import React, { useEffect, useState } from 'react';
import {
  equals, divide, pathOr, compose, propOr,
} from 'ramda';

import generatePath from 'utils/generatePath';
import textCapitalize from 'utils/textCapitalize';

import funnelViews from 'libs/funnelViews';
import {
  connectBank, error as errorPage,
  reviewInfo, personalInfo,
} from 'libs/views';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import Currency from 'components/Currency';

const Banner = ({ isReduced }) => (
  <div className="media align-items-center">
    <Icon name="congrats" className="h2 mb-0 mr-4" />
    <div className="media-body">
      <p className="mb-0">
        {!isReduced
          ? 'You pay exactly what you owe, and not a penny more. Forever.'
          : 'We were able to reduce your total bill amount!'}
      </p>
    </div>
  </div>
);

const PaymentPlan = ({
  history,
  offer,
  updateOffer,
  applicationId,
  currentView,
  patientId,
  updateApplication,
  getOffer,
  user,
  location,
  funnelType,
  applicationStatus,
  offerId,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const resumeApp = pathOr(false, ['state', 'resumeApp'])(location);
  const onSubmitFailure = () => history.push(generatePath(funnelType, errorPage));
  const onSubmitSuccess = () => history.push(generatePath(funnelType, connectBank));

  const onSubmit = () => {
    setIsFetching(true);

    updateOffer({ id: offer.offerId, data: { status: 'OFFER_APPROVED' } })
      .then(onSubmitSuccess)
      .catch(onSubmitFailure);
  };

  const {
    totalPayment, totalAmount, newAmount,
  } = offer;

  const isReduced = !equals(newAmount, totalAmount);

  const amountToPay = newAmount || totalAmount;

  const monthlyPayment = totalPayment && divide(amountToPay, totalPayment);

  useEffect(() => {
    if (equals(offer.getOfferStatus, 'pending')) {
      return setIsFetching(true);
    }
    return setIsFetching(false);
  }, [offer.getOfferStatus]);

  useEffect(() => {
    if (user.isLoaded && !offer.isLoaded) {
      setIsFetching(true);
      getOffer(offerId)
        .then(() => setIsFetching(false))
        .catch(() => setIsFetching(false));
    }
  }, [offer.isLoaded]);

  useEffect(() => {
    if (currentView !== funnelViews.offer && applicationId && applicationStatus !== 'APPLICATION_COMPLETED') {
      updateApplication({
        patientId,
        applicationId,
        currentView: funnelViews.offer,
      });
    }
  }, [currentView]);

  const isAppFunnel = equals(funnelType, 'app');
  const backRoute = isAppFunnel ? reviewInfo : personalInfo;
  const userName = compose(textCapitalize, propOr('', 'firstName'))(user);

  return (
    <Layout
      backLink={generatePath(funnelType, backRoute)}
      hideBackLink={!applicationId}
    >
      <Card
        progressBarValue={66.9117647059}
        content={(
          <div className="funnel-card__wrapper w-100">
            {isFetching && (
              <div className="connect-bank__loader text-center">
                <Loading />
              </div>
            )}
            <div className="text-larger text-center font-weight-light mb-5">
              {isReduced ? (
                <p className="mb-0">
                  Your total bill amount is
                  {' '}
                  <b>reduced</b>
                </p>
              ) : (
                <>
                  <p data-private className="text-center text-base mb-2">
                    {resumeApp
                      ? `Welcome back, ${userName}`
                      : `Great ${userName},`}
                  </p>
                  <h1 className="h4 font-weight-normal mb-2">
                    Here is your payment plan
                  </h1>
                  {resumeApp && <p className="text-center text-base mb-0">Pick up where you left off</p>}
                </>
              )}
            </div>
            <div className={`payment-plan__box ${isReduced ? 'pt-4 pb-5 mb-4' : 'py-5  mb-6'} px-5 px-sm-7 bg-alice-blue-alt`}>
              {isReduced && (
                <>
                  <div className="text-center">
                    <h1 className="text-smaller font-weight-bold">NEW PAYMENT PLAN</h1>
                  </div>
                  <hr className="payment-plan__box__hr mb-5" />
                </>
              )}
              <div className="row no-gutters mb-5 position-relative">
                <div className="col-7 text-center payment-plan__box__divider">
                  {isReduced && (<span className="d-block text-tundora text-smaller text-uppercase mb-2">Adjusted bill amount</span>)}
                  <span data-private className="d-block h2 font-weight-light text-xlarger mb-1">
                    <Currency
                      amount={amountToPay}
                    />
                  </span>
                  <span className="text-tundora text-smaller text-uppercase">
                    {isReduced ? (
                      <span className="payment-plan__box__savings px-3 py-1 d-none d-sm-inline">
                        Saved
                        {' '}
                        <b data-private>
                          <Currency
                            amount={totalAmount - newAmount}
                          />
                        </b>
                      </span>
                    ) : 'Full bill amount'}
                  </span>
                </div>
                <div className="col-5 text-center">
                  {isReduced && (<span className="d-block text-tundora text-smaller text-uppercase mb-2">months term</span>)}
                  <span data-private className="d-block h2 font-weight-light text-xlarger mb-1">{totalPayment && `${totalPayment} Months`}</span>
                  {!isReduced && (<span className="text-tundora text-smaller text-uppercase">months term</span>)}
                </div>
                {isReduced && (
                  <span className="payment-plan__box__savings px-3 py-1 d-block d-sm-none">
                    Saved
                    {' '}
                    <b data-private>
                      <Currency
                        amount={totalAmount - newAmount}
                      />
                    </b>
                  </span>
                )}
              </div>
              <hr className="payment-plan__box__hr" />
              <div className="text-center">
                {monthlyPayment && (
                  <>
                    <span data-private className="h1 font-weight-light">
                      <Currency
                        amount={monthlyPayment}
                      />
                    </span>
                    <span className="h4 font-weight-light">/mo</span>
                  </>
                )}
                <br />
                <span className="text-tundora text-smaller text-uppercase">Monthly Payment</span>
              </div>
            </div>
            {isReduced && (
              <div className="bg-alabaster-alt py-3 pl-md-7 px-4 mb-5 mb-md-7">
                <div className="d-flex justify-content-between">
                  <div>
                    <span className="text-comet text-smaller text-uppercase">Original bill</span>
                  </div>
                  <div>
                    <span data-private className="h4 text-comet font-weight-light">
                      <Currency
                        amount={totalAmount}
                      />
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div className="funnel-card__form">
              <Button type="primary" className="btn-block" onClick={onSubmit}>Accept & Continue</Button>
            </div>
          </div>
        )}
        banner={<Banner isReduced={isReduced} />}
      />
    </Layout>
  );
};

export default PaymentPlan;
