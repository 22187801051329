import { connect } from 'react-redux';

import {
  createPatient,
  getProviderName,
  createProvider,
  createPartnerBill,
} from 'actions/patient';
import { updateApplication, updateApplicationPatient } from 'actions/application';
import { updateBill } from 'actions/bill';

import Form from './Form';

const mapStateToProps = ({
  patient: { partnerId },
  bill: { bills },
}) => ({
  partnerId,
  bills,
});

const mapDispatchToProps = {
  createPatient,
  getProviderName,
  createProvider,
  createPartnerBill,
  updateApplication,
  updateApplicationPatient,
  updateBill,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Form);
