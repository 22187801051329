import { compose } from 'redux';
import { connect } from 'react-redux';

import { setPatientProp } from 'actions/patient';

import withBankFunnel from './withBankFunnel';

const mapStateToProps = ({ offer }) => ({ offer });

const mapDispatchToProps = {
  setPatientProp,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withBankFunnel,
);
