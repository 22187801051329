import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { addMonths, format } from 'date-fns';
import {
  compose, prop, isNil, head, filter, pathOr, includes, path, last,
} from 'ramda';
import queryString from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

import { confirmPaymentForm } from 'utils/validationSchemas';
import generatePath from 'utils/generatePath';
import getErrorMessage from 'utils/getErrorMessage';

import {
  success,
  connectBank,
  PAYMENT_PLAN_AGREEMENT,
  AUTOMATIC_PAYMENT_AUTHORIZATION,
  error as errorPage,
  connectPaymentMethod,
  paymentError,
} from 'libs/views';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Button from 'components/Button';
import Loading from 'components/Loading';
import { Input } from 'components/Fields';
import CardFormat from 'components/CardFormat';
import Currency from 'components/Currency';
import Link from 'components/Link';

const initialValues = { agree: false };
const hash = Math.random().toString(36).slice(2).substring(5);

const ConfirmPayment = ({
  user,
  paymentMethod,
  getOffer,
  makePatientPayment,
  funnelType,
  bills,
  offerId,
  offer,
  paymentMethodTypes,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [isFetching, setIsFetching] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const bill = compose(head, filter(prop('providerId')))(bills);
  const providerId = prop('providerId')(bill);
  const paymentMethodId = prop('paymentMethodId')(paymentMethod);
  const hasDebitCardOption = includes('debit card', paymentMethodTypes);
  const backView = hasDebitCardOption ? connectPaymentMethod : connectBank;
  const paymentType = pathOr('', ['state', 'paymentType'])(location);
  const isCARDPayment = paymentType === 'CARD_PAYMENT';

  const onFailure = (error) => {
    setIsProcessing(false);
    if (isCARDPayment) {
      const message = getErrorMessage(error);
      return history.push(
        generatePath(funnelType, paymentError),
        { cardInfo: paymentMethod.card, errorMessage: message },
      );
    }

    return history.push(generatePath(funnelType, errorPage));
  };

  const onSubmitSuccess = () => {
    setIsProcessing(false);
    history.push(generatePath(funnelType, success), { paymentMethodId });
  };

  const handleSubmit = (_, actions) => {
    setIsProcessing(true);

    makePatientPayment({ paymentMethodId, offerId })
      .then(onSubmitSuccess)
      .catch(onFailure);

    return actions.setSubmitting(false);
  };

  useEffect(() => {
    if (isNil(paymentMethod.status)) {
      history.push(generatePath(funnelType, connectBank));
    }
  }, [paymentMethod.status]);

  const getOfferSuccess = () => setIsFetching(false);

  const getOfferFailure = () => {
    setIsFetching(false);
    return history.push(generatePath(funnelType, errorPage));
  };

  useEffect(() => {
    if (user.isLoaded && offerId) {
      setIsFetching(true);

      getOffer(offerId)
        .then(getOfferSuccess)
        .catch(getOfferFailure);
    }
  }, []);

  const paymentPlanUrl = generatePath(funnelType, PAYMENT_PLAN_AGREEMENT);
  const queryParams = queryString.stringify({ providerId, offerId }, { addQueryPrefix: true });

  const autoPaymentAuthUrl = generatePath(funnelType, AUTOMATIC_PAYMENT_AUTHORIZATION);
  const autoPaymentAuthQS = queryString.stringify(
    { providerId, offerId, paymentMethodId },
    { addQueryPrefix: true },
  );

  const planLength = path(['totalPayment'])(offer);

  return (
    <Layout
      backLink={generatePath(funnelType, backView)}
    >
      <Card
        className="mt-2"
        progressBarValue={94.9367088608}
        content={(
          <div className="funnel-card__wrapper pt-5 pt-sm-7">
            {isProcessing && (
              <div className="connect-bank__loader text-center">
                <Loading message="Processing your payment" />
              </div>
            )}
            {isFetching && (
              <div className="connect-bank__loader text-center">
                <Loading />
              </div>
            )}
            <div className="funnel-card__form text-center mb-5">
              <h1 className="h4 font-weight-normal mb-0" data-cy="confirm-title" data-testid="confirm-payment-title">
                Review your payment plan
              </h1>
            </div>
            <div className="payment-plan__box py-4 py-sm-5 px-4 px-sm-5 px-sm-7 bg-alice-blue-alt mb-4">
              <div className="px-1 px-sm-0">
                <div className="text-center">
                  <p data-private className="payment-plan__box__amount mb-0 text-charade">
                    <span className="d-flex justify-content-center align-items-start">
                      <span className="text-larger pt-2">$</span>
                      <span className="d-flex align-items-baseline">
                        <Currency
                          amount={pathOr('', ['paymentsAmount', '0'])(offer)}
                          prefix=""
                          data-cy="confirm-monthlyamount"
                          data-testid="confirm-payment-monthly-amount"
                        />
                        <span className="text-larger">/ month</span>
                      </span>
                    </span>
                  </p>
                  <p className="text-smaller text-trout" data-testid="confirm-payment-charged-on">
                    Automatically charged on the
                    {' '}
                    {format(new Date(), 'do')}
                    {' '}
                    of every month
                  </p>
                </div>
                <hr className="payment-plan__box__hr my-3 my-md-5" />
                <div className="text-tuna text-small d-flex justify-content-between mb-2">
                  <span className="text-storm-gray" data-testid="confirm-payment-first-payment">
                    First Payment (
                    <Currency
                      amount={pathOr('', ['paymentsAmount', '0'])(offer)}
                    />
                    )
                  </span>
                  <span data-testid="confirm-payment-first-payment-date">
                    Today
                  </span>
                </div>
                <div className="text-tuna text-small d-flex justify-content-between mb-2">
                  <span className="text-storm-gray" data-testid="confirm-payment-last-payment">
                    Last Payment (
                    <Currency
                      amount={compose(last, path(['paymentsAmount']))(offer)}
                    />
                    )
                  </span>
                  <span data-testid="confirm-payment-last-payment-date">
                    {/* TODO: use API when we have it */}
                    {format(addMonths(new Date(), planLength - 1), 'MM/dd/yyyy')}
                  </span>
                </div>
                <div className="text-tuna text-small d-flex justify-content-between mb-2">
                  <span className="text-storm-gray" data-testid="confirm-payment-plan-length">Plan Length</span>
                  <span data-testid="confirm-payment-plan-length-value">
                    {planLength}
                    {' '}
                    Months
                  </span>
                </div>
                {paymentMethod && (
                  <>
                    {isCARDPayment ? (
                      <div data-private className="text-tuna text-small d-flex justify-content-between" data-cy="confirm-carddata">
                        <span className="text-storm-gray" data-testid="confirm-payment-payment-method">Payment Method</span>
                        <CardFormat data={paymentMethod.card} data-testid="confirm-payment-payment-method-value" />
                      </div>
                    ) : (
                      <div data-private className="text-tuna text-small row" data-cy="confirm-bankdata">
                        <span className="text-storm-gray col-6" data-testid="confirm-payment-payment-method">Payment Method</span>
                        <span data-testid="confirm-payment-payment-method-value" className="col-6 text-right">
                          {paymentMethod.bankName}
                          {' '}
                          ••••
                          {' '}
                          {paymentMethod.accountLast4Digits}
                        </span>
                      </div>
                    )}
                  </>
                )}
                <hr className="payment-plan__box__hr my-3 my-md-5" />
                <div className="text-comet text-small d-flex justify-content-between mb-2">
                  <span className="text-storm-gray" data-testid="confirm-payment-total-plan-amount">Total Plan Amount</span>
                  <Currency amount={path(['totalAmount'])(offer)} data-testid="confirm-payment-total-plan-amount-value" />
                </div>
                <div className="text-comet text-small d-flex justify-content-between">
                  <span className="text-storm-gray" data-testid="confirm-payment-interest">Interest</span>
                  <span data-testid="confirm-payment-interest-value">$0.00</span>
                </div>
              </div>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={confirmPaymentForm}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values }) => {
                const isDisabled = isSubmitting || !values.agree;

                return (
                  <Form>
                    <div className="mb-4 mb-sm-5">
                      <div className="media align-items-start justify-content-center">
                        <Field
                          id="agree"
                          type="checkbox"
                          name="agree"
                          component={Input}
                          data-cy="confirm-agreetos"
                          eventName="agreement-checkbox"
                        />
                        <div>
                          <p className="text-small mb-0" data-testid="confirm-payment-agreement">
                            I have reviewed and agree to the terms of my
                            {' '}
                            <Link
                              target={`${hash}-payment-plan-agreement`}
                              to={paymentPlanUrl + queryParams}
                              data-cy="confirm-planagreement"
                              eventName="payment-plan-agreement"
                            >
                              Payment Plan Agreement
                            </Link>
                            {' '}
                            and to debit the Monthly Payment Amount from
                            my account in accordance with the
                            {' '}
                            <Link
                              target={`${hash}-automatic-payment-authorization`}
                              to={autoPaymentAuthUrl + autoPaymentAuthQS}
                              data-cy="confirm-autopayagreement"
                              eventName="automatic-payment-authorization"
                            >
                              Automatic Payment Authorization
                            </Link>
                            .
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="funnel-card__form">
                      <Button
                        type="primary"
                        className="btn-block"
                        isSubmit
                        disabled={isDisabled}
                        data-cy="confirm-approve"
                        eventName="main-cta"
                        data-testid="confirm-payment-cta"
                      >
                        Confirm and Enroll
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
      />
    </Layout>
  );
};

export default ConfirmPayment;
