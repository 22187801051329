import React from 'react';

import Currency from 'components/Currency';

import {
  BALANCE_MAX, BALANCE_MIN, isAboveAllowedTotal, isBelowAllowedTotal,
} from 'utils/invoiceToPay';

const BalanceErrorPayInFull = ({ accumulator }) => {
  const isAboveBalance = isAboveAllowedTotal(accumulator);
  const isBelowBalance = isBelowAllowedTotal(accumulator);

  if (accumulator === 0) {
    return (
      <div className="bg-linen p-4 mb-5">
        <p className="text-center text-small text-stiletto mb-0">
          Please select bills to pay in full or over time.
        </p>
      </div>
    );
  }

  if (isAboveBalance) {
    return (
      <div className="bg-island-spice p-4 mb-5">
        <p className="text-center text-small text-tuna mb-0">
          <Currency
            amount={BALANCE_MAX}
          />
          {' '}
          is the limit for enrolling in a payment plan.
        </p>
      </div>
    );
  }

  if (isBelowBalance) {
    return (
      <div className="bg-island-spice p-4 mb-5">
        <p className="text-center text-small text-tuna mb-0">
          You need a minimum balance of
          {' '}
          <Currency
            amount={BALANCE_MIN}
          />
          {' '}
          to enroll in a payment plan.
        </p>
      </div>
    );
  }

  return null;
};

export default BalanceErrorPayInFull;
