import { createAsyncAction } from 'redux-promise-middleware-actions';
import { prop } from 'ramda';

import { reqGetDocument, reqGetDocumentSigned } from 'api/documents';

export const getDocument = createAsyncAction(
  'GET_DOCUMENT',
  (payload) => reqGetDocument(payload).then((response) => ({
    ...payload,
    html: response.data,
  })),
);

export const getDocumentSigned = createAsyncAction(
  'GET_DOCUMENT_SIGNED',
  (payload) => reqGetDocumentSigned(payload).then(prop('data')),
);
