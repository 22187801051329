import React, { useEffect, useState } from 'react';
import {
  compose,
  prop,
  path,
  isEmpty,
  head,
  filter,
  sort,
  descend,
  propEq,
  propOr,
} from 'ramda';
import queryString from 'qs';

import generatePath from 'utils/generatePath';
import textCapitalize from 'utils/textCapitalize';

import { confirmPayment, PAYMENT_CONFIRMATION, billInfo } from 'libs/views';
import funnelViews from 'libs/funnelViews';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Loading from 'components/Loading';
import Icon from 'components/Icon';
import Currency from 'components/Currency';
import Link from 'components/Link';

import imageCongrats from 'assets/images/icn_congrats.svg';
import imageBill from 'assets/images/bill.svg';
import imageLetter from 'assets/images/letter.svg';
import imageEnvelop from 'assets/images/envelop.svg';

const hash = Math.random().toString(36).slice(2).substring(5);

const Success = ({
  paymentMade,
  currentView,
  applicationId,
  patientId,
  updateApplication,
  user,
  getOfferPayment,
  funnelType,
  bills,
  location,
  pendingBills,
  getBill,
  applicationStatus,
  offerId,
  providerId,
  providerName,
  processor,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const paymentMethodId = path(['state', 'paymentMethodId'])(location);
  // todo: revisit this once we have info about payments frequency

  const firstPaymentAmount = prop('amount')(paymentMade);

  useEffect(() => {
    if (currentView !== funnelViews.success && applicationId && applicationStatus !== 'APPLICATION_COMPLETED') {
      updateApplication({
        patientId,
        applicationId,
        currentView: funnelViews.success,
        status: 'APPLICATION_COMPLETED',
      });
    }
  }, [currentView]);

  const onSuccess = () => setIsFetching(false);

  useEffect(() => {
    const offerIdBills = compose(
      prop('offerId'),
      head,
      sort(descend(prop('updatedAt'))),
      filter(propEq('status', 'BILL_ACCEPTED')),
    )(bills);

    if (!paymentMade.isLoaded && offerIdBills) {
      setIsFetching(true);

      getOfferPayment(offerIdBills).then(onSuccess);
    }
  }, [paymentMade.isLoaded, offerId]);

  useEffect(() => {
    if (patientId && offerId) {
      setIsFetching(true);

      getBill({ patientId, providerId }).then(onSuccess);
    }
  }, [patientId, offerId]);

  const paymentConfirmationUrl = generatePath(funnelType, PAYMENT_CONFIRMATION);

  const queryParams = queryString.stringify(
    { providerId, offerId, paymentMethodId },
    { addQueryPrefix: true },
  );

  const userName = compose(textCapitalize, propOr('', 'firstName'))(user);

  const isMT = processor === 'MT';

  const renderDescriptor = () => {
    if (isMT) return 'Payzen Inc. Plan pymt';
    return `${providerName.substring(0, 15)} PayZen Plan Pymt`;
  };

  return (
    <Layout
      backLink={generatePath(funnelType, confirmPayment)}
      hideBackLink
    >
      <Card
        className="mt-2"
        progressBarValue={100}
        content={(
          <>
            <div className="funnel-card__wrapper text-center text-tuna">
              {(!paymentMade || isFetching) && (
                <div className="connect-bank__loader text-center">
                  <Loading />
                </div>
              )}
              <img src={imageCongrats} alt="" width="66" className="mb-4" />
              <h1 data-private className="h4 font-weight-light mb-6" data-cy="success-title" data-testid="success-title">
                {`You’re All Set ${userName}`}
              </h1>
              <p className="mb-5" data-testid="success-first-payment">
                Your first payment of
                {' '}
                <span data-private data-cy="success-monthlyamount">
                  <Currency amount={firstPaymentAmount} />
                </span>
                {' '}
                has been processed.
              </p>
              <div className="text-left funnel-card__form">
                <div className="d-flex align-items-center mb-5">
                  <img src={imageBill} alt="bill" className="mr-4" />
                  <p className="text-small mb-0" data-testid="success-bank-statement">
                    Payments will show on your bank statement as
                    {' '}
                    <b>{`“${renderDescriptor()}”.`}</b>
                  </p>
                </div>
                <div className="d-flex align-items-center mb-5">
                  <img src={imageLetter} alt="letter" className="mr-4 h1 text-bali-hai" />
                  <p className="text-small mb-0" data-testid="success-provider-name">
                    It takes a few days to process your payment plan.
                    Please disregard bill reminders from
                    {' '}
                    {providerName}
                    {' '}
                    in the meantime.
                  </p>
                </div>
                <div className="d-flex align-items-center mb-6">
                  <img src={imageEnvelop} alt="envelop" className="mr-4 text-xlarger text-bali-hai" />
                  <p className="text-small mb-0" data-testid="success-confirmation-email">
                    We will send you a confirmation email confirming your payment and plan.
                  </p>
                </div>
                <p className="mb-5 text-center" data-testid="success-confirmation-code">
                  For your reference, confirmation code:
                  <span
                    data-private
                    className="success__code d-block"
                    data-cy="success-code"
                  >
                    {prop('confirmationCode')(paymentMade)}
                  </span>
                </p>
                <Link
                  to={paymentConfirmationUrl + queryParams}
                  className="btn btn-primary btn-block text-decoration-none"
                  target={`${hash}-print-confirmation`}
                  data-cy="success-printconfirmation"
                  eventName="print-confirmation"
                  data-testid="success-print-confirmation"
                >
                  Print Confirmation
                </Link>
              </div>
            </div>
            {!isEmpty(pendingBills) && (
              <>
                <div className="bg-alabaster-alt p-2" />
                <div className="text-center py-3">
                  <Link
                    className="btn btn-link text-decoration-none d-inline-flex align-items-center"
                    href={generatePath(funnelType, billInfo)}
                    data-cy="success-paymore"
                    eventName="pay-another-bill"
                  >
                    <Icon name="sheet-add" className="text-xlarger mr-4" />
                    Pay another medical bill
                  </Link>
                </div>
              </>
            )}
          </>
        )}
      />
    </Layout>
  );
};

export default Success;
