import React, { useState, useEffect } from 'react';
import {
  propOr, pathOr, equals, isEmpty, filter,
  path, omit, compose,
  propEq, sum, pluck,
  includes, head, startsWith,
} from 'ramda';

import {
  personalInfoForm,
  personalInfoWithoutAddressAndIncomeForm,
  personalInfoWithoutAddressForm,
  personalInfoWithoutIncome,
} from 'utils/validationSchemas';
import generatePath from 'utils/generatePath';
import getErrorMessage from 'utils/getErrorMessage';
import textCapitalize from 'utils/textCapitalize';

import funnelViews from 'libs/funnelViews';
import {
  invoiceToPay,
  paymentPlan,
  error as errorPage,
  confirmSSN,
  manualAddress,
  errorIdentity,
  errorEnroll,
} from 'libs/views';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Loading from 'components/Loading';
import PersonalInfo from 'components/Forms/PersonalInfo';
import isDemo from 'utils/isDemo';
import { convertToCents, convertToDollars } from 'utils/currency';

const getEmail = (user) => {
  const email = propOr('', 'email')(user);
  if (startsWith('GUARANTOR_', email)) return '';
  return email;
};

const initialValues = ({ user, patient }, shouldHideIncome) => {
  const completeAddress = () => {
    const {
      addressNumber, address, city, state,
    } = patient;

    if (addressNumber && address && city && state) return `${addressNumber} ${address}, ${city}, ${state}`;
    if (address && city && state) return `${address}, ${city}, ${state}`;
    return '';
  };

  const hasAddress = propOr(false, 'hasAddress')(patient);

  const patientIncome = patient.annualIncome ? `${convertToDollars(patient.annualIncome)}` : '';

  if (isDemo() && shouldHideIncome) {
    return {
      email: propOr('', 'email')(user),
      phone: propOr('', 'phone')(user),
    };
  }

  if (isDemo()) {
    return {
      email: propOr('', 'email')(user),
      phone: propOr('', 'phone')(user),
      annualIncome: patientIncome,
    };
  }

  if (shouldHideIncome) {
    return {
      fullAddress: hasAddress ? 'has address' : completeAddress(),
      email: getEmail(user),
      phone: propOr('', 'phone')(user),
      hasAddress: propOr(false, 'hasAddress')(patient),
    };
  }

  return {
    fullAddress: hasAddress ? 'has address' : completeAddress(),
    email: getEmail(user),
    phone: propOr('', 'phone')(user),
    hasAddress: propOr(false, 'hasAddress')(patient),
    annualIncome: patientIncome,
  };
};

const ReviewInfo = ({
  history,
  user,
  patient,
  updateApplication,
  updateApplicationPatient,
  applicationId,
  patientId,
  currentView,
  location,
  bills,
  getOffer,
  offerId,
  applicationStatus,
  generateOffers,
  getGeneratedOffers,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const userName = compose(textCapitalize, propOr('', 'firstName'))(user);
  const shouldHideIncome = propOr(false, 'shouldHideIncome')(user);
  const resumeApp = pathOr(false, ['state', 'resumeApp'])(location);
  const initValues = initialValues({ user, patient }, shouldHideIncome);

  const validationSchema = () => {
    if (isDemo() && shouldHideIncome) return personalInfoWithoutAddressAndIncomeForm;
    if (isDemo()) return personalInfoWithoutAddressForm;
    if (shouldHideIncome) return personalInfoWithoutIncome;
    return personalInfoForm;
  };

  const includedInOffer = propEq('includedInOffer', true);
  const statusPending = propEq('status', 'BILL_PENDING');
  const validOffers = compose(filter(includedInOffer), filter(statusPending));

  const totalBalance = compose(sum, pluck('totalAmount'), validOffers)(bills);
  const selectedBills = compose(pluck('billId'), validOffers)(bills);

  const ignoreEmptyValues = (item) => !isEmpty(item);

  const getOfferSuccess = () => setIsFetching(false);

  const getOfferFailure = () => {
    setIsFetching(false);
    return history.push(generatePath('app', errorPage));
  };

  useEffect(() => {
    if (offerId) {
      setIsFetching(true);

      getOffer(offerId)
        .then(getOfferSuccess)
        .catch(getOfferFailure);
    }
  }, [offerId]);

  const onFailure = (formData) => (error) => {
    const needsManualAddress = path(['response', 'data', 'needsManualAddress'])(error);

    const incomeData = shouldHideIncome
      ? {}
      : { isAnnualIncomeEqual: equals(initValues.annualIncome, formData.annualIncome) };

    setIsFetching(false);

    if (needsManualAddress) {
      return history.push(
        generatePath('app', manualAddress),
        { ...formData, attempt: 0, ...incomeData },
      );
    }

    return history.push(generatePath('app', errorIdentity));
  };

  const onFailureCreateOffer = (formData) => (error) => {
    const errorMessage = getErrorMessage(error);

    const incomeData = shouldHideIncome
      ? {}
      : { isAnnualIncomeEqual: equals(initValues.annualIncome, formData.annualIncome) };

    setIsFetching(false);

    if (includes('DTI', errorMessage)) return history.push(generatePath('app', errorEnroll));
    if (includes('VerifySSN', errorMessage)) return history.push(generatePath('app', confirmSSN));

    return history.push(
      generatePath('app', manualAddress),
      { ...formData, attempt: 1, ...incomeData },
    );
  };

  const onSuccess = () => {
    setIsFetching(false);
    history.push(generatePath('app', paymentPlan));
  };

  const getShouldSucceed = (formData, response) => {
    if (shouldHideIncome) return false;

    const totalAmountOffer = compose(head, pluck('totalAmount'), propOr([], 'value'))(response);
    const isTotalBalanceEqual = equals(totalAmountOffer, totalBalance);
    const isAnnualIncomeEqual = equals(initValues.annualIncome, formData.annualIncome);

    return isTotalBalanceEqual && isAnnualIncomeEqual;
  };

  const onSuccessGetGeneratedOffers = (formData) => (response) => {
    const shouldSucceed = getShouldSucceed(formData, response);

    if (shouldSucceed) return onSuccess();

    return generateOffers({
      totalBalance,
      bills: selectedBills,
      firstBillingDate: new Date(),
    })
      .then(onSuccess)
      .catch(onFailureCreateOffer(formData));
  };

  const onGenerateOffers = (formData) => () => generateOffers({
    totalBalance,
    bills: selectedBills,
    firstBillingDate: new Date(),
  })
    .then(onSuccess)
    .catch(onFailureCreateOffer(formData));

  const onFailureGetGeneratedOffers = (formData) => (error) => {
    const errorMessage = getErrorMessage(error);

    if (equals(errorMessage, 'No GDS data available')) {
      return onGenerateOffers(formData)();
    }

    setIsFetching(false);

    return onFailureCreateOffer(formData);
  };

  const onGetGeneratedOffers = (formData) => () => getGeneratedOffers()
    .then(onSuccessGetGeneratedOffers(formData))
    .catch(onFailureGetGeneratedOffers(formData));

  const handleSubmit = (formData) => {
    setIsFetching(true);

    const data = omit(['hasAddress'])(formData);
    const newData = formData.hasAddress ? omit(['fullAddress', 'isPoBox'])(data) : data;

    const incomeData = shouldHideIncome
      ? {}
      : { annualIncome: convertToCents(formData.annualIncome) };

    const body = {
      applicationId,
      ...newData,
      ...incomeData,
    };

    const cleanBody = filter(ignoreEmptyValues)(body);

    return updateApplicationPatient(cleanBody)
      .then(onGetGeneratedOffers(body))
      .catch(onFailure(body));
  };

  useEffect(() => {
    if (currentView !== funnelViews.personalInfo && applicationId && applicationStatus !== 'APPLICATION_COMPLETED') {
      updateApplication({
        patientId,
        applicationId,
        currentView: funnelViews.personalInfo,
      });
    }
  }, [currentView]);

  const TITLE_TEXT = ', let\'s create your payment plan';
  const INFO_TEXT = 'Please answer a few basic questions so we can generate your custom plan.';
  const BUTTON_TEXT = 'See my options';

  return (
    <Layout
      backLink={generatePath('app', invoiceToPay)}
    >
      <Card
        className="mt-2"
        progressBarValue={31.25}
        content={(
          <div className="funnel-card__wrapper">
            {isFetching && (
              <div className="connect-bank__loader text-center">
                <Loading />
              </div>
            )}
            <div className="funnel-card__form">
              <div className="text-center">
                {resumeApp
                  && (
                    <p data-private className="mb-4" data-cy="info-resume-title">
                      {userName
                        ? `Welcome back, ${userName}!`
                        : 'Welcome back!'}
                    </p>
                  )}
                <h1 className="h4 font-weight-normal text-center mb-5" data-cy="info-title">
                  <span className="text-capitalize">{userName}</span>
                  {TITLE_TEXT}
                </h1>
                <p className="text-center mb-5" data-cy="info-description">
                  {INFO_TEXT}
                </p>
              </div>
              <PersonalInfo
                initialValues={initValues}
                validationSchema={validationSchema()}
                onSubmit={handleSubmit}
                isSmsLink={user.isSmsLink}
                isDemo={isDemo()}
                buttonText={BUTTON_TEXT}
                shouldHideIncome={shouldHideIncome}
              />
            </div>
          </div>
        )}
      />
    </Layout>
  );
};

export default ReviewInfo;
