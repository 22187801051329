import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  isEmpty, path, pathOr, toLower,
} from 'ramda';

import {
  billInfo,
  error,
  invoiceToPay,
  selectUser,
} from 'libs/views';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Loading from 'components/Loading';
import Icon from 'components/Icon';
import Button from 'components/Button';
import isMinorPatient from 'utils/isMinorPatient';
import { filterGuarantors } from 'utils/guarantors';
import generatePath from 'utils/generatePath';
import { setToken } from 'libs/authenticationService';

const SelectAccount = ({
  isEpicApp,
  organization,
  selectProvider,
  getProviderConfig,
}) => {
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(false);

  const onSuccess = (response) => {
    setIsFetching(false);
    const user = path(['action', 'payload', 'user'])(response);
    const patient = path(['action', 'payload', 'patient'])(response);
    const guarantors = pathOr([], ['action', 'payload', 'guarantors'])(response);
    const filteredGuarantors = filterGuarantors(user, guarantors);
    const hasGuarantor = !isEmpty(filteredGuarantors) && !isMinorPatient(patient.birthDate);
    const token = path(['action', 'payload', 'auth', 'token'])(response);
    const { providerId } = path(['action', 'payload', 'application'])(response);

    setToken(token);
    getProviderConfig({ providerId });

    const nextView = hasGuarantor ? selectUser : invoiceToPay;
    return history.push(generatePath('app', nextView));
  };

  const onFailure = () => {
    setIsFetching(false);
    return history.push(generatePath('app', error));
  };

  const onSelectProvider = (providerId) => {
    setIsFetching(true);

    selectProvider({
      providerId,
      accounts: organization.accounts,
      multiOrgId: organization.multiOrgId,
      epic: organization.epic,
    }).then(onSuccess)
      .catch(onFailure);
  };

  return (
    <Layout
      backLink={generatePath('app', billInfo)}
      hideBackLink={isEpicApp}
    >
      <Card
        className="mt-2 funnel-card--invoice"
        progressBarValue={0}
        showBillingMessage
        content={(
          <div className="funnel-card__wrapper pt-7 pt-sm-8 pb-5 pb-sm-9">
            {isFetching && (
              <div className="connect-bank__loader text-center">
                <Loading />
              </div>
            )}
            <div className="text-center">
              <h1 className="h4 font-weight-normal mb-5" data-cy="select-account-title">
                <>
                  Welcome,
                  {' '}
                  <span className="text-capitalize" data-cy="select-account-patientname">
                    {organization.user.firstName && toLower(organization.user.firstName)}
                    !
                  </span>
                </>
              </h1>
              <div className="funnel-card__form mb-3">
                <p className="text-center mb-0">
                  Whose bills would like to set up a payment plan for?
                </p>
              </div>
            </div>
            {organization.providers.map((org) => (
              <Button
                key={org.providerId}
                type="button"
                className="d-flex bg-zircon mb-2 px-4 py-3 rounded w-100"
                onClick={() => onSelectProvider(org.providerId)}
                data-cy="select-account-item"
              >
                <div className="w-100">
                  <div className="d-flex justify-content-between align-items-center">
                    <div data-private data-cy="select-account" className="text-left">
                      {org.providerName}
                      <span className="text-storm-gray text-smaller d-block">
                        {org.visits}
                        {' '}
                        {org.visits === 1 ? 'visit' : 'visits'}
                      </span>
                    </div>
                    <div className="ml-2">
                      <Icon name="caret-right" />
                    </div>
                  </div>
                </div>
              </Button>
            ))}
          </div>
        )}
      />
    </Layout>
  );
};

export default SelectAccount;
