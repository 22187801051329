import React from 'react';

import Header from 'components/Header';
import Icon from 'components/Icon';
import Card from 'components/Funnel/Card';

const Maintenance = () => (
  <div className="d-flex flex-column min-vh-100">
    <Header />
    <div className="wrapper funnel-wrapper">
      <Card
        className="mt-2 mt-sm-0"
        content={(
          <div className="error-page text-center mx-auto text-emperor font-weight-semibold d-flex align-items-center">
            <div className="flex-fill">
              <div className="mb-7">
                <Icon name="chain-broken" className="error-page__icon mb-5 mm-md-6 text-pizazz" />
                <h1 className="h2 mb-5">We are down for maintenance</h1>
                <p className="mb-0">
                  We will be back soon
                </p>
              </div>
            </div>
          </div>
          )}
      />
    </div>
  </div>
);

export default Maintenance;
