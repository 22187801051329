import React, { useEffect } from 'react';
import { isEmpty, pathOr } from 'ramda';

import { loggedIn, logout } from 'libs/authenticationService';

import Header from 'components/Header';
import { useHistory } from 'react-router-dom';
import getSubdomain from 'utils/getSubdomain';
import generatePath from 'utils/generatePath';
import { maintenance } from 'libs/views';
import LayoutContext from 'contexts/LayoutContext';

const Layout = ({
  backLink,
  backCallback = null,
  hideBackLink = false,
  user,
  children,
  clearStore,
  className = '',
  getProviderConfig,
  provider,
  isEpicApp,
}) => {
  const history = useHistory();
  const providerName = getSubdomain(window);

  useEffect(() => {
    if (loggedIn() && !backLink && provider.isLoaded) {
      logout();
      clearStore();
    }
  }, [provider.isLoaded]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (providerName && !provider.isLoaded && !isEpicApp) {
      getProviderConfig({ subdomain: providerName })
        .then((response) => {
          const maintenanceMode = pathOr(false, ['value', 'maintenanceMode'])(response);
          if (maintenanceMode) return history.replace(generatePath('app', maintenance));
          return null;
        })
        .catch(() => window.location.replace('https://payzen.com/'));
    }
  }, [providerName, provider.isLoaded, isEpicApp]);

  const isFullNameAvailable = !isEmpty(user.firstName) && !isEmpty(user.lastName);

  if (user.isLoaded) {
    window.Intercom('update', {
      name: isFullNameAvailable ? `${user.firstName} ${user.lastName}` : user.email,
      email: user.email,
      user_id: user.userId,
      user_hash: user.intercom,
    });
  }

  return (
    <LayoutContext.Provider value={{
      backLink,
      hideBackLink,
      backCallback,
    }}
    >

      <Header backLink={backLink} hideBackLink={hideBackLink} backCallback={backCallback} />
      <div className={`wrapper ${isEpicApp ? 'funnel-wrapper-epic' : 'funnel-wrapper'} ${className}`}>
        {children}
      </div>
    </LayoutContext.Provider>
  );
};

export default Layout;
