import { createReducer } from '@reduxjs/toolkit';
import { path } from 'ramda';
import { setToken } from 'libs/authenticationService';
import {
  getFromVault,
  getFromInvite,
} from 'actions/user';
import {
  getOfferByCode,
} from 'actions/offer';
import {
  updateApplication,
  getApplication,
  resetApplication,
  clearStore,
  updateApplicationPatient,
  setABTesting,
  updateLoginAttempts,
  setIsEpicApp,
} from 'actions/application';
import { createPatient } from 'actions/patient';
import { getFromMychart } from 'actions/epic';
import { selectProvider } from 'actions/provider';

export const AB_TESTING_PARAM = '0123';

const initialState = {
  getApplicationStatus: null,
  getFromVaultStatus: null,
  applicationId: null,
  completedAt: null,
  createdAt: null,
  currentView: null,
  deletedAt: null,
  isLoaded: false,
  patientId: null,
  providerId: null,
  status: null,
  statusDescription: null,
  updatedAt: null,
  partnerId: null,
  meta: {
    offerId: null,
    paymentMethodId: null,
  },
  tags: {
    hasPayInFullRedirect: false,
  },
  failedLoginAttempts: 0,
  isAltPage: false,
  isEpicApp: false,
};

const getActionReducer = (action) => ({
  [String(action.pending)]: (state) => ({
    ...state,
    getFromVaultStatus: 'pending',
    isLoaded: false,
  }),
  [String(action.fulfilled)]: (state, action) => ({
    ...state,
    getFromVaultStatus: 'fulfilled',
    isLoaded: true,
    ...action.payload.application,
    tags: {
      ...state.tags,
      ...action.payload.tags,
    },
  }),
  [String(action.rejected)]: (state) => ({
    ...state,
    getFromVaultStatus: 'rejected',
    isLoaded: false,
  }),
});

export default createReducer(initialState, {
  ...getActionReducer(getFromVault),
  ...getActionReducer(getFromInvite),
  ...getActionReducer(getFromMychart),
  ...getActionReducer(selectProvider),

  [String(updateApplication.pending)]: (state) => ({
    ...state,
    updateApplicationStatus: 'pending',
  }),
  [String(updateApplication.fulfilled)]: (state, action) => ({
    ...state,
    updateApplicationStatus: 'fulfilled',
    ...action.payload,
  }),
  [String(updateApplication.rejected)]: (state) => ({
    ...state,
    updateApplicationStatus: 'rejected',
  }),

  [String(getApplication.pending)]: (state) => ({
    ...state,
    getApplicationStatus: 'pending',
    isLoaded: false,
  }),
  [String(getApplication.fulfilled)]: (state, action) => ({
    ...state,
    getApplicationStatus: 'fulfilled',
    isLoaded: true,
    ...action.payload,
  }),
  [String(getApplication.rejected)]: (state) => ({
    ...state,
    getApplicationStatus: 'rejected',
    isLoaded: false,
  }),

  [String(getOfferByCode.pending)]: (state) => ({
    ...state,
    getApplicationStatus: 'pending',
    isLoaded: false,
  }),
  [String(getOfferByCode.fulfilled)]: (state, action) => ({
    ...state,
    ...action.payload.application,
    getApplicationStatus: 'fulfilled',
    isLoaded: true,
  }),
  [String(getOfferByCode.rejected)]: (state) => ({
    ...state,
    getApplicationStatus: 'rejected',
    isLoaded: false,
  }),

  [String(resetApplication.pending)]: (state) => ({
    ...state,
    resetApplicationstatus: 'pending',
  }),
  [String(resetApplication.fulfilled)]: () => ({
    ...initialState,
    resetApplicationstatus: 'fulfilled',
  }),
  [String(resetApplication.rejected)]: (state) => ({
    ...state,
    resetApplicationstatus: 'rejected',
  }),

  [String(createPatient.pending)]: (state) => ({
    ...state,
  }),
  [String(createPatient.fulfilled)]: (state, action) => ({
    ...state,
    ...action.payload.application,
  }),
  [String(createPatient.rejected)]: (state) => ({
    ...state,
  }),

  [String(updateApplicationPatient.pending)]: (state) => ({
    ...state,
  }),
  [String(updateApplicationPatient.fulfilled)]: (state, action) => {
    const token = path(['payload', 'auth', 'token'])(action);
    if (token) setToken(token);

    return {
      ...state,
    };
  },
  [String(updateApplicationPatient.rejected)]: (state) => ({
    ...state,
  }),

  [String(clearStore)]: (state) => ({
    ...initialState,
    isAltPage: state.isAltPage,
  }),

  [String(setABTesting)]: (state, action) => ({
    ...state,
    isAltPage: action.payload,
  }),

  [String(updateLoginAttempts)]: (state) => ({
    ...state,
    failedLoginAttempts: state.failedLoginAttempts + 1,
  }),

  [String(setIsEpicApp)]: (state, action) => ({
    ...state,
    isEpicApp: action.payload,
  }),
});
