import {
  compose, toLower, join, juxt, toUpper, head, tail,
} from 'ramda';

const textCapitalize = compose(
  join(''),
  juxt([compose(toUpper, head), tail]),
  toLower,
);

export default textCapitalize;
