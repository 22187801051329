import React, { useEffect } from 'react';
import { tail, compose, join } from 'ramda';

import Header from 'components/Header';
import Loading from 'components/Loading';

import generatePath from 'utils/generatePath';

import { noMatch, billInfo } from 'libs/views';

const PatientInvite = ({
  getPatientInvite,
  history,
  match,
  funnelType,
}) => {
  const onSuccess = (invite) => {
    const {
      subdomain,
      patientInviteId,
    } = invite.value;
    const {
      protocol,
      host,
    } = window.location;

    const isStg = process.env.REACT_APP_ENV === 'staging';

    const hostHandler = () => {
      const hostObj = host.split('.');
      if (isStg) return host;
      if (hostObj.length > 2) return compose(join('.'), tail)(hostObj);
      return host;
    };

    if (subdomain) {
      const redirectUrl = `${protocol}//${subdomain}.${hostHandler()}${billInfo.route[0]}?patientInviteId=${patientInviteId}`;
      window.location.replace(redirectUrl);
    } else {
      const redirectUrl = `${billInfo.route[0]}?patientInviteId=${patientInviteId}`;
      history.replace(redirectUrl);
    }
  };

  const onFailure = () => history.push(generatePath(funnelType, noMatch));

  const { inviteCode } = match.params;

  useEffect(() => {
    getPatientInvite({ inviteCode })
      .then(onSuccess)
      .catch(onFailure);
  }, []);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="d-flex align-items-center flex-fill">
        <div className="document__loader text-center">
          <Loading />
        </div>
      </div>
    </div>
  );
};

export default PatientInvite;
