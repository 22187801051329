import React from 'react';
import ReactModal from 'react-modal';
import NumberFormat from 'react-number-format';

import Button from 'components/Button';
import Link from 'components/Link';

import { SUPPORT_PHONE_NUMBER } from 'constants/support';

const SupportPhoneModal = ({
  isOpen, setIsOpen,
}) => {
  const onCloseModal = () => setIsOpen(!isOpen);

  return (
    <ReactModal
      isOpen={isOpen}
      className="main-modal main-modal--small"
      overlayClassName="main-modal__overlay"
      contentLabel="Support Phone number Modal"
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      closeTimeoutMS={300}
    >
      <div className="main-modal__content" data-testid="support-phone-number-modal">
        <div className="p-6">
          <h1 className="text-tuna font-weight-normal text-xlarger mb-5" data-testid="support-phone-number-modal-title">
            Contact customer support
          </h1>
          <p className="text-storm-gray font-weight-bold mb-2" data-testid="support-phone-number-modal-label">
            Phone Number
          </p>
          <p className="text-larger text-tuna font-weight-bold mb-6">
            <NumberFormat
              value={SUPPORT_PHONE_NUMBER}
              displayType="text"
              format="###-###-#####"
              data-testid="support-phone-number-modal-phone-number"
            />
          </p>
          <div>
            <Link
              className="btn btn-primary px-7 mr-4 font-weight-bold"
              href={`tel:${SUPPORT_PHONE_NUMBER}`}
              target="_self"
              data-testid="support-phone-number-modal-call-button"
              eventName="call"
            >
              Call
              {' '}
              <NumberFormat
                value={SUPPORT_PHONE_NUMBER}
                displayType="text"
                format="###-###-#####"
              />
            </Link>
            <Button
              type="link"
              className="font-weight-bold px-7 text-decoration-none"
              onClick={onCloseModal}
              data-testid="support-phone-number-modal-dismiss-button"
              eventName="dismiss-phone-modal"
            >
              Dismiss
            </Button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default SupportPhoneModal;
