import { apiCall } from 'libs/authenticationService';
import {
  PUT, GET, POST,
} from 'libs/httpMethods';
import {
  APPLICATION,
  RESET_APPLICATION,
  UPDATE_APPLICATION_PATIENT,
  GET_APPLICATION_BY_CODE,
  SEND_SLACK_MESSAGE,
} from 'libs/apiUrls';

import mixpanelIdentify from 'utils/mixpanelIdentify';

export const reqUpdateApplication = ({
  patientId,
  applicationId,
  ...rest
}) => apiCall(PUT, APPLICATION({ patientId, applicationId }), { data: rest });

export const reqUpdateApplicationPatient = ({
  applicationId,
  ...rest
}) => apiCall(PUT, UPDATE_APPLICATION_PATIENT(applicationId), { data: rest })
  .then(mixpanelIdentify);

export const reqGetApplication = ({ patientId, applicationId }) =>
  apiCall(GET, APPLICATION({ patientId, applicationId }));

export const reqResetApplication = (data) =>
  apiCall(POST, RESET_APPLICATION(data.applicationId), { data });

export const reqGetApplicationByCode = (data) =>
  apiCall(GET, GET_APPLICATION_BY_CODE(data));

export const reqSendSlackMessage = (data) =>
  apiCall(POST, SEND_SLACK_MESSAGE, { data });
