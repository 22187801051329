import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ className, value }) => (
  <div className={`progress ${className}`}>
    <div
      className="progress-bar"
      role="progressbar"
      aria-valuenow={value}
      aria-valuemin="0"
      aria-valuemax="100"
      style={{ width: `${value}%` }}
    >
      <span className="sr-only">
        {`${value}% Complete`}
      </span>
    </div>
  </div>
);

export default ProgressBar;

ProgressBar.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
};

ProgressBar.defaultProps = {
  className: '',
  value: 0,
};
