import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({ error, errorInside = false }) => (
  <div className={`invalid-feedback ${errorInside ? 'invalid-feedback--inside-input' : ''}`} role="alert">
    <p className="mb-0" data-cy="inpunt-error" data-testid="error-message">
      {error}
    </p>
  </div>
);

ErrorMessage.propTypes = {
  error: PropTypes.string,
};

ErrorMessage.defaultProps = {
  error: '',
};

export default ErrorMessage;
