import { createReducer } from '@reduxjs/toolkit';

import {
  createPlaidPaymentMethod,
  createManualPaymentMethod,
  setCardInfo,
  createManualCardPaymentMethod,
} from 'actions/paymentMethod';
import { clearStore } from 'actions/application';

const initialState = {
  status: null,
  isLoaded: false,
  card: {
    paymentToken: null,
    firstName: null,
    lastName: null,
    bin: null,
    exp: null,
    hash: null,
    number: null,
    type: null,
  },
};

export default createReducer(initialState, {
  [String(createPlaidPaymentMethod.pending)]: (state) => ({
    ...state,
    status: 'pending',
    isLoaded: false,
  }),
  [String(createPlaidPaymentMethod.fulfilled)]: (state, action) => ({
    ...state,
    status: 'fulfilled',
    isLoaded: true,
    ...action.payload,
  }),
  [String(createPlaidPaymentMethod.rejected)]: (state) => ({
    ...state,
    status: 'rejected',
    isLoaded: false,
  }),

  [String(createManualPaymentMethod.pending)]: (state) => ({
    ...state,
    status: 'pending',
    isLoaded: false,
  }),
  [String(createManualPaymentMethod.fulfilled)]: (state, action) => ({
    ...state,
    status: 'fulfilled',
    isLoaded: true,
    ...action.payload,
  }),
  [String(createManualPaymentMethod.rejected)]: (state) => ({
    ...state,
    status: 'rejected',
    isLoaded: false,
  }),

  [String(createManualCardPaymentMethod.pending)]: (state) => ({
    ...state,
    status: 'pending',
    isLoaded: false,
  }),
  [String(createManualCardPaymentMethod.fulfilled)]: (state, action) => ({
    ...state,
    status: 'fulfilled',
    isLoaded: true,
    ...action.payload,
  }),
  [String(createManualCardPaymentMethod.rejected)]: (state) => ({
    ...state,
    status: 'rejected',
    isLoaded: false,
  }),

  [String(setCardInfo)]: (state, action) => ({
    ...state,
    card: action.payload,
  }),

  [String(clearStore)]: () => ({
    ...initialState,
  }),
});
