export const CREATE_PATIENT = 'v1/patients';

export const GET_FROM_VAULT = 'v1/users/vault';

export const GET_FROM_INVITE = 'v1/users/invite';

export const GET_FROM_MYCHART = 'v1/users/epic';

export const SELECT_PROVIDER = 'v1/epic/accounts-by-provider';

export const UPDATE_BILL = (billId) => `v1/bills/${billId}`;

export const GET_BILL_FILES = (billId) => `v1/bills/${billId}/bill-files`;

export const UPDATE_USER = 'v1/user';

export const UPDATE_PATIENT = 'v1/patient';

export const CREATE_PATIENT_OFFERS = (patientId) => `v1/patients/${patientId}/offers`;

export const VIEW_PATIENT_OFFER = ({ patientId, offerId }) => `v1/patients/${patientId}/offers/${offerId}/view`;

export const OFFER = (offerId) => `v1/offers/${offerId}`;

export const APPLICATION = ({ patientId, applicationId }) => `v1/patients/${patientId}/applications/${applicationId}`;

export const UPDATE_APPLICATION_PATIENT = (applicationId) => `v1/applications/${applicationId}/patient`;

export const GET_USER = 'v1/user';

export const GET_PATIENT = 'v1/patient';

export const GET_BILL = ({ patientId, providerId }) => `v1/patients/${patientId}/bills?providerId=${providerId}`;

export const CREATE_PLAID_PAYMENT_METHOD = 'v1/payment-methods/plaid/ach';

export const CREATE_MANUAL_PAYMENT_METHOD = 'v1/payment-methods/manual/ach';

export const CREATE_MANUAL_CARD_PAYMENT_METHOD = 'v1/payment-methods/manual/card';

export const MAKE_PATIENT_PAYMENT = 'v1/payment-methods/patient/pay';

export const MAKE_PATIENT_PAYMENT_PARTNER = 'v1/payment-methods/patient/pay/partner';

export const LOG_PLAID_EVENT = 'v1/payment-methods/plaid/event';

export const GET_OFFER_PAYMENT = (offerId) => `v1/offers/${offerId}/payment-made`;

export const RESET_APPLICATION = (applicationId) => `v1/application/${applicationId}/reset`;

export const CONFIRM_PATIENT_OFFERS = (patientId, offerId) => `v1/patients/${patientId}/offers/${offerId}/confirm`;

export const GET_DOCUMENT = (apiDocId) => `v1/documents/${apiDocId}`;

export const GET_DOCUMENT_SIGNED = ({ offerId, apiDocId }) => `/v1/documents/${offerId}/${apiDocId}/signed`;

export const GET_PARTNER_INFO = 'v1/partners';

export const GET_PROVIDER_NAME = 'v1/providers/query';

export const CREATE_PROVIDER = 'v1/providers';

export const CREATE_PARTNER_BILL = (patientId) => `v1/partner/patients/${patientId}/bills`;

export const TWO_FACTOR_CODE_VERIFICATION = 'v1/auth/2fa';

export const SEND_TO_CRM = '/v1/patients/send-to-crm';

export const GET_PATIENT_INVITE = (inviteCode) => `/v1/patient-invites/${inviteCode}`;

export const GET_OFFER_BY_CODE = (code) => `/v1/offer/code/${code}`;

export const GET_APPLICATION_BY_CODE = (code) => `/v1/application/code/${code}`;

export const GET_PROVIDER_CONFIG = '/v1/providers/website';

export const SEND_SLACK_MESSAGE = '/v1/applications/activity-monitor';

export const GENERATE_OFFERS = '/v1/offers/generate';

export const CREATE_OFFER = '/v1/offers';

export const GET_GENERATED_OFFERS = '/v1/offers/all';

export const BANK_ACCOUNT_VALIDATE = 'v1/bank-account/validate';

export const ADDRESS_AUTO_COMPLETE = 'v1/users/address/auto-complete';
