import { connect } from 'react-redux';

import { updateOffer, getOffer } from 'actions/offer';
import { updateApplication } from 'actions/application';
import { sendToCrm } from 'actions/patient';
import { getDocumentSigned } from 'actions/documents';

import PaymentPlanPartnerResume from './PaymentPlanPartnerResume';

const mapStateToProps = ({
  offer,
  application: { applicationId, currentView, patientId },
  bill,
  user,
  documents: { documentSigned },
}) => ({
  offer,
  applicationId,
  currentView,
  patientId,
  bill,
  user,
  documentSigned,
});

const mapDispatchToProps = {
  updateOffer,
  updateApplication,
  getOffer,
  sendToCrm,
  getDocumentSigned,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentPlanPartnerResume);
