import React from 'react';

import payzenLogo from 'assets/images/payzen-logo-colored.svg';

const PoweredByIcon = ({ poweredByText, poweredByIconUrl }) => {
  if (poweredByText) return poweredByText;
  if (poweredByIconUrl) return <img data-testid="powered-by-provider-logo" src={poweredByIconUrl} alt="" height="13" />;
  return <img data-testid="powered-by-payzen-logo" src={payzenLogo} alt="" className="ml-1" height="13" />;
};

export default PoweredByIcon;
