import { connect } from 'react-redux';

import { updateApplication, clearStore } from 'actions/application';
import { createPatient } from 'actions/patient';

import AllSet from './AllSet';

const mapStateToProps = ({
  application: {
    applicationId, patientId, partnerId,
  },
  user,
}) => ({
  applicationId,
  patientId,
  user,
  partnerId,
});

const mapDispatchToProps = {
  updateApplication,
  clearStore,
  createPatient,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllSet);
