import { connect } from 'react-redux';

import { getFromMychart } from 'actions/epic';
import { setIsEpicApp } from 'actions/application';
import { getProviderConfig } from 'actions/provider';

import Mychart from './Mychart';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  getFromMychart,
  setIsEpicApp,
  getProviderConfig,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Mychart);
