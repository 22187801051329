import { connect } from 'react-redux';

import { getFromVault } from 'actions/user';
import { sendSlackMessage } from 'actions/application';

import SSN from './SSN';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  getFromVault,
  sendSlackMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SSN);
