import React, { useEffect } from 'react';
import {
  path,
  compose,
  filter,
  prop,
  propEq,
  head,
  isNil,
  equals,
  pathOr,
  isEmpty,
} from 'ramda';

import { loggedIn, getProfile } from 'libs/authenticationService';
import funnelViews from 'libs/funnelViews';
import { error as errorPage, billInfo, planExists } from 'libs/views';

import generatePath from 'utils/generatePath';
import getFunnelType from 'utils/getFunnelType';

const withProvider = (Component) => (props) => {
  const {
    user,
    getUser,
    getPatient,
    getApplication,
    getBill,
    getOffer,
    getOfferPayment,
    getFromVaultStatus,
    getApplicationStatus,
    history,
    paymentMadeStatus,
    currentView,
    location,
    applicationStatus,
    pendingBills,
    offerId,
  } = props;

  const { applicationId } = loggedIn() && getProfile();
  const funnelType = getFunnelType(location);
  const resumeFromLink = pathOr(false, ['state', 'resumeFromLink'])(location);

  const onFailure = () => history.push(generatePath(funnelType, errorPage));

  const getBillSuccess = (response) => {
    const offerId = compose(
      prop('offerId'),
      head,
      filter(prop('offerId')),
      filter(propEq('status', 'BILL_PENDING')),
      path(['action', 'payload']),
    )(response);

    return getOffer(offerId).catch(onFailure);
  };

  const getOfferSuccess = (response) => {
    const offerId = path(['action', 'payload', 'offerId'])(response);
    return getOfferPayment(offerId).catch(onFailure);
  };

  const getApplicationSuccess = (response) => {
    const currentView = path(['action', 'payload', 'currentView'])(response);
    const patientId = path(['action', 'payload', 'patientId'])(response);
    const providerId = path(['action', 'payload', 'providerId'])(response);

    switch (currentView) {
      case funnelViews.bills:
      case funnelViews.personalInfo:
      case funnelViews.financialInfo: {
        return getBill({ patientId, providerId })
          .catch(onFailure);
      }
      case funnelViews.offer: {
        return getBill({ patientId, providerId })
          .then(getBillSuccess)
          .catch(onFailure);
      }
      case funnelViews.connectBank:
      case funnelViews.success: {
        return getBill({ patientId, providerId })
          .then(getBillSuccess)
          .then(getOfferSuccess)
          .catch(onFailure);
      }
      default:
        return null;
    }
  };

  const getPatientSuccess = (response) => {
    const patientId = path(['action', 'payload', 'patientId'])(response);

    return getApplication({ patientId, applicationId })
      .then(getApplicationSuccess)
      .catch(onFailure);
  };

  const getUserSuccess = () => getPatient().then(getPatientSuccess).catch(onFailure);

  useEffect(() => {
    if (equals(applicationStatus, 'APPLICATION_COMPLETED') && !equals(history.action, 'REPLACE')) {
      if (isEmpty(pendingBills)) {
        history.replace(generatePath(funnelType, planExists));
      } else {
        history.replace(generatePath(funnelType, billInfo));
      }
    }
  }, []);

  useEffect(() => {
    const isConnectBankView = equals(currentView, 'FUNNEL_CONNECT_BANK');
    const { action } = history;
    const isActionPop = equals(action, 'POP');
    const isLoggedIn = loggedIn();
    const isNilGetApplicationStatus = resumeFromLink ? true : isNil(getApplicationStatus);

    if (isLoggedIn && equals(getFromVaultStatus, 'fulfilled') && isNil(paymentMadeStatus) && !isNil(offerId) && isConnectBankView) {
      getOfferPayment(offerId).catch(onFailure);
    }

    if (
      isLoggedIn
      && isNilGetApplicationStatus
      && !user.isLoaded
      && applicationId
      && (isActionPop || resumeFromLink)
    ) {
      getUser().then(getUserSuccess).catch(onFailure);
    }
  }, [getFromVaultStatus, getApplicationStatus, currentView]);

  return <Component {...props} />;
};

export default withProvider;
