import React, { useState, useEffect } from 'react';
import {
  isNil, pathOr, toLower, equals, length, head, isEmpty,
} from 'ramda';

import funnelViews from 'libs/funnelViews';
import {
  billInfo,
  reviewInfo,
  error as errorPage,
  selectUser,
  selectAccount,
} from 'libs/views';

import generatePath from 'utils/generatePath';
import isMinorPatient from 'utils/isMinorPatient';
import { filterGuarantors } from 'utils/guarantors';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Loading from 'components/Loading';

import SingleBill from 'components/InvoiceToPay/SingleBill';
import MultipleBills from 'components/InvoiceToPay/MultipleBills';

const InvoiceToPay = ({
  firstName,
  history,
  bills,
  updateBill,
  applicationId,
  currentView,
  patientId,
  updateApplication,
  location,
  funnelType,
  applicationStatus,
  providerName,
  hasPayInFullRedirect,
  payInFullUri,
  guarantors,
  birthDate,
  userBackup,
  isEpicApp,
  showOrganizationView,
}) => {
  const checkBills = pathOr(false, ['location', 'state', 'checkBills'])(history);
  const [isSingleBill, setIsSingleBill] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const resumeApp = pathOr(false, ['state', 'resumeApp'])(location);
  const filteredGuarantors = filterGuarantors(userBackup, guarantors);
  const hasGuarantor = !isEmpty(filteredGuarantors) && !isMinorPatient(birthDate);

  const submitSuccess = () => history.push(generatePath(funnelType, reviewInfo));
  const updateBillFailure = () => history.push(generatePath(funnelType, errorPage));

  useEffect(() => {
    if (!isEmpty(bills)) {
      setIsSingleBill(equals(length(bills), 1));
    }
  }, [bills]);

  useEffect(() => {
    if (isNil(currentView) && applicationId && applicationStatus !== 'APPLICATION_COMPLETED') {
      updateApplication({
        patientId,
        applicationId,
        currentView: funnelViews.bills,
      });
    }
  }, [currentView]);

  const patientFirstName = userBackup.firstName && toLower(userBackup.firstName);
  const patientLastName = userBackup.lastName && toLower(userBackup.lastName);

  const userName = `${patientFirstName} ${patientLastName}`;

  const TEXT = isSingleBill
    ? (
      <>
        The following bill for
        {' '}
        <span className="text-capitalize">{userName}</span>
        {' '}
        shows the final amount after insurance claims.
      </>
    ) : (
      <>
        The following bills for
        {' '}
        <span className="text-capitalize">{userName}</span>
        {' '}
        show the final amount after insurance claims.
      </>
    );

  const getGoBackRoute = () => {
    if (showOrganizationView && !hasGuarantor) return selectAccount;
    if (showOrganizationView && hasGuarantor) return selectUser;
    if (!showOrganizationView && hasGuarantor) return selectUser;
    return billInfo;
  };

  const goBackRoute = getGoBackRoute();

  return (
    <Layout
      backLink={generatePath(funnelType, goBackRoute)}
      hideBackLink={isEpicApp}
    >
      <Card
        className={`mt-2 ${isSingleBill ? null : 'funnel-card--invoice'}`}
        progressBarValue={15.625}
        showBillingMessage
        content={(
          <div className="funnel-card__wrapper">
            {isFetching && (
              <div className="connect-bank__loader text-center">
                <Loading />
              </div>
            )}
            <div className="text-center">
              <h1 className="h4 font-weight-normal mb-5" data-cy="bills-title">
                {isEpicApp ? 'Select Your Bills' : (
                  <>
                    Welcome
                    {resumeApp ? ' back' : ''}
                    ,
                    {' '}
                    <span className="text-capitalize" data-cy="bills-patientname">
                      {firstName && toLower(firstName)}
                      !
                    </span>
                  </>
                )}
              </h1>
              <div className="funnel-card__form mb-3">
                <p className="text-center mb-0">
                  {TEXT}
                </p>
              </div>
            </div>
            {isSingleBill ? (
              <SingleBill
                bill={head(bills)}
                updateBill={updateBill}
                submitSuccess={submitSuccess}
                hasPayInFullRedirect={hasPayInFullRedirect}
                payInFullUri={payInFullUri}
                providerName={providerName}
              />
            ) : (
              <MultipleBills
                bills={bills}
                setIsFetching={setIsFetching}
                updateBill={updateBill}
                updateBillFailure={updateBillFailure}
                submitSuccess={submitSuccess}
                checkBills={checkBills}
                hasPayInFullRedirect={hasPayInFullRedirect}
                payInFullUri={payInFullUri}
                providerName={providerName}
              />
            )}
          </div>
        )}
      />
    </Layout>
  );
};

export default InvoiceToPay;
