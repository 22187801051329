import React from 'react';
import { gt, prop } from 'ramda';

import Icon from 'components/Icon';

const Counter = ({ field, form }) => {
  const value = prop('value')(field);
  const name = prop('name')(field);
  const setFieldValue = prop('setFieldValue')(form);

  const add = () => setFieldValue(name, value + 1);
  const sub = () => setFieldValue(name, value - 1);

  return (
    <div className="counter">
      <button
        type="button"
        onClick={sub}
        className="line-height-0"
        disabled={!gt(value, 0)}
      >
        <Icon name="sub" />
      </button>
      <span>{value}</span>
      <button
        type="button"
        onClick={add}
        className="line-height-0"
      >
        <Icon name="add" />
      </button>
    </div>
  );
};

export default Counter;
