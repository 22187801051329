import { createAsyncAction } from 'redux-promise-middleware-actions';
import { prop } from 'ramda';

import { reqUpdateBill, reqGetBill, reqGetBillFiles } from 'api/bill';

export const updateBill = createAsyncAction(
  'UPDATE_BILL',
  (payload) => reqUpdateBill(payload).then(prop('data')),
);

export const getBill = createAsyncAction(
  'GET_BILL',
  (payload) => reqGetBill(payload).then(prop('data')),
);

export const getBillFiles = createAsyncAction(
  'GET_BILL_FILES',
  (payload) => reqGetBillFiles(payload).then(prop('data')),
);
