import React from 'react';

import ErrorTULayout from 'components/ErrorTULayout';

const NoCreditRecordFound = () => (
  <ErrorTULayout>
    <p className="mb-6">
      There is no credit record associated with the
      <br />
      information you provided.
    </p>
  </ErrorTULayout>
);

export default NoCreditRecordFound;
