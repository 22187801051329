import React from 'react';

import Currency from 'components/Currency';

const PatientBalance = ({ accumulator, hasBalanceError }) => (
  <div data-private className="d-flex justify-content-between align-items-center mb-4" data-cy="bills-balance">
    <p className="text-storm-gray text-small mb-0">Include in Payment Plan</p>
    <Currency
      amount={accumulator}
      className={`text-tuna text-larger ${hasBalanceError ? 'text-burnt-sienna' : ''}`}
    />
  </div>
);

export default PatientBalance;
