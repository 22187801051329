import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, withRouter } from 'react-router-dom';
import { isEmpty } from 'ramda';

import Icon from 'components/Icon';
import Link from 'components/Link';

import ProviderLogo from 'components/ProviderLogo';
import MonthlyPaymentsMessage from 'components/MonthlyPaymentsMessage';

const Header = ({
  backLink,
  hideBackLink,
  logoUrl,
  pageTitle,
  backCallback,
  poweredByText,
  poweredByIconUrl,
  isEpicApp,
  isErrorPage,
}) => {
  const history = useHistory();

  const onGoBack = () => {
    if (backCallback) backCallback();
  };

  if (isEpicApp) return null;

  return (
    <header className="main-header sticky-top text-center" data-testid="header">
      {(!isEmpty(backLink) && !hideBackLink) && (
        <div className="main-header__back">
          <Link
            data-testid="back-button"
            to={backLink}
            onClick={onGoBack}
            className="d-flex align-items-center text-decoration-none"
            eventName="go-back"
          >
            <Icon name="caret-left" className="text-dodger-blue text-larger" />
            <span className="ml-3 d-none d-sm-block">Back</span>
          </Link>
        </div>
      )}
      {(isErrorPage && !hideBackLink) && (
        <div className="main-header__back">
          <Link
            data-testid="back-button"
            onClick={history.goBack}
            className="d-flex align-items-center text-decoration-none bg-transparent border-0 text-dodger-blue p-0"
            eventName="go-back"
          >
            <Icon name="caret-left" className="text-dodger-blue text-larger" />
            <span className="ml-3 d-none d-sm-block">Back</span>
          </Link>
        </div>
      )}
      <div>
        <ProviderLogo logoUrl={logoUrl} pageTitle={pageTitle} />
        <MonthlyPaymentsMessage
          poweredByText={poweredByText}
          poweredByIconUrl={poweredByIconUrl}
          data-testid="header-monthly-payments"
        />
      </div>
    </header>
  );
};

export default withRouter(Header);

Header.propTypes = {
  backLink: PropTypes.string,
  isErrorPage: PropTypes.bool,
};

Header.defaultProps = {
  backLink: '',
  isErrorPage: false,
};
