import React, { useState } from 'react';
import { compose, join, values } from 'ramda';
import { Link } from 'react-router-dom';

import Loading from 'components/Loading';
import PhoneForm from 'components/Forms/PhoneForm';

import getSubdomain from 'utils/getSubdomain';
import generatePath from 'utils/generatePath';

import { billInfo } from 'libs/views';

const Footer = ({ sendSlackMessage, patientInfo }) => {
  const providerName = getSubdomain(window);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async ({ phone }) => {
    setIsLoading(true);

    const data = compose(
      join(', '),
      values,
    )({
      ...patientInfo,
      phone,
    });

    await sendSlackMessage({
      type: 'error-form',
      title: 'Submitted form error page',
      provider: providerName,
      patientInfo: data,
    });

    setSubmitted(true);
    setIsLoading(false);
  };

  if (isLoading) return <Loading message="" />;

  if (submitted) {
    return (
      <>
        <p className="mb-1" data-testid="lets-get-in-touch-submitted-text">
          Thanks, we got your number! We will reach out soon.
        </p>
        <Link to={generatePath('app', billInfo)} data-testid="lets-get-in-touch-submitted-link">
          Back to login
        </Link>
      </>
    );
  }

  return (
    <>
      <p className="mb-2" data-testid="lets-get-in-touch-form-text">
        Can&apos;t talk right now? Enter your phone number
        below and we&apos;ll be in touch soon:
      </p>
      <PhoneForm onSubmit={onSubmit} />
    </>
  );
};

export default Footer;
