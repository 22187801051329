import React, { useState } from 'react';
import {
  Formik, Form, Field,
} from 'formik';
import {
  isEmpty, compose, filter, values,
} from 'ramda';

import Button from 'components/Button';
import { Input, InfoBubble } from 'components/Fields';

const PersonalInfo = ({
  initialValues,
  validationSchema,
  onSubmit,
  isSmsLink,
  isDemo,
  buttonText,
  shouldHideIncome,
}) => {
  const [focusAnnualIncome, setFocusAnnualIncome] = useState(false);
  const showAddressField = () => {
    if (isDemo) return false;
    return !initialValues.hasAddress;
  };

  const isEmailDisabled = isDemo && initialValues.email;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnMount={shouldHideIncome ? false : !isEmpty(initialValues.annualIncome)}
    >
      {({
        isSubmitting, values: formData, errors,
      }) => {
        const isDataComplete = !compose(isEmpty, filter(isEmpty), values)(formData);
        const withErrors = !isEmpty(errors);
        const isDisabled = isSubmitting || isDataComplete || withErrors;
        const showInfoBubble = focusAnnualIncome && formData.annualIncome && !errors.annualIncome;

        return (
          <Form>
            <div className="mb-5">
              {showAddressField() && (
                <Field
                  id="fullAddress"
                  type="address"
                  name="fullAddress"
                  label="Home Address"
                  component={Input}
                />
              )}
              <Field
                id="email"
                type="email"
                name="email"
                label="Email"
                component={Input}
                data-cy="email"
                disabled={isEmailDisabled}
              />
              {!isSmsLink && (
                <Field
                  id="phone"
                  type="tel"
                  name="phone"
                  label="Mobile Number"
                  component={Input}
                  data-cy="phone"
                />
              )}
              {!shouldHideIncome && (
                <Field
                  id="annualIncome"
                  type="currency"
                  name="annualIncome"
                  label="Annual Household Income"
                  icon="$"
                  component={Input}
                  onFocus={() => setFocusAnnualIncome(true)}
                  data-cy="annual-income"
                />
              )}
              {showInfoBubble && (
                <div className="mb-2 mb-sm-4">
                  <InfoBubble
                    content="Make sure you enter your YEARLY gross income, not your monthly"
                    className="py-3"
                    data-cy="income-warning"
                  />
                </div>
              )}
            </div>
            <Button
              type="primary"
              className="btn-block"
              isSubmit
              disabled={isDisabled}
              data-cy="info-continue"
              eventName="main-cta"
            >
              {buttonText}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PersonalInfo;
