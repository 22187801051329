import React from 'react';

import Icon from 'components/Icon';

const Banner = ({ content, isAppFunnel }) => {
  if (isAppFunnel) {
    return (
      <div className="funnel-card__banner d-sm-flex justify-content-sm-center align-items-sm-center">
        {content}
      </div>
    );
  }

  return (
    <div className="funnel-card__notification mx-auto">
      <div className="funnel-card__notification__content">
        <div className="media">
          <Icon name="info" className="funnel-card__notification__icon mt-1 mr-3" />
          <div className="media-body text-small">
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
