import { apiCall } from 'libs/authenticationService';
import {
  POST, PUT, GET,
} from 'libs/httpMethods';
import {
  CREATE_PATIENT_OFFERS,
  OFFER,
  CONFIRM_PATIENT_OFFERS,
  VIEW_PATIENT_OFFER,
  GET_OFFER_BY_CODE,
  GENERATE_OFFERS,
  CREATE_OFFER,
  GET_GENERATED_OFFERS,
} from 'libs/apiUrls';

export const reqCreatePatientOffers = ({ id, data }) =>
  apiCall(POST, CREATE_PATIENT_OFFERS(id), { data });

export const reqUpdateOffer = ({ id, data }) => apiCall(PUT, OFFER(id), { data });

export const reqGetOffer = (id) => apiCall(GET, OFFER(id));

export const reqConfirmPatientOffer = ({ patientId, offerId, data }) =>
  apiCall(POST, CONFIRM_PATIENT_OFFERS(patientId, offerId), { data });

export const reqViewPatientOffer = (data) =>
  apiCall(POST, VIEW_PATIENT_OFFER(data));

export const reqGetOfferByCode = (code) => apiCall(GET, GET_OFFER_BY_CODE(code));

export const reqGenerateOffers = (data) => apiCall(POST, GENERATE_OFFERS, { data });

export const reqCreateOffer = (data) => apiCall(POST, CREATE_OFFER, { data });

export const reqGetGeneratedOffers = () => apiCall(GET, GET_GENERATED_OFFERS);
