import { connect } from 'react-redux';
import { compose } from 'redux';

import { getPartnerInfo } from 'actions/patient';
import { clearStore, getApplication, getApplicationByCode } from 'actions/application';
import { getOfferByCode } from 'actions/offer';
import { getFromVault } from 'actions/user';
import { getProviderConfig } from 'actions/provider';

import RedirectToFunnel from './RedirectToFunnel';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  getPartnerInfo,
  clearStore,
  getApplication,
  getOfferByCode,
  getApplicationByCode,
  getFromVault,
  getProviderConfig,
};

export default compose(connect(
  mapStateToProps,
  mapDispatchToProps,
), RedirectToFunnel);
