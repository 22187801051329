import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { pathOr } from 'ramda';

import Header from 'components/Header';
import Card from 'components/Funnel/Card';
import Icon from 'components/Icon';
import Link from 'components/Link';
import { maintenance } from 'libs/views';
import generatePath from 'utils/generatePath';
import getSubdomain from 'utils/getSubdomain';

const ErrorTULayout = ({
  children,
  provider,
  isEpicApp,
  getProviderConfig,
}) => {
  const history = useHistory();
  const providerName = getSubdomain(window);

  useEffect(() => {
    if (providerName && !provider.isLoaded && !isEpicApp) {
      getProviderConfig({ subdomain: providerName })
        .then((response) => {
          const maintenanceMode = pathOr(false, ['value', 'maintenanceMode'])(response);
          if (maintenanceMode) return history.replace(generatePath('app', maintenance));
          return null;
        })
        .catch(() => window.location.replace('https://payzen.com/'));
    }
  }, [providerName, provider.isLoaded, isEpicApp]);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header isErrorPage />
      <div className={`wrapper ${isEpicApp ? 'funnel-wrapper-epic' : 'funnel-wrapper'}`}>
        <Card
          isErrorPage
          className="mt-2 mt-sm-0"
          content={(
            <div className="error-page tu-error-page text-center mx-auto text-emperor font-weight-semibold d-flex align-items-center">
              <div className="flex-fill">
                <div>
                  <Icon name="tu-error" className="error-page__icon mb-5 mm-md-6" />
                  <h1 className="h4 mb-3" data-cy="tu-error-title">
                    We are unable to offer you a
                    <br />
                    Payment Plan at this time
                  </h1>
                  <div className="mb-6">
                    {children}
                  </div>
                  {provider.providerUrl && provider.providerName && (
                    <Link
                      href={provider.providerUrl}
                      className="btn btn-primary text-decoration-none px-10 py-4"
                      eventName="go-to-provider-website"
                    >
                      Go to
                      {' '}
                      <span className="text-capitalize">
                        {provider.providerName.toLowerCase()}
                      </span>
                      &apos;s
                      {' '}
                      website
                    </Link>
                  )}
                </div>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default ErrorTULayout;
