import React from 'react';
import { split, isEmpty } from 'ramda';
import qs from 'qs';

const LogoImage = ({ logoUrl }) => {
  const url = split('?', logoUrl)[0];
  const params = split('?', logoUrl)[1];
  const size = qs.parse(params);
  const props = isEmpty(size) ? { style: { maxHeight: '28px' } } : size;

  return <img src={url} alt="" {...props} />;
};

export default LogoImage;
