import { connect } from 'react-redux';

import { verificationCode, getUser } from 'actions/user';
import { getPatient } from 'actions/patient';
import { getApplication } from 'actions/application';
import { getBill } from 'actions/bill';
import { getOffer } from 'actions/offer';
import { getOfferPayment } from 'actions/paymentMade';

import VerifyCode from './VerifyCode';

const mapStateToProps = ({
  user: { twoFactorToken },
  application,
}) => ({
  twoFactorToken,
  application,
});

const mapDispatchToProps = {
  verificationCode,
  getUser,
  getPatient,
  getApplication,
  getBill,
  getOffer,
  getOfferPayment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerifyCode);
