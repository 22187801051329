import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import useTrackMixpanelEvent from 'utils/useTrackMixpanelEvent';

const Link = ({
  id,
  children,
  onClick = () => { },
  eventName = '',
  href = '',
  to = '',
  ...rest
}) => {
  const trackMixpanelEvent = useTrackMixpanelEvent();

  const onClickWrapper = (e) => {
    trackMixpanelEvent('LC', eventName, {
      'link id': id,
      'link content': children,
    });

    return onClick(e);
  };

  if (href) {
    return (
      <a
        {...rest}
        href={href}
        onClick={onClickWrapper}
        rel="noreferrer"
      >
        {children}
      </a>
    );
  }

  if (to) {
    return (
      <ReactRouterLink
        {...rest}
        to={to}
        onClick={onClickWrapper}
      >
        {children}
      </ReactRouterLink>
    );
  }

  return (
    <button
      {...rest}
      type="button"
      onClick={onClickWrapper}
    >
      {children}
    </button>
  );
};

export default Link;
