import * as R from 'ramda';

const getBillsOfferId = R.compose(
  R.prop('offerId'),
  R.head,
  R.filter(R.prop('offerId')),
  R.filter(R.propEq('status', 'BILL_PENDING')),
);

export default getBillsOfferId;
