import React, { useState } from 'react';
import NumberFormat from 'react-number-format';

import Icon from 'components/Icon';

const EncryptedInput = ({ className, value, ...props }) => {
  const [type, setType] = useState('password');
  const isEncrypted = type === 'password';

  const onToggle = () => {
    if (isEncrypted) {
      return setType('text');
    }
    return setType('password');
  };

  const fakeText = [...Array(value.length).keys()].reduce(
    (previousValue) => `${previousValue}•`,
    '',
  );

  return (
    <>
      <NumberFormat
        {...props}
        type="text"
        className={`${className} ${isEncrypted ? 'is-encrypted' : ''}`}
        value={value}
        maxLength="20"
      />
      {isEncrypted && (
        <label htmlFor={props.id} className="fake-text-encrypted mb-0">
          {fakeText}
        </label>
      )}
      <button
        type="button"
        onClick={onToggle}
        className={`toggle-button ${isEncrypted ? '' : 'is-active'}`}
      >
        <Icon name={isEncrypted ? 'eye-hide' : 'eye-show'} />
      </button>
    </>
  );
};

export default EncryptedInput;
