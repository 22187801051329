/* eslint-disable camelcase */
import capitalize from 'utils/capitalize';
import {
  billInfoTestForm,
  billInfoDefaultForm,
} from 'utils/validationSchemas';

const testForm = {
  initialValues: {
    lastName: '',
    medicalRecordNumber: '',
    dateOfBirth: '',
  },
  validationSchema: billInfoTestForm,
  fields: [
    {
      name: 'lastName',
      type: 'text',
      label: 'Patient Last Name',
      onChangeHandler: capitalize,
    },
    {
      name: 'medicalRecordNumber',
      type: 'text',
      label: 'Medical Record Number',
    },
    {
      name: 'dateOfBirth',
      type: 'date',
      label: 'Patient Date of Birth',
      placeholder: 'MM/DD/YYYY',
      className: 'form-control--with-placeholder ',
    },
  ],
};

const defaultForm = {
  initialValues: {
    lastName: '',
    dateOfBirth: '',
  },
  validationSchema: billInfoDefaultForm,
  fields: [
    {
      name: 'lastName',
      type: 'text',
      label: 'Patient Last Name',
      onChangeHandler: capitalize,
    },
    {
      name: 'dateOfBirth',
      type: 'date',
      label: 'Patient Date of Birth',
      placeholder: 'MM/DD/YYYY',
      className: 'form-control--with-placeholder ',
    },
  ],
};

export default (providerName) => {
  switch (providerName) {
    case 'sutter':
      return testForm;
    default:
      return defaultForm;
  }
};
