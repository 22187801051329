/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import PropTypes from 'prop-types';

const notifications = {
  error: {
    defaultTitle: 'Error!',
  },
  warning: {
    defaultTitle: 'Warning!',
  },
  success: {
    defaultTitle: 'Success!',
  },
};

const Notification = ({ type, message, closeToast }) => (
  <>
    <h1 className="notifications__title">
      {type && notifications[type].defaultTitle}
    </h1>
    <p className="notifications__description">{message}</p>
    <span className="notifications__close-btn" onClick={closeToast}>
      &times;
    </span>
  </>
);

Notification.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
};

Notification.defaultProps = {
  type: '',
  message: '',
};

export default Notification;
