import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  isEmpty,
  map,
  compose,
  filter,
  sum,
  prop,
  head,
  isNil,
  includes,
  pluck,
  equals,
  keys,
  pick,
  reject,
  uniq,
  pathOr,
  propEq,
  length,
} from 'ramda';
import queryString from 'qs';

import { financialInfoForm } from 'utils/validationSchemas';
import cleanObject from 'utils/cleanObject';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import { Input, InfoBubble } from 'components/Fields';

import getErrorMessage from 'utils/getErrorMessage';
import generatePath from 'utils/generatePath';
import { convertToCents, convertToDollars } from 'utils/currency';

import funnelViews from 'libs/funnelViews';
import {
  reviewInfo,
  confirmSSN,
  paymentPlan,
  PRIVACY_NOTICE,
  AUTH_FOR_HEALTH_INFO,
  AUTH_FOR_HEALTH_INFO_PARTNER,
  CREDIT_REPORT_AUTH,
  ESIGNATURE_DISCLOSURE,
  error as errorPage,
  household,
  personalInfo,
} from 'libs/views';

import AdditionalIncomeMenu from './AdditionalIncomeMenu';

const hash = Math.random().toString(36).slice(2).substring(5);

const OptIncomeInput = ({
  id, name, label, onClick, initialValue,
}) => (
  <div className="position-relative">
    <Field
      id={id}
      type="currency"
      name={name}
      label={label}
      icon="$"
      component={Input}
    />
    {isEmpty(initialValue) && <Icon name="trash" className="text-larger text-spun-pearl btns-options__close btns-options__close--field clickable" onClick={onClick} />}
  </div>
);

const Banner = ({ isAppFunnel, isFetching }) => {
  if (isAppFunnel) {
    return (
      <div className="media align-items-center">
        {isFetching ? (
          <div className="text-center">
            <p className="mb-0">We’re busy crunching your numbers!</p>
          </div>
        ) : (
          <>
            <Icon name="sheet-check" className="h2 mb-0 mr-4" />
            <div className="media-body">
              <p className="mb-0">We build a monthly payment plan that you can afford!</p>
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <>
      We use this information to try to get your bill forgiven or
      provide you with an affordable payment option.
    </>
  );
};

const FinancialInfo = ({
  history,
  patient,
  updatePatient,
  createPatientOffers,
  bills,
  applicationId,
  currentView,
  updateApplication,
  offerId,
  getOffer,
  offerTotalAmount,
  location,
  firstName,
  funnelType,
}) => {
  const [showAddIncomeMenu, setShowAddIncomeMenu] = useState(false);
  const [showSpouseIncome, setShowSpouseIncome] = useState(!!patient.spouseIncome);
  const [showPropertyIncome, setShowPropertyIncome] = useState(!!patient.householdIncome);
  const [showOtherIncome, setShowOtherIncome] = useState(!!patient.additionalIncome);
  const [isFetching, setIsFetching] = useState(false);
  const [dirtyForm, setDirtyForm] = useState(false);
  const isAppFunnel = equals(funnelType, 'app');
  const backRoute = isAppFunnel ? reviewInfo : personalInfo;
  const successRoute = isAppFunnel ? paymentPlan : household;
  const bill = compose(head, filter(prop('providerId')))(bills);
  const providerId = prop('providerId')(bill);

  const resumeApp = pathOr(false, ['state', 'resumeApp'])(location);

  const includedInOffer = prop('includedInOffer');
  const statusPending = propEq('status', 'BILL_PENDING');
  const totalBalance = compose(sum, pluck('totalAmount'), filter(includedInOffer), filter(statusPending))(bills);
  const selectedBills = compose(pluck('billId'), filter(includedInOffer), filter(statusPending))(bills);
  const totalBalanceChange = !equals(offerTotalAmount, totalBalance.toString());

  const initialValues = {
    annualIncome: patient.annualIncome ? `${convertToDollars(patient.annualIncome)}` : '',
    spouseIncome: patient.spouseIncome ? `${convertToDollars(patient.spouseIncome)}` : '',
    householdIncome: patient.householdIncome ? `${convertToDollars(patient.householdIncome)}` : '',
    additionalIncome: patient.additionalIncome ? `${convertToDollars(patient.additionalIncome)}` : '',
  };

  useEffect(() => {
    setShowSpouseIncome(!!patient.spouseIncome);
    setShowPropertyIncome(!!patient.householdIncome);
    setShowOtherIncome(!!patient.additionalIncome);
  }, [patient]);

  const toggleShowAddIncomeMenu = () => setShowAddIncomeMenu(!showAddIncomeMenu);
  const toggleShowSpouseIncome = () => setShowSpouseIncome(!showSpouseIncome);
  const toggleShowPropertyIncome = () => setShowPropertyIncome(!showPropertyIncome);
  const toggleShowOtherIncome = () => setShowOtherIncome(!showOtherIncome);
  const patientId = prop('patientId')(patient);
  const getOfferSuccess = () => setIsFetching(false);

  const getOfferFailure = () => {
    setIsFetching(false);
    return history.push(generatePath(funnelType, errorPage));
  };

  useEffect(() => {
    const offerIdBills = compose(head, uniq, reject(isNil), pluck('offerId'))(bills);

    if (offerIdBills) {
      setIsFetching(true);

      getOffer(offerIdBills)
        .then(getOfferSuccess)
        .catch(getOfferFailure);
    }
  }, [bills]);

  const onSuccess = () => history.push(generatePath(funnelType, successRoute));

  const onFailure = (error) => {
    const errorMessage = getErrorMessage(error);

    setIsFetching(false);

    if (includes('DTI', errorMessage)) return history.push(generatePath(funnelType, errorPage), { type: 'enroll' });
    if (!includes('VerifySSN', errorMessage)) return history.push(generatePath(funnelType, errorPage), { type: 'enroll' });
    return history.push(generatePath(funnelType, confirmSSN));
  };

  const createOffer = () => createPatientOffers({
    id: patientId,
    data: {
      totalBalance,
      bills: selectedBills,
      firstBillingDate: new Date(),
      type: isAppFunnel ? 'PROVIDER' : 'PARTNER',
    },
  })
    .then(onSuccess)
    .catch(onFailure);

  const handleSubmit = (data, actions) => {
    setIsFetching(true);
    const keysToUpdate = offerId
      ? compose(keys, filter(isEmpty))(initialValues)
      : keys(initialValues);
    const dataToUpdate = pick(keysToUpdate)(data);
    const cleanData = compose(
      map(convertToCents),
      cleanObject,
    )(dataToUpdate);

    if (isEmpty(cleanData) && totalBalanceChange && !isEmpty(selectedBills)) return createOffer();

    if (isEmpty(cleanData) && offerId) return onSuccess();

    updatePatient(cleanData)
      .then(createOffer)
      .catch(onFailure);

    return actions.setSubmitting(false);
  };

  useEffect(() => {
    if (currentView !== funnelViews.financialInfo && applicationId) {
      updateApplication({
        patientId,
        applicationId,
        currentView: funnelViews.financialInfo,
      });
    }
  }, [currentView]);

  const onSetDirtyForm = (dirty) => {
    if (!dirtyForm && dirty) setDirtyForm(true);
    if (dirtyForm && !dirty) setDirtyForm(false);
  };

  const hipaaPath = isAppFunnel
    ? generatePath(funnelType, AUTH_FOR_HEALTH_INFO)
    : generatePath(funnelType, AUTH_FOR_HEALTH_INFO_PARTNER);

  const esignatureDisclosureUrl = generatePath(funnelType, ESIGNATURE_DISCLOSURE);
  const queryParams = queryString.stringify({ providerId }, { addQueryPrefix: true });
  const hackUseEffect = useEffect;

  return (
    <Layout
      backLink={generatePath(funnelType, backRoute)}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={financialInfoForm(initialValues)}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount={!isEmpty(initialValues.annualIncome)}
      >
        {({
          values,
          isSubmitting,
          dirty,
        }) => {
          hackUseEffect(() => {
            onSetDirtyForm(dirty);
          }, [dirty]);

          const valuesLength = compose(length, keys)(values);
          const showInfoBubble = compose(
            equals(valuesLength),
            length,
            keys,
            filter(isEmpty),
          )(values);

          return (
            <Card
              progressBarValue={18.3823529412}
              content={(
                <div className="funnel-card__wrapper d-flex flex-column financial-info">
                  {isFetching && (
                    <div className="financial-info__loader text-center">
                      <Loading />
                    </div>
                  )}
                  {isAppFunnel && (
                    <div className="text-center mb-2">
                      {resumeApp ? `Welcome back, ${firstName}!` : 'Great!'}
                    </div>
                  )}
                  <h1 className="text-center h4 font-weight-light mb-5">Let’s Get to Know You</h1>
                  <div className="funnel-card__form w-100 h-100">
                    <Form className="d-flex flex-column h-100">
                      <div className="">
                        <Field
                          id="annualIncome"
                          type="currency"
                          name="annualIncome"
                          label="Annual Income"
                          icon="$"
                          component={Input}
                        />
                        {showInfoBubble && (
                          <div className="mb-4">
                            <InfoBubble title="What to Include:" content={['Individual income', 'You can add additional income sources below']} />
                          </div>
                        )}
                        {showSpouseIncome && (
                          <OptIncomeInput
                            id="spouseIncome"
                            name="spouseIncome"
                            label="Spouse Income"
                            onClick={toggleShowSpouseIncome}
                            initialValue={initialValues.spouseIncome}
                          />
                        )}
                        {showPropertyIncome && (
                          <OptIncomeInput
                            id="householdIncome"
                            name="householdIncome"
                            label="Property Income"
                            onClick={toggleShowPropertyIncome}
                            initialValue={initialValues.householdIncome}
                          />
                        )}
                        {showOtherIncome && (
                          <OptIncomeInput
                            id="additionalIncome"
                            name="additionalIncome"
                            label="Other Income"
                            onClick={toggleShowOtherIncome}
                            initialValue={initialValues.additionalIncome}
                          />
                        )}
                        <div>
                          <hr />
                          {showAddIncomeMenu
                            ? (
                              <AdditionalIncomeMenu
                                showSpouseIncome={showSpouseIncome}
                                showPropertyIncome={showPropertyIncome}
                                showOtherIncome={showOtherIncome}
                                toggleShowAddIncomeMenu={toggleShowAddIncomeMenu}
                                toggleShowSpouseIncome={toggleShowSpouseIncome}
                                toggleShowPropertyIncome={toggleShowPropertyIncome}
                                toggleShowOtherIncome={toggleShowOtherIncome}
                              />
                            )
                            : (
                              <button type="button" className="btn py-0 px-3 align-items-center d-flex" onClick={toggleShowAddIncomeMenu}>
                                <Icon name="plus" className="text-silver h4 mb-0 mr-4" />
                                <span className="text-bombay">Additional Income</span>
                              </button>
                            )}
                          <hr />
                        </div>
                        <div className="pt-4 mb-5">
                          <p className="font-weight-bold text-small mb-4">This will not impact your credit score.</p>
                          <p className="text-small">
                            By clicking the button below, I confirm I have read and agree to
                            PayZen
                            {' '}
                            <a
                              target={`${hash}-privacy-notice`}
                              href={generatePath(funnelType, PRIVACY_NOTICE)}
                            >
                              Privacy Notice
                            </a>
                            ,
                            {' '}
                            <a
                              target={`${hash}-hipaa`}
                              href={hipaaPath}
                            >
                              Authorization for Use or Disclosure of Health Information
                            </a>
                            ,
                            {' '}
                            <a
                              target={`${hash}-credit-report-authorization`}
                              href={generatePath(funnelType, CREDIT_REPORT_AUTH)}
                            >
                              Credit Report Authorization
                            </a>
                            {' '}
                            and
                            {' '}
                            <a
                              target={`${hash}-e-signature-disclosure`}
                              href={esignatureDisclosureUrl + queryParams}
                            >
                              Consent to the use of Electronic Records and Signatures
                            </a>
                            .
                          </p>
                        </div>
                      </div>
                      <Button type="primary" className="btn-block mt-auto" isSubmit disabled={isSubmitting}>Accept & Continue</Button>
                    </Form>
                  </div>
                </div>
              )}
              banner={<Banner isAppFunnel={isAppFunnel} isFetching={isFetching} />}
            />
          );
        }}
      </Formik>
    </Layout>
  );
};

export default FinancialInfo;
