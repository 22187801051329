import {
  prop, path, head, compose, propOr,
} from 'ramda';
import PropTypes from 'prop-types';

const getErrorMessage = (error) => {
  const { data } = path(['response'])(error);

  return compose(
    prop('message'),
    head,
    propOr([{}], 'errors'),
  )(data) || error;
};

export default getErrorMessage;

getErrorMessage.propTypes = {
  error: PropTypes.string,
};

getErrorMessage.defaultProps = {
  error: 'There was a problem with your request.',
};
