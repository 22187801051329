import React from 'react';
import NumberFormat from 'react-number-format';

const CurrencyInput = ({
  id, placeholder, className, disabled, name, setField, value, onBlur, onFocus, ...rest
}) => (
  <NumberFormat
    id={id}
    name={name}
    allowNegative={false}
    thousandSeparator
    className={className}
    placeholder={placeholder}
    onBlur={onBlur}
    onFocus={onFocus}
    disabled={disabled}
    value={value}
    autoComplete="none"
    {...rest}
  />
);

export default CurrencyInput;
