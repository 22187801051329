import { connect } from 'react-redux';

import { getPatientInvite } from 'actions/patientInvite';

import PatientInvite from './PatientInvite';

const mapStateToProps = ({
  patientInvite,
}) => ({
  patientInvite,
});

const mapDispatchToProps = {
  getPatientInvite,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatientInvite);
