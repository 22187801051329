import { connect } from 'react-redux';

import { updateApplication } from 'actions/application';
import { makePatientPayment } from 'actions/paymentMade';
import { getOffer } from 'actions/offer';

import ConfirmPayment from './ConfirmPayment';

const mapStateToProps = ({
  user,
  paymentMethod,
  application: { offerId },
  bill: { bills },
  offer,
  provider: { paymentMethodTypes },
}) => ({
  user,
  paymentMethod,
  bills,
  offerId,
  offer,
  paymentMethodTypes,
});

const mapDispatchToProps = {
  updateApplication,
  getOffer,
  makePatientPayment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmPayment);
