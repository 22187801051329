import LogRocket from 'logrocket';
import { createReducer } from '@reduxjs/toolkit';
import {
  path, isNil, isEmpty, pathOr, propOr,
} from 'ramda';
import { setToken } from 'libs/authenticationService';

import { createPatient } from 'actions/patient';
import {
  getFromVault,
  getFromInvite,
  updateUser,
  getUser,
  verificationCode,
  setIsSmsLink,
} from 'actions/user';
import { updateApplicationPatient, clearStore, updateApplication } from 'actions/application';
import { getOfferByCode } from 'actions/offer';
import { cleanGuarantors } from 'utils/guarantors';
import { anonymizeEmail, anonymizeName } from 'utils/anonymize';
import { getFromMychart } from 'actions/epic';
import { selectProvider } from 'actions/provider';

const logRocketIdentify = (data, applicationId) => {
  const {
    userId,
    firstName,
    lastName,
    email,
  } = data;

  // Added applicationId as default
  LogRocket.identify(userId || applicationId, {
    name: `${anonymizeName(firstName)} ${anonymizeName(lastName)}`,
    email: anonymizeEmail(email),
  });
};

const initialState = {
  createdAt: null,
  deletedAt: null,
  email: null,
  firstName: null,
  isLoaded: false,
  lastName: null,
  middleName: null,
  phone: null,
  status: null,
  suspendedUntil: null,
  typeName: null,
  updatedAt: null,
  userId: null,
  twoFactorToken: null,
  isSmsLink: false,
  guarantors: [],
  guarantor: {},
  shouldHideIncome: false,
  intercom: '',
  userBackup: {
    email: null,
    firstName: null,
    lastName: null,
    phone: null,
    suspendedUntil: null,
    typeName: null,
    userId: null,
  },
};

const getActionReducer = (action) => ({
  [String(action.pending)]: (state) => ({
    ...state,
    status: 'pending',
    isLoaded: false,
  }),
  [String(action.fulfilled)]: (state, action) => {
    const token = path(['payload', 'auth', 'token'])(action);

    if (isNil(token) || isEmpty(token)) return state;

    setToken(token);

    const payloadGuarantors = pathOr([], ['payload', 'guarantors'])(action);
    const guarantors = cleanGuarantors(payloadGuarantors);

    const user = path(['payload', 'user'])(action);
    const applicationId = path(['payload', 'application', 'applicationId'])(action);
    logRocketIdentify(user, applicationId);

    return {
      ...state,
      status: 'fulfilled',
      isLoaded: true,
      ...action.payload.user,
      guarantors,
      shouldHideIncome: action.payload.shouldHideIncome || false,
      userBackup: {
        ...state.userBackup,
        ...action.payload.user,
      },
    };
  },
  [String(action.rejected)]: (state) => ({
    ...state,
    status: 'rejected',
    isLoaded: false,
  }),
});

export default createReducer(initialState, {
  ...getActionReducer(getFromVault),
  ...getActionReducer(getFromInvite),
  ...getActionReducer(getOfferByCode),
  ...getActionReducer(getFromMychart),
  ...getActionReducer(selectProvider),

  [String(updateApplicationPatient.pending)]: (state) => ({
    ...state,
    status: 'pending',
  }),
  [String(updateApplicationPatient.fulfilled)]: (state, action) => {
    const token = path(['payload', 'auth', 'token'])(action);
    const applicationId = path(['payload', 'application', 'applicationId'])(action);

    if (isNil(token) || isEmpty(token)) return state;

    setToken(token);

    const isGuarantorApplication = pathOr(false, ['payload', 'application', 'isGuarantorApplication'])(action);
    const guarantorData = pathOr({}, ['payload', 'guarantorData'])(action);

    const guarantor = {
      ...state.guarantor,
      ...guarantorData,
      ...pathOr({}, ['payload', 'guarantor'])(action),
    };

    const data = isGuarantorApplication
      ? {
        firstName: propOr(null, 'firstName')(guarantor),
        lastName: propOr(null, 'lastName')(guarantor),
        phone: propOr(null, 'phone')(guarantor),
        email: propOr(null, 'email')(guarantor),
      } : {};

    const userBackup = isGuarantorApplication ? {} : action.payload.user;

    const result = {
      ...state,
      status: 'fulfilled',
      isLoaded: true,
      ...action.payload.user,
      guarantor: data,
      userBackup: {
        ...state.userBackup,
        ...userBackup,
      },

    };

    logRocketIdentify(result, applicationId);

    return result;
  },
  [String(updateApplicationPatient.rejected)]: (state) => ({
    ...state,
    status: 'rejected',
  }),

  [String(updateApplication.pending)]: (state) => ({
    ...state,
  }),
  [String(updateApplication.fulfilled)]: (state, action) => {
    const isGuarantorApplication = pathOr(false, ['payload', 'isGuarantorApplication'])(action);
    const guarantorData = pathOr({}, ['payload', 'guarantorData'])(action);

    const guarantor = {
      ...state.guarantor,
      ...guarantorData,
      ...pathOr({}, ['payload', 'guarantor'])(action),
    };

    const data = isGuarantorApplication
      ? {
        firstName: propOr(null, 'firstName')(guarantor),
        lastName: propOr(null, 'lastName')(guarantor),
        phone: propOr(null, 'phone')(guarantor),
        email: propOr(null, 'email')(guarantor),
      } : {
        firstName: pathOr(null, ['userBackup', 'firstName'])(state),
        lastName: pathOr(null, ['userBackup', 'lastName'])(state),
        phone: pathOr(null, ['userBackup', 'phone'])(state),
        email: pathOr(null, ['userBackup', 'email'])(state),
      };

    return {
      ...state,
      ...data,
    };
  },
  [String(updateApplication.rejected)]: (state) => ({
    ...state,
  }),

  [String(updateUser.pending)]: (state) => ({
    ...state,
    status: 'pending',
  }),
  [String(updateUser.fulfilled)]: (state, action) => ({
    ...state,
    status: 'fulfilled',
    ...action.payload,
  }),
  [String(updateUser.rejected)]: (state) => ({
    ...state,
    status: 'rejected',
  }),

  [String(getUser.pending)]: (state) => ({
    ...state,
    status: 'pending',
    isLoaded: false,
  }),
  [String(getUser.fulfilled)]: (state, action) => ({
    ...state,
    status: 'fulfilled',
    isLoaded: true,
    ...action.payload,
  }),
  [String(getUser.rejected)]: (state) => ({
    ...state,
    status: 'rejected',
    isLoaded: false,
  }),

  [String(verificationCode.pending)]: (state) => ({
    ...state,
  }),
  [String(verificationCode.fulfilled)]: (state, action) => {
    const token = path(['payload', 'token'])(action);

    if (isNil(token) || isEmpty(token)) return state;

    setToken(token);

    return {
      ...state,
      ...action.payload,
    };
  },
  [String(verificationCode.rejected)]: (state) => ({
    ...state,
  }),

  [String(createPatient.pending)]: (state) => ({
    ...state,
    isLoaded: false,
  }),
  [String(createPatient.fulfilled)]: (state, action) => {
    const twoFactorToken = pathOr(null, ['payload', 'twoFactorToken'])(action);
    const user = pathOr(false, ['payload', 'user'])(action);

    if (user) {
      return {
        ...state,
        ...action.payload.user,
        isLoaded: true,
      };
    }

    return {
      ...state,
      twoFactorToken,
    };
  },
  [String(createPatient.rejected)]: (state) => ({
    ...state,
    isLoaded: false,
  }),

  [String(clearStore)]: () => ({
    ...initialState,
  }),

  [String(setIsSmsLink)]: (state, action) => ({
    ...state,
    isSmsLink: propOr(false, 'payload')(action),
  }),
});
