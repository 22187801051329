import React from 'react';

const SingleBillRow = ({ name, value, 'data-cy': datCy }) => (
  <div className="d-flex justify-content-between align-items-center mb-2 text-capitalize">
    <span className="text-storm-gray text-smaller">
      {`${name}:`}
    </span>
    <span data-private className="text-small text-tuna" data-cy={datCy} data-testid={datCy}>
      {value}
    </span>
  </div>
);

export default SingleBillRow;
