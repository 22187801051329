import React, { useState, useEffect } from 'react';
import {
  filter, isEmpty, path, pathOr,
} from 'ramda';

import {
  billInfo,
  invoiceToPay,
  selectAccount,
} from 'libs/views';
import funnelViews from 'libs/funnelViews';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import Button from 'components/Button';
import Link from 'components/Link';

import isMinorPatient from 'utils/isMinorPatient';
import { filterGuarantors } from 'utils/guarantors';
import getLower from 'utils/getLower';
import generatePath from 'utils/generatePath';
import { redirectToApp } from 'utils/redirectTo';

import { SUPPORT_PHONE_NUMBER } from 'constants/support';
import LogRocket from 'logrocket';
import { anonymizeName } from 'utils/anonymize';

const SelectUser = ({
  funnelType,
  user,
  userBackup,
  guarantors,
  history,
  updateApplication,
  patientId,
  applicationId,
  birthDate,
  bills,
  isEpicApp,
  showOrganizationView,
}) => {
  const [isFetching, setIsFetching] = useState(true);
  const checkBills = pathOr(false, ['location', 'state', 'checkBills'])(history);

  const logRocketIdentify = (data) => {
    const {
      firstName,
      lastName,
    } = data;

    LogRocket.identify(user.userId, {
      name: `${anonymizeName(firstName)} ${anonymizeName(lastName)}`,
    });
  };

  const updateApplicationSuccess = () => {
    setIsFetching(false);
    return history.push(generatePath(funnelType, invoiceToPay), { checkBills });
  };

  const updateApplicationFailed = () => {
    setIsFetching(false);
  };

  const onSelectPatient = () => {
    setIsFetching(true);

    return updateApplication({
      patientId,
      applicationId,
      isGuarantorApplication: false,
    }).then(updateApplicationSuccess)
      .catch(updateApplicationFailed);
  };

  const onSelectGuarantor = (guarantor) => {
    setIsFetching(true);

    logRocketIdentify(guarantor);

    return updateApplication({
      patientId,
      applicationId,
      isGuarantorApplication: true,
      guarantor,
    }).then(updateApplicationSuccess)
      .catch(updateApplicationFailed);
  };

  const autoSelectGuarantor = () => {
    setIsFetching(true);
    const guarantor = path(['0'])(guarantors);

    logRocketIdentify(guarantor);

    return updateApplication({
      patientId,
      applicationId,
      isGuarantorApplication: true,
      guarantor,
    }).then(updateApplicationSuccess)
      .catch(updateApplicationFailed);
  };

  useEffect(() => {
    const nonAcceptedBills = filter((item) => item.status !== 'BILL_ACCEPTED')(bills);

    if (isEmpty(nonAcceptedBills)) {
      redirectToApp({ history, funnelType, currentView: funnelViews.planExists });
    } else if (isMinorPatient(birthDate)) {
      autoSelectGuarantor();
    } else {
      setIsFetching(false);
    }
  }, [birthDate]);

  const goBackRoute = showOrganizationView ? selectAccount : billInfo;

  return (
    <Layout
      backLink={generatePath(funnelType, goBackRoute)}
      hideBackLink={isEpicApp && !showOrganizationView}
    >
      <Card
        className="select-user mt-2"
        progressBarValue={0}
        content={(
          <div className="funnel-card__wrapper w-100 pb-7">
            {isFetching && (
              <div className="connect-bank__loader text-center">
                <Loading />
              </div>
            )}
            <div className="funnel-card__form text-center mb-5">
              <h1 className="text-larger text-sm-xlarger font-weight-normal" data-cy="offers-title">
                Who would like to enroll?
              </h1>
              <p className="mb-0">
                Our records indicate that the patient may be a dependent. Please select your name
                below to continue.
              </p>
            </div>
            <div className="funnel-card__form mb-9">
              <div className="select-user__item d-flex align-items-center justify-content-between">
                <div>
                  <p className="text-dodger-blue text-xlarge font-weight-bold mb-0 text-capitalize" data-cy="guarantor-patientname">{getLower(userBackup.firstName)}</p>
                  <p className="text-jumbo font-weight-light mb-0 text-capitalize" data-cy="guarantor-patientlabel">Patient</p>
                </div>
                <Button
                  type="primary"
                  className="select-user__btn"
                  onClick={onSelectPatient}
                  data-cy="guarantor-selectpatient"
                  eventName="patient"
                >
                  Select
                  <Icon name="caret-right" className="text-base" />
                </Button>
              </div>
              {filterGuarantors(userBackup, guarantors).map((item) => (
                <div key={`${item.firstName} ${item.lastName}`} className="select-user__item d-flex align-items-center justify-content-between">
                  <div>
                    <p className="text-dodger-blue text-xlarge font-weight-bold mb-0 text-capitalize" data-cy="guarantor-guarantorname">{getLower(item.firstName)}</p>
                    <p className="text-jumbo font-weight-light mb-0 text-capitalize" data-cy="guarantor-guarantorlabel">Guarantor</p>
                  </div>
                  <Button
                    type="primary"
                    className="select-user__btn"
                    onClick={() => onSelectGuarantor(item)}
                    data-cy="guarantor-selectguarantor"
                    eventName="guarantor"
                  >
                    Select
                    <Icon name="caret-right" className="text-base" />
                  </Button>
                </div>
              ))}
            </div>
            <div className="text-center d-sm-none">
              <p className="text-smaller mb-0">
                Does this seem incorrect?
                {' '}
                <Link
                  href={`tel:${SUPPORT_PHONE_NUMBER}`}
                  eventName="call"
                >
                  Click here
                </Link>
                {' '}
                to call us.
              </p>
            </div>
          </div>
        )}
      />
    </Layout>
  );
};

export default SelectUser;
