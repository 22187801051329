import React from 'react';

import ErrorTULayout from 'components/ErrorTULayout';

const CreditFreeze = () => (
  <ErrorTULayout>
    <p className="mb-0">
      It seems like your credit report is frozen. Please
      <br />
      unfreeze your credit and try again.
    </p>
  </ErrorTULayout>
);

export default CreditFreeze;
