import React from 'react';
import NumberFormat from 'react-number-format';

const ManualDateInput = ({
  id,
  label,
  className,
  disabled,
  name,
  setField,
  value,
  onBlur,
  'data-testid': dataTestId = '',
  ...rest
}) => (
  <>
    <NumberFormat
      id={id}
      name={name}
      format="##/##/####"
      placeholder="MM/DD/YYYY"
      mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
      className={className}
      onValueChange={(values) => {
        const { formattedValue } = values;
        setField(name, formattedValue);
      }}
      disabled={disabled}
      value={value}
      onBlur={onBlur}
      data-testid={dataTestId}
      {...rest}
    />
    {label && <label htmlFor={id} className="form-control-placeholder">{label}</label>}
  </>
);

export default ManualDateInput;
