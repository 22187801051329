import React, { useState, useEffect } from 'react';
import {
  path, omit, compose, filter, map,
  prop, propEq, sum, pluck, lt, includes,
  equals, head, propOr, isEmpty, values,
} from 'ramda';
import {
  Formik, Form, Field,
} from 'formik';
import { useHistory, useLocation } from 'react-router-dom';

import Button from 'components/Button';
import { Input } from 'components/Fields';
import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Loading from 'components/Loading';

import { manualAddressForm } from 'utils/validationSchemas';
import generatePath from 'utils/generatePath';
import getErrorMessage from 'utils/getErrorMessage';

import usStates from 'libs/usStates';
import {
  paymentPlan, error as errorPage, confirmSSN, reviewInfo, errorIdentity, errorEnroll,
} from 'libs/views';
import { getTUErrorPage } from 'utils/redirectTo';

const ManualAddress = ({
  updateApplicationPatient,
  bills,
  getOffer,
  generateOffers,
  getGeneratedOffers,
  offerId,
  shouldHideIncome,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [isFetching, setIsFetching] = useState(false);
  const [localAttempt, setLocalAttempt] = useState(0);

  const includedInOffer = propEq('includedInOffer', true);
  const statusPending = propEq('status', 'BILL_PENDING');
  const validOffers = compose(filter(includedInOffer), filter(statusPending));

  const totalBalance = compose(sum, pluck('totalAmount'), validOffers)(bills);
  const selectedBills = compose(pluck('billId'), validOffers)(bills);

  const getOfferSuccess = () => setIsFetching(false);

  const getOfferFailure = () => {
    setIsFetching(false);
    return history.replace(generatePath('app', errorPage));
  };

  useEffect(() => {
    if (offerId) {
      setIsFetching(true);

      getOffer(offerId)
        .then(getOfferSuccess)
        .catch(getOfferFailure);
    }
  }, [bills]);

  const onFailure = () => {
    setIsFetching(false);
    return history.replace(generatePath('app', errorIdentity));
  };

  const onFailureCreateOffer = (error) => {
    const attempt = path(['state', 'attempt'])(location);
    const errorMessage = getErrorMessage(error);
    setIsFetching(false);

    if (includes('DTI', errorMessage)) return history.replace(generatePath('app', errorEnroll));
    if (includes('VerifySSN', errorMessage)) return history.replace(generatePath('app', confirmSSN));
    if (lt(attempt, 1) && lt(localAttempt, 1)) {
      return setLocalAttempt(localAttempt + 1);
    }

    const TUErrorPage = getTUErrorPage(errorMessage);

    if (TUErrorPage) return history.push(generatePath('app', TUErrorPage));

    return history.replace(generatePath('app', errorEnroll));
  };

  const onSuccess = () => {
    setIsFetching(false);
    history.replace(generatePath('app', paymentPlan));
  };

  const getShouldSucceed = (response) => {
    if (shouldHideIncome) return false;

    const { state } = location;
    const totalAmountOffer = compose(head, pluck('totalAmount'), propOr([], 'value'))(response);
    const totalBalanceChanged = equals(totalAmountOffer, totalBalance);

    return totalBalanceChanged && state.isAnnualIncomeEqual;
  };

  const onSuccessGetGeneratedOffers = (response) => {
    const shouldSucceed = getShouldSucceed(response);

    if (shouldSucceed) return onSuccess();

    return generateOffers({
      totalBalance,
      bills: selectedBills,
      firstBillingDate: new Date(),
    })
      .then(onSuccess)
      .catch(onFailureCreateOffer);
  };

  const onGenerateOffers = () => generateOffers({
    totalBalance,
    bills: selectedBills,
    firstBillingDate: new Date(),
  })
    .then(onSuccess)
    .catch(onFailureCreateOffer);

  const onFailureGetGeneratedOffers = (error) => {
    const errorMessage = getErrorMessage(error);

    if (equals(errorMessage, 'No GDS data available')) {
      return onGenerateOffers();
    }

    setIsFetching(false);

    return onFailureCreateOffer;
  };

  const onGetGeneratedOffers = () => getGeneratedOffers()
    .then(onSuccessGetGeneratedOffers)
    .catch(onFailureGetGeneratedOffers);

  const handleSubmit = (formData) => {
    const { state } = location;
    setIsFetching(true);

    const body = {
      ...omit(['attempt', 'fullAddress', 'isAnnualIncomeEqual'])(state),
      ...formData,
      isManualAddress: true,
    };

    return updateApplicationPatient(body)
      .then(onGetGeneratedOffers)
      .catch(onFailure);
  };

  const initValues = {
    address: '',
    city: '',
    state: '',
    postalCode: '',
  };

  return (
    <Layout
      backLink={generatePath('app', reviewInfo)}
    >
      <Card
        className="mt-2"
        progressBarValue={0.7352941176}
        content={(
          <div className="funnel-card__wrapper">
            {isFetching && (
              <div className="connect-bank__loader text-center">
                <Loading />
              </div>
            )}
            <div className="funnel-card__form">
              <div className="text-center">
                <h1
                  className="h4 font-weight-normal text-center mb-5"
                  data-cy="address-title"
                  data-testid="manual-address-title"
                >
                  Confirm your address
                </h1>
                <p
                  className="text-center mb-5"
                  data-cy="address-description"
                  data-testid="manual-address-description"
                >
                  We need to verify your address to create a personalized payment plan for you
                </p>
              </div>
              <Formik
                initialValues={initValues}
                validationSchema={manualAddressForm}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({
                  isSubmitting, values: formData, errors,
                }) => {
                  const isDataComplete = !compose(isEmpty, filter(isEmpty), values)(formData);
                  const WithErrors = !isEmpty(errors);
                  const isDisabled = isSubmitting || isDataComplete || WithErrors;

                  return (
                    <Form>
                      <div className="mb-5">
                        <div className="row smaller-gutters">
                          <div className="col-12">
                            <Field
                              id="address"
                              type="text"
                              name="address"
                              label="Address"
                              component={Input}
                              data-cy="address-street"
                              data-testid="manual-address-street"
                            />
                          </div>
                          <div className="col-sm-6">
                            <Field
                              id="city"
                              type="text"
                              name="city"
                              label="City"
                              component={Input}
                              data-cy="address-city"
                              data-testid="manual-address-city"
                            />
                          </div>
                          <div className="col-sm-6">
                            <Field
                              id="state"
                              type="select"
                              name="state"
                              label="State"
                              component={Input}
                              options={map(prop('name'))(usStates)}
                              data-cy="address-state"
                              data-testid="manual-address-state"
                            />
                          </div>
                          <div className="col-sm-12">
                            <Field
                              id="postalCode"
                              type="text"
                              name="postalCode"
                              label="Zip Code"
                              component={Input}
                              data-cy="address-zipcode"
                              data-testid="manual-address-zipcode"
                            />
                          </div>
                        </div>
                      </div>
                      <Button
                        type="primary"
                        className="btn-block"
                        isSubmit
                        disabled={isDisabled}
                        data-cy="address-continue"
                        data-testid="manual-address-continue"
                        eventName="main-cta"
                      >
                        Continue
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
      />
    </Layout>
  );
};

export default ManualAddress;
