import React from 'react';
import NumberFormat from 'react-number-format';
import { convertToDollars } from 'utils/currency';

const Currency = ({ amount, ...props }) => (
  <NumberFormat
    value={convertToDollars(amount)}
    decimalScale={2}
    fixedDecimalScale
    displayType="text"
    thousandSeparator
    prefix="$"
    {...props}
  />
);

export default Currency;
