import React, { useEffect } from 'react';
import { path } from 'ramda';
import queryString from 'qs';

import RedirectToFunnel from 'components/RedirectToFunnel';
import Header from 'components/Homepage/Header';
import Banner from 'components/Homepage/Banner';
import Logos from 'components/Homepage/Logos';
import LetUsHelp from 'components/Homepage/LetUsHelp';
import HowWeDoIt from 'components/Homepage/HowWeDoIt';
import WhoWeAre from 'components/Homepage/WhoWeAre';
import Difference from 'components/Homepage/Difference';
import FrequentlyAskedQuestions from 'components/Homepage/FrequentlyAskedQuestions';
import Footer from 'components/Homepage/Footer';

import { logout } from 'libs/authenticationService';

const Homepage = ({
  getPartnerInfo, match, location, clearStore,
}) => {
  const paramId = path(['params', 'id'])(match);
  const queryParam = queryString.parse(location.search, { ignoreQueryPrefix: true });
  const token = path(['token'])(queryParam);
  const disablePartnerFunnel = true;

  useEffect(() => {
    if (!disablePartnerFunnel) {
      getPartnerInfo({ params: { q: paramId || queryParam.ref || '' } });
    }
  }, []);

  useEffect(() => {
    if (!token) {
      logout();
      clearStore();
    }
  }, []);

  if (disablePartnerFunnel) return null;

  return (
    <div className="homepage bg-white overflow-hidden" id="top">
      <Header />
      <div className="homepage-banner--overall">
        <Banner />
        <Logos />
      </div>
      <LetUsHelp />
      <HowWeDoIt />
      <WhoWeAre />
      <Difference />
      <FrequentlyAskedQuestions />
      <Footer />
    </div>
  );
};

export default RedirectToFunnel(Homepage);
