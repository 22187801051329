import { connect } from 'react-redux';

import { createManualPaymentMethod } from 'actions/paymentMethod';
import { updateApplication, sendSlackMessage } from 'actions/application';
import { confirmPatientOffer } from 'actions/offer';
import { bankAccountValidate } from 'actions/bankAccount';

import ConnectBankSimple from './ConnectBankSimple';

const mapStateToProps = ({
  application: {
    applicationId, currentView, offerId, status,
  },
  patient: { patientId },
  user,
}) => ({
  applicationId,
  currentView,
  patientId,
  offerId,
  applicationStatus: status,
  user,
});

const mapDispatchToProps = {
  createManualPaymentMethod,
  updateApplication,
  confirmPatientOffer,
  sendSlackMessage,
  bankAccountValidate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectBankSimple);
