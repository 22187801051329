import React, { useEffect } from 'react';
import {
  isEmpty, path, split, compose, last,
} from 'ramda';
import queryString from 'qs';

import Header from 'components/Header';
import Loading from 'components/Loading';

import generatePath from 'utils/generatePath';

import { noMatch } from 'libs/views';

import useEventListener from './useEventListener';
import smoothScrolling from './smoothScrolling';

const Document = ({
  apiDocId,
  getDocument,
  documentsHTML,
  history,
  funnelType,
  location,
}) => {
  const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true });
  const hasQueryParams = !isEmpty(queryParams);
  const data = hasQueryParams ? { apiDocId, ...queryParams } : { apiDocId };
  const onSuccess = () => hasQueryParams && history.replace(location.pathname);
  const onFailure = () => history.push(generatePath(funnelType, noMatch));

  useEffect(() => {
    if (!documentsHTML[apiDocId]) {
      getDocument(data)
        .then(onSuccess)
        .catch(onFailure);
    }
  }, [apiDocId]);

  useEventListener('click', (e) => {
    const hash = path(['target', 'hash'])(e);
    const id = hash && compose(last, split('#'))(hash);

    if (id) {
      e.preventDefault();
      smoothScrolling.scrollTo(id);
    }
  });

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="d-flex align-items-center flex-fill">
        {
          documentsHTML[apiDocId]
            ? (
              <div className="document">
                <div dangerouslySetInnerHTML={{ __html: documentsHTML[apiDocId] }} />
              </div>
            ) : (
              <div className="document__loader">
                <Loading />
              </div>
            )
        }
      </div>
    </div>
  );
};

export default Document;
