import React from 'react';

import Header from 'components/Header';
import Card from 'components/Funnel/Card';

const ErrorLayout = ({ children, isEpicApp }) => (
  <div className="d-flex flex-column min-vh-100">
    <Header />
    <div className={`wrapper ${isEpicApp ? 'funnel-wrapper-epic' : 'funnel-wrapper'}`}>
      <Card
        isErrorPage
        className="mt-2 mt-sm-0"
        content={(
          <div className="error-page text-center mx-auto text-emperor font-weight-semibold d-flex align-items-center">
            <div className="flex-fill">
              {children}
            </div>
          </div>
        )}
      />
    </div>
  </div>
);

export default ErrorLayout;
