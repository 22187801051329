import React from 'react';

import { BillingMessage, DefaultMessage } from 'components/QuestionsCallMessage';

const CardFooter = ({
  showBillingMessage,
  providerName,
  billingPhoneNumber,
}) => (
  <div className="text-smaller text-center px-5" data-testid="card-footer">
    <p className="mb-1" data-testid="card-footer-questions">
      {showBillingMessage
        ? <BillingMessage providerName={providerName} billingPhoneNumber={billingPhoneNumber} />
        : <DefaultMessage />}
    </p>
  </div>
);

export default CardFooter;
