import React from 'react';
import PropTypes from 'prop-types';

const Loading = ({ message }) => (
  <div className="loading" data-cy="loading" data-testid="loading">
    <div className="mb-5">
      <span className="loading__bullet" />
      <span className="loading__bullet" />
      <span className="loading__bullet" />
      <span className="loading__bullet" />
    </div>
    {message && <p className="mb-0">{message}</p>}
  </div>
);

Loading.propTypes = {
  message: PropTypes.string,
};

Loading.defaultProps = {
  message: 'Please wait',
};

export default Loading;
