import React, { Children, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import {
  path, add, prop, propOr,
} from 'ramda';
import { getIn } from 'formik';

import { ErrorMessage } from 'components/Fields';
import { reqGetAddressSuggestions } from 'api/user';

const getSuggestionValue = (suggestion) => suggestion.place;

const renderSuggestion = (suggestion, { query }) => {
  const suggestionText = suggestion.place;
  const matches = AutosuggestHighlightMatch(suggestionText, query);
  const parts = AutosuggestHighlightParse(suggestionText, matches);

  return (
    Children.toArray(parts.map((part) => {
      if (prop('highlight')(part)) return <span className="font-weight-bold">{prop('text')(part)}</span>;
      return prop('text')(part);
    }))
  );
};

const requestOptions = (value) => ({ q: value });
const request = reqGetAddressSuggestions;

const AutoCompleteAddress = ({
  label,
  className,
  id,
  type,
  field,
  form,
  isRequired = false,
  errorInside = false,
  onFocus = () => { },
  onBlur = () => { },
  ...rest
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [currentRequest, setCurrentRequest] = useState(0);
  const setFieldValue = prop('setFieldValue')(form);
  const handleBlur = prop('handleBlur')(form);
  const name = prop('name')(field);
  const errors = propOr({}, 'errors')(form);
  const touched = propOr({}, 'touched')(form);
  const error = getIn(errors, name);
  const hasError = getIn(touched, name) && error;
  const isValidClass = !error && field.value ? 'is-valid' : '';
  const invalidClass = hasError ? 'is-invalid' : '';
  const errorInsideClass = errorInside ? 'is-invalid-inside' : '';

  const onChange = (event, { newValue }) => setFieldValue(name, newValue);

  const onSuggestionsClearRequested = () => setSuggestions([]);

  const onSuggestionSelected = (event, { method, suggestionValue }) => {
    if (method === 'enter') {
      event.preventDefault();
    }
    if (suggestionValue) {
      setFieldValue(name, suggestionValue);
    }
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    const promiseRequest = add(1, currentRequest);

    setCurrentRequest(promiseRequest);

    const payload = requestOptions(value);
    const requestedSuggestions = await request(payload);
    const requestedSuggestionsValue = path(['data'])(requestedSuggestions);

    // Check if the request just completed is not the last one
    if (promiseRequest < currentRequest) {
      return;
    }

    setSuggestions(requestedSuggestionsValue);
  };

  const inputProps = {
    placeholder: label,
    className: `form-control ${className} ${invalidClass} ${isValidClass} ${errorInsideClass}`,
    id,
    type,
    autoComplete: 'off',
    ...field,
    ...rest,
    onChange,
    onFocus,
    onBlur: (e) => {
      onBlur();
      return handleBlur(e);
    },
  };

  const renderInputComponent = (props) => (
    <>
      <div className="form-group mb-2 mb-sm-4 position-relative">
        <input data-private {...props} />
        {label && (
          <label htmlFor={id} className="form-control-placeholder">
            {label}
            {isRequired && <span className="ml-1 text-burnt-sienna">*</span>}
          </label>
        )}
        {hasError && errorInside && <ErrorMessage error={error} errorInside />}
      </div>
      {hasError && !errorInside && <ErrorMessage error={error} />}
    </>
  );

  const theme = {
    container: 'react-autosuggest__container',
    containerOpen: 'react-autosuggest__container--open',
    input: 'react-autosuggest__input',
    inputOpen: 'react-autosuggest__input--open',
    inputFocused: 'react-autosuggest__input--focused',
    suggestionsContainer: 'react-autosuggest__suggestions-container',
    suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
    suggestionsList: 'react-autosuggest__suggestions-list',
    suggestion: 'react-autosuggest__suggestion suggestion-content text-tuna',
    suggestionFirst: 'react-autosuggest__suggestion--first',
    suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
    sectionContainer: 'react-autosuggest__section-container',
    sectionContainerFirst: 'react-autosuggest__section-container--first',
    sectionTitle: 'react-autosuggest__section-title',
  };

  return (
    <>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        shouldRenderSuggestions={(value) => value && value.trim().length > 2}
        inputProps={inputProps}
        theme={theme}
        renderInputComponent={renderInputComponent}
      />
    </>
  );
};

export default AutoCompleteAddress;
