import { createReducer } from '@reduxjs/toolkit';

import { getDocument, getDocumentSigned } from 'actions/documents';
import { clearStore } from 'actions/application';

const initialState = {
  isLoaded: false,
  status: null,
  documentsHTML: {},
  documentSigned: null,
  documentSignedIsLoaded: false,
};

export default createReducer(initialState, {
  [String(getDocument.pending)]: (state) => ({
    ...state,
    status: 'pending',
    isLoaded: false,
  }),
  [String(getDocument.fulfilled)]: (state, action) => {
    const documentsHTML = {
      ...state.documentsHTML,
      [action.payload.apiDocId]: action.payload.html,
    };
    return {
      ...state,
      status: 'fulfilled',
      isLoaded: true,
      documentsHTML,
    };
  },
  [String(getDocument.rejected)]: (state) => ({
    ...state,
    status: 'rejected',
    isLoaded: false,
  }),

  [String(getDocumentSigned.pending)]: (state) => ({
    ...state,
    documentSignedIsLoaded: false,
  }),
  [String(getDocumentSigned.fulfilled)]: (state, action) => ({
    ...state,
    documentSignedIsLoaded: true,
    documentSigned: action.payload,
  }),
  [String(getDocumentSigned.rejected)]: (state) => ({
    ...state,
    documentSignedIsLoaded: false,
  }),

  [String(clearStore)]: () => ({
    ...initialState,
  }),
});
