import React from 'react';

import Button from 'components/Button';
import Icon from 'components/Icon';

const AdditionalIncomeMenu = ({
  showSpouseIncome,
  showPropertyIncome,
  showOtherIncome,
  toggleShowAddIncomeMenu,
  toggleShowSpouseIncome,
  toggleShowPropertyIncome,
  toggleShowOtherIncome,
}) => (
  <div className="text-center">
    <span className="d-inline-block mb-4">Select Additional Income Type</span>
    <div className="btns-options">
      <div className="btns-options__item flex-fill">
        <Button type={`${showSpouseIncome ? 'primary' : 'outline-primary-alice-blue'}`} disabled={showSpouseIncome} className="btn-square py-3 text-small btn-block" onClick={() => { toggleShowSpouseIncome(); toggleShowAddIncomeMenu(); }}>Spouse Income</Button>
      </div>
      <div className="btns-options__item flex-fill">
        <Button type={`${showPropertyIncome ? 'primary' : 'outline-primary-alice-blue'}`} disabled={showPropertyIncome} className="btn-square py-3 text-small btn-block" onClick={() => { toggleShowPropertyIncome(); toggleShowAddIncomeMenu(); }}>Property Income</Button>
      </div>
      <div className="btns-options__item">
        <Button type={`${showOtherIncome ? 'primary' : 'outline-primary-alice-blue'}`} disabled={showOtherIncome} className="btn-square py-3 px-md-4 text-small" onClick={() => { toggleShowOtherIncome(); toggleShowAddIncomeMenu(); }}>Other</Button>
      </div>
      <Icon name="trash" className="text-larger text-spun-pearl btns-options__close clickable" onClick={toggleShowAddIncomeMenu} />
    </div>
  </div>
);

export default AdditionalIncomeMenu;
