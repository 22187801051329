import React, { useState } from 'react';
import {
  path,
  prop,
  mapObjIndexed,
  trim,
  equals,
  pathOr,
  compose,
  join,
  values,
  omit,
  head,
} from 'ramda';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import getErrorMessage from 'utils/getErrorMessage';
import generatePath from 'utils/generatePath';
import getSubdomain from 'utils/getSubdomain';
import { guarantorValidation } from 'utils/guarantors';

import {
  mrn as mrnPage,
  ssn as ssnPage,
  letsGetInTouch,
  errorMessage as errorMessageRoute,
} from 'libs/views';

import BillInfoLayout from 'components/BillInfoLayout';

const formatDate = (date) => format(new Date(date), 'MM-dd-yyyy');

const BillInfo = ({
  getFromVault,
  funnelType,
  sendSlackMessage,
  updateLoginAttempts,
  failedLoginAttempts,
}) => {
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(false);
  const providerName = getSubdomain(window);

  const submitFailure = (obj) => (error) => {
    setIsFetching(false);
    const statusCode = path(['response', 'status'])(error);
    const responseErrorMessage = getErrorMessage(error);
    const patientInfo = compose(join(', '), values)(obj);

    sendSlackMessage({
      type: 'vault.failure',
      title: 'Failure - Get Info From Vault',
      provider: providerName,
      lastName: obj.lastName,
      dateOfBirth: obj.dateOfBirth,
      patientInfo,
      errorMessage: responseErrorMessage,
    });

    if (failedLoginAttempts >= 2) {
      const errorState = {
        patientInfo: obj,
      };

      return history.push(generatePath(funnelType, letsGetInTouch), errorState);
    }

    if (equals(statusCode, 406)) {
      return history.push(generatePath(funnelType, errorMessageRoute), {
        errorMessage: responseErrorMessage,
      });
    }

    if (equals(statusCode, 409)) {
      const parameter = compose(
        prop('parameter'),
        head,
        path(['response', 'data', 'errors']),
      )(error);

      if (equals(parameter, 'payzenPatientSSN')) {
        return history.push(generatePath(funnelType, ssnPage), obj);
      }

      if (equals(parameter, 'payzenPatientMRN')) {
        return history.push(generatePath(funnelType, mrnPage), obj);
      }
    }

    return updateLoginAttempts();
  };

  const submitSuccess = (response) => {
    const user = pathOr([], ['action', 'payload', 'user'])(response);
    setIsFetching(false);
    const patientInfo = compose(join(', '), values, omit(['middleName', 'suspendedUntil', 'typeName']))(user);

    sendSlackMessage({
      type: 'vault.success',
      title: 'Success - Get Info From Vault',
      provider: providerName,
      name: `${user.firstName} ${user.lastName}`,
      phone: user.phone,
      patientInfo,
    });

    return guarantorValidation(response, funnelType, history);
  };

  const handleSubmit = (data, actions) => {
    setIsFetching(true);

    const { dateOfBirth, ...rest } = data;

    const obj = {
      ...mapObjIndexed(trim)(rest),
      dateOfBirth: formatDate(data.dateOfBirth),
    };

    getFromVault(obj)
      .then(submitSuccess)
      .catch(submitFailure(obj))
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <BillInfoLayout isFetching={isFetching} handleSubmit={handleSubmit}>
      <div className="funnel-card__form text-center mb-4">
        <h1 className="text-larger text-sm-xlarger font-weight-normal pb-1">
          Hmm...
          <br />
          We couldn&apos;t find your bill
        </h1>
        <div className="text-left text-small text-stiletto p-4 bg-linen">
          <p className="mb-0">Here are some tips:</p>
          <ul className="pl-4 mb-0">
            <li>
              <p className="mb-0">
                Make sure you enter the
                {' '}
                <b>patient&apos;s</b>
                {' '}
                last name and date of birth
                if you are paying for others
              </p>
            </li>
            <li>
              <p className="mb-0">
                If applicable, try &quot;Smith Jr.&quot;, &quot;Smith III&quot;,
                or do not include a hyphen
              </p>
            </li>
            <li>
              <p className="mb-0">
                If you&apos;re still having trouble,
                double check the status of your bill with your provider
              </p>
            </li>
          </ul>
        </div>
      </div>
    </BillInfoLayout>
  );
};

export default BillInfo;
