import React from 'react';

import Link from 'components/Link';

import { SUPPORT_PHONE_NUMBER } from 'constants/support';

export const DefaultMessage = () => (
  <span data-testid="default-questions-call">
    Questions? Call
    {' '}
    <Link
      href={`tel:${SUPPORT_PHONE_NUMBER}`}
      eventName="questions-call"
    >
      {SUPPORT_PHONE_NUMBER}
    </Link>
  </span>
);

export const BillingMessage = ({
  providerName,
  billingPhoneNumber,
}) => {
  if (providerName && billingPhoneNumber) {
    return (
      <span data-testid="provider-name-phone-questions-call">
        For billing questions, call
        {' '}
        {providerName}
        {' '}
        at
        {' '}
        <Link
          href={`tel:${billingPhoneNumber}`}
          eventName="questions-call-provider-phone"
          className="text-nowrap"
        >
          {billingPhoneNumber}
        </Link>
      </span>
    );
  }
  if (providerName) {
    return (
      <span data-testid="provider-name-questions-call">
        For billing questions, please call
        {' '}
        {providerName}
      </span>
    );
  }

  return <DefaultMessage />;
};
