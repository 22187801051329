import { connect } from 'react-redux';

import {
  getFromVault,
  getFromInvite,
  setIsSmsLink,
} from 'actions/user';
import {
  sendSlackMessage,
  clearStore,
  updateLoginAttempts,
} from 'actions/application';

import BillInfo from './BillInfo';

const mapStateToProps = ({
  provider: { payInFullUri, providerId },
  application: { failedLoginAttempts },
}) => ({
  providerId,
  payInFullUri,
  failedLoginAttempts,
});

const mapDispatchToProps = {
  getFromVault,
  getFromInvite,
  setIsSmsLink,
  sendSlackMessage,
  clearStore,
  updateLoginAttempts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BillInfo);
