import React, { useEffect } from 'react';
import {
  equals, divide, compose, prop, head, filter, pathOr, isNil,
} from 'ramda';
import { Formik, Form, Field } from 'formik';
import queryString from 'qs';

import generatePath from 'utils/generatePath';
import { confirmPaymentForm } from 'utils/validationSchemas';

import funnelViews from 'libs/funnelViews';
import {
  error as errorPage, household, allSet, PAYMENT_PLAN_AGREEMENT,
} from 'libs/views';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Button from 'components/Button';
import Loading from 'components/Loading';
import { Input } from 'components/Fields';

import hands from 'assets/images/hands.svg';
import handsHeart from 'assets/images/hands-heart.svg';
import Currency from 'components/Currency';

const hash = Math.random().toString(36).slice(2).substring(5);

const PaymentPlan = ({
  history,
  offer,
  updateOffer,
  applicationId,
  currentView,
  patientId,
  updateApplication,
  getOffer,
  bill: { bills },
  user,
  location,
  funnelType,
  sendToCrm,
  spinner,
  setSpinner,
}) => {
  const resumeApp = pathOr(false, ['state', 'resumeApp'])(location);
  const offerId = prop('offerId')(offer);
  const bill = compose(head, filter(prop('providerId')))(bills);
  const providerId = prop('providerId')(bill);
  const providerName = prop('providerName')(bill);
  const onSubmitFailure = () => history.push(generatePath(funnelType, errorPage));

  const onSubmitSuccess = () => sendToCrm({ offerId })
    .then(() => history.push(generatePath(funnelType, allSet)))
    .catch(onSubmitFailure);

  const onSubmit = () => {
    setSpinner(true);

    if (isNil(offerId)) {
      return history.go(0);
    }

    return updateOffer({ id: offerId, data: { status: 'OFFER_APPROVED' } })
      .then(onSubmitSuccess)
      .catch(onSubmitFailure);
  };

  const {
    totalPayment, totalAmount, newAmount,
  } = offer;

  const amountToPay = newAmount || totalAmount;

  const monthlyPayment = totalPayment && divide(amountToPay, totalPayment);

  useEffect(() => {
    if (equals(offer.getOfferStatus, 'pending')) {
      return setSpinner(true);
    }
    return setSpinner(false);
  }, [offer.getOfferStatus]);

  useEffect(() => {
    if (user.isLoaded && !offer.isLoaded) {
      const offerId = compose(prop('offerId'), head, filter(prop('offerId')))(bills);
      setSpinner(true);
      getOffer(offerId)
        .then(() => setSpinner(false))
        .catch(() => setSpinner(false));
    }
  }, [offer.isLoaded]);

  useEffect(() => {
    if (currentView !== funnelViews.offer && applicationId) {
      updateApplication({
        patientId,
        applicationId,
        currentView: funnelViews.offer,
      });
    }
  }, [currentView]);

  const paymentPlanUrl = generatePath(funnelType, PAYMENT_PLAN_AGREEMENT);
  const queryParams = queryString.stringify({ providerId, offerId }, { addQueryPrefix: true });

  return (
    <Layout
      backLink={generatePath(funnelType, household)}
    >
      <Card
        progressBarValue={66.9117647059}
        content={(
          <div className="funnel-card__wrapper w-100">
            {spinner && (
              <div className="connect-bank__loader text-center">
                <Loading />
              </div>
            )}
            <div className="text-larger text-center font-weight-light mb-5">
              <>
                {resumeApp && (
                  <p data-private className="text-center text-base mb-2">
                    {`Welcome back, ${user.firstName}`}
                  </p>
                )}
                <h1 className="h4 font-weight-normal mb-5">
                  Great news!
                </h1>
                <p className="text-center text-base mb-0 px-md-10">
                  Based on the information you provided PayZen can provide you with a solution
                </p>
              </>
            </div>
            <div className="payment-plan-partner">
              <div className="mb-5">
                <h2 className="text-base text-dodger-blue-alt font-weight-bold text-uppercase">
                  Step 1
                </h2>
                <div className="position-relative">
                  <img src={hands} alt="" className="payment-plan-partner__icon" />
                  <p className="text-trout font-weight-light mb-0">
                    We’ll attempt to match you with financial assistance based on
                    {' '}
                    {providerName}
                    ’s eligibility criteria.
                  </p>
                </div>
              </div>
              <div className="mb-3">
                <h2 className="text-base text-dodger-blue-alt font-weight-bold text-uppercase">
                  Step 2
                </h2>
                <div className="position-relative">
                  <img src={handsHeart} alt="" className="payment-plan-partner__icon" />
                  <p className="text-trout font-weight-light mb-0">
                    If you don’t meet
                    {' '}
                    {providerName}
                    ’s criteria, we will offer
                    {' '}
                    {providerName}
                    {' '}
                    the following 0%, no fees payment plan for you to pay your medical bill.
                  </p>
                </div>
              </div>
              <div className="payment-plan__box py-2 mb-6 bg-alice-blue-alt">
                <div className="row no-gutters position-relative">
                  <div className="col-6 col-md-4 py-sm-1 text-center payment-plan__box__divider">
                    <span data-private className="d-block font-weight-light text-large line-height-1">
                      <Currency
                        amount={amountToPay}
                      />
                    </span>
                    <span className="text-tundora text-xsmall text-uppercase">
                      Full bill amount
                    </span>
                  </div>
                  <div className="col-6 col-md-4 py-sm-1 text-center payment-plan__box__divider">
                    <span data-private className="d-block font-weight-light text-large line-height-1">
                      {totalPayment && `${totalPayment} Months`}
                    </span>
                    <span className="text-tundora text-xsmall text-uppercase">months term</span>
                  </div>
                  <hr className="d-md-none col-12 payment-plan__box__hr" />
                  <div className="col-12 col-md-4 py-sm-1 text-center text-dodger-blue-alt ">
                    {monthlyPayment && (
                      <div className="line-height-1">
                        <span data-private className="font-weight-bold text-large">
                          <Currency
                            amount={monthlyPayment}
                          />
                        </span>
                        <span className="text-smaller font-weight-semibold">/mo</span>
                      </div>
                    )}
                    <span className="text-xsmall text-uppercase">Monthly Payment</span>
                  </div>
                </div>
              </div>
            </div>
            <Formik
              initialValues={{ agree: false }}
              validationSchema={confirmPaymentForm}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, values }) => (
                <Form>
                  <div className="mb-6">
                    <div className="media align-items-center">
                      <Field id="agree" type="checkbox" name="agree" component={Input} />
                      <div>
                        <p className="text-small mb-0">
                          I have reviewed and agree to the terms of my
                          {' '}
                          <a
                            target={`${hash}-proposed-payment-plan`}
                            href={paymentPlanUrl + queryParams}
                          >
                            Proposed Payment plan
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="funnel-card__form">
                    <Button type="primary" className="btn-block" isSubmit disabled={!values.agree || isSubmitting}>Approve & Continue</Button>
                  </div>
                </Form>
              )}
            </Formik>
            <p className="text-jumbo text-center text-smaller mt-5 mt-sm-6 mb-0 px-md-12">
              Please allow us 5 business days to process your medical bill. You should expect an
              email communication from us with next steps.
            </p>
          </div>
        )}
      />
    </Layout>
  );
};

export default PaymentPlan;
