import Homepage from 'views/Homepage';
import SelectAccount from 'views/SelectAccount';
import BillInfo from 'views/BillInfo';
import BillInfoError from 'views/BillInfoError';
import MRN from 'views/MRN';
import SSN from 'views/SSN';
import InvoiceToPay from 'views/InvoiceToPay';
import ReviewInfo from 'views/ReviewInfo';
import ManualAddress from 'views/ManualAddress';
import PaymentPlanOptions from 'views/PaymentPlanOptions';
import ConnectPaymentMethod from 'views/ConnectPaymentMethod';
import ConfirmPayment from 'views/ConfirmPayment';
import Success from 'views/Success';
import Document from 'views/Document';
import Error from 'views/Error';
import ErrorIdentity from 'views/ErrorIdentity';
import ErrorVault from 'views/ErrorVault';
import ErrorEnroll from 'views/ErrorEnroll';
import ErrorMessage from 'views/ErrorMessage';
import NoMatch from 'views/NoMatch';
import LetsGetInTouch from 'views/LetsGetInTouch';
import Maintenance from 'views/Maintenance';
import SelectUser from 'views/SelectUser';
import PatientInvite from 'views/PatientInvite';
import UrlShortener from 'views/UrlShortener';
import NoCreditRecordFound from 'views/TUErrors/NoCreditRecordFound';
import CreditFreeze from 'views/TUErrors/CreditFreeze';
import OFACHit from 'views/TUErrors/OFACHit';
import BankruptcyExists from 'views/TUErrors/BankruptcyExits';

import withProvider from 'components/withProvider';

// views bellow are not longer in use
import CreateBill from 'views/CreateBill';
import UploadBill from 'views/UploadBill';
import FinancialInfo from 'views/FinancialInfo';
import Household from 'views/Household';
import ConfirmSSN from 'views/ConfirmSSN';
import PaymentPlan from 'views/PaymentPlan';
import PaymentPlanPartner from 'views/PaymentPlanPartner';
import PaymentPlanPartnerResume from 'views/PaymentPlanPartnerResume';
import ConnectBank from 'views/ConnectBank';
import ConnectBankSimple from 'views/ConnectBankSimple';
import AllSet from 'views/AllSet';
import PaymentError from 'views/PaymentError';
import SendCode from 'views/SendCode';
import VerifyCode from 'views/VerifyCode';
import withPartner from 'components/withPartner';
import withOffer from 'components/withOffer';
import withBankFunnel from 'components/withBankFunnel';

// EPIC Related Imports
import Mychart from 'views/Mychart';

const chars = [...'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789abcdefghjklmnpqrstuvwxyz'];
const hashLength = 5;
// eslint-disable-next-line no-bitwise
const hash = [...Array(hashLength)].map(() => chars[Math.random() * chars.length | 0]).join('');

// EPIC Related Definitions
export const mychart = {
  route: ['/app/source/mychart'],
  path: '/source/mychart',
  component: Mychart,
  isPrivate: false,
};

export const homepage = {
  route: ['/', '/:id'],
  path: '/',
  component: Homepage,
  isPrivate: false,
  withTracker: false,
};

export const selectAccount = {
  route: ['/app/select-account'],
  path: '/select-account',
  component: SelectAccount,
  isPrivate: false,
  withTracker: true,
};

export const billInfo = {
  route: ['/app/bill-info'],
  path: '/bill-info',
  component: withProvider(BillInfo),
  isPrivate: false,
  withTracker: true,
};

export const billInfoError = {
  route: ['/app/bill-info-error'],
  path: '/bill-info-error',
  component: withProvider(BillInfoError),
  isPrivate: false,
  withTracker: true,
};

export const letsGetInTouch = {
  route: ['/app/lets-get-in-touch'],
  path: '/lets-get-in-touch',
  component: LetsGetInTouch,
  isPrivate: false,
  withTracker: true,
};

export const selectUser = {
  route: ['/app/select-user'],
  path: '/select-user',
  component: withProvider(SelectUser),
  isPrivate: true,
  withTracker: true,
};

export const mrn = {
  route: ['/app/confirm-mrn'],
  path: '/confirm-mrn',
  component: MRN,
  isPrivate: false,
  withTracker: true,
};

export const ssn = {
  route: ['/app/ssn'],
  path: '/ssn',
  component: SSN,
  isPrivate: false,
  withTracker: true,
};

export const invoiceToPay = {
  route: ['/app/invoice-to-pay'],
  path: '/invoice-to-pay',
  component: withProvider(InvoiceToPay),
  isPrivate: true,
  withTracker: true,
};

export const reviewInfo = {
  route: ['/app/review-info'],
  path: '/review-info',
  component: withProvider(ReviewInfo),
  isPrivate: true,
  withTracker: true,
};

export const manualAddress = {
  route: ['/app/address'],
  path: '/address',
  component: withProvider(ManualAddress),
  isPrivate: true,
  withTracker: true,
};

export const paymentPlan = {
  route: ['/app/payment-plan'],
  path: '/payment-plan',
  component: withProvider(PaymentPlanOptions),
  isPrivate: true,
  withTracker: true,
};

export const connectPaymentMethod = {
  route: ['/app/connect-payment-method'],
  path: '/connect-payment-method',
  component: withProvider(ConnectPaymentMethod),
  isPrivate: true,
  withTracker: true,
};

export const confirmPayment = {
  route: ['/app/confirm-payment'],
  path: '/confirm-payment',
  component: withProvider(ConfirmPayment),
  isPrivate: true,
  withTracker: true,
};

export const success = {
  route: ['/app/success'],
  path: '/success',
  component: withProvider(Success),
  isPrivate: true,
  withTracker: true,
};

export const planExists = {
  route: ['/app/plan-exists'],
  path: '/plan-exists',
  component: withProvider(Success),
  isPrivate: true,
  withTracker: true,
};

export const PRIVACY_NOTICE = {
  route: ['/app/:hash/privacy-notice'],
  path: `/${hash}/privacy-notice`,
  component: Document,
  isPrivate: false,
  apiDocId: 'privacy-notice',
  withTracker: true,
};

export const AUTH_FOR_HEALTH_INFO = {
  route: ['/app/:hash/auth-for-health-info'],
  path: `/${hash}/auth-for-health-info`,
  component: Document,
  isPrivate: false,
  apiDocId: 'auth-for-health-info',
  withTracker: true,
};

export const AUTH_FOR_HEALTH_INFO_PARTNER = {
  route: ['/app/:hash/auth-for-health-info-partner'],
  path: `/${hash}/auth-for-health-info-partner`,
  component: Document,
  isPrivate: true,
  apiDocId: 'auth-for-health-info-partner',
  withTracker: true,
};

export const CREDIT_REPORT_AUTH = {
  route: ['/app/:hash/credit-report-authorization'],
  path: `/${hash}/credit-report-authorization`,
  component: Document,
  isPrivate: false,
  apiDocId: 'credit-report-authorization',
  withTracker: true,
};

export const ESIGNATURE_DISCLOSURE = {
  route: ['/app/:hash/esignature-disclosure'],
  path: `/${hash}/esignature-disclosure`,
  component: Document,
  isPrivate: false,
  apiDocId: 'esignature-disclosure',
  withTracker: true,
};

export const TERMS_OF_SERVICE = {
  route: ['/app/:hash/terms-of-service'],
  path: `/${hash}/terms-of-service`,
  component: Document,
  isPrivate: false,
  apiDocId: 'terms-of-service',
  withTracker: true,
};

// payments related docs
export const PAYMENT_PLAN_AGREEMENT = {
  route: ['/app/:hash/payment-plan-agreement'],
  path: `/${hash}/payment-plan-agreement`,
  component: Document,
  isPrivate: true,
  apiDocId: 'payment-plan-agreement',
  withTracker: true,
};

export const AUTOMATIC_PAYMENT_AUTHORIZATION = {
  route: ['/app/:hash/automatic-payment-authorization'],
  path: `/${hash}/automatic-payment-authorization`,
  component: Document,
  isPrivate: true,
  apiDocId: 'automatic-payment-authorization',
  withTracker: true,
};

export const PAYMENT_CONFIRMATION = {
  route: ['/app/:hash/payment-confirmation'],
  path: `/${hash}/payment-confirmation`,
  component: Document,
  isPrivate: true,
  apiDocId: 'payment-confirmation',
  withTracker: true,
};

export const error = {
  route: ['/app/error'],
  path: '/error',
  component: Error,
  isPrivate: false,
  withTracker: true,
};

export const errorIdentity = {
  route: ['/app/error-identity'],
  path: '/error-identity',
  component: ErrorIdentity,
  isPrivate: false,
  withTracker: true,
};

export const errorVault = {
  route: ['/app/error-vault'],
  path: '/error-vault',
  component: ErrorVault,
  isPrivate: false,
  withTracker: true,
};

export const errorEnroll = {
  route: ['/app/error-enroll'],
  path: '/error-enroll',
  component: ErrorEnroll,
  isPrivate: false,
  withTracker: true,
};

export const errorMessage = {
  route: ['/app/error-message'],
  path: '/error-message',
  component: ErrorMessage,
  isPrivate: false,
  withTracker: true,
};

export const errorNoCreditRecordFound = {
  route: ['/app/no-credit-record-found'],
  path: '/no-credit-record-found',
  component: NoCreditRecordFound,
  isPrivate: false,
  withTracker: true,
};

export const errorCreditFreeze = {
  route: ['/app/credit-freeze'],
  path: '/credit-freeze',
  component: CreditFreeze,
  isPrivate: false,
  withTracker: true,
};

export const errorOFACHit = {
  route: ['/app/ofac-hit'],
  path: '/ofac-hit',
  component: OFACHit,
  isPrivate: false,
  withTracker: true,
};

export const errorBankruptcyExists = {
  route: ['/app/bankruptcy-exists'],
  path: '/bankruptcy-exists',
  component: BankruptcyExists,
  isPrivate: false,
  withTracker: true,
};

export const maintenance = {
  route: ['/app/maintenance'],
  path: '/maintenance',
  component: Maintenance,
  isPrivate: false,
  withTracker: false,
};

export const page404 = {
  route: ['/i/404'],
  path: '/404',
  component: NoMatch,
  isPrivate: false,
  withTracker: false,
};

export const patientInvite = {
  route: ['/i/:inviteCode'],
  path: '/',
  component: PatientInvite,
  isPrivate: false,
  withTracker: false,
};

export const urlShortener = {
  route: ['/s/:linkId'],
  path: '/',
  component: UrlShortener,
  isPrivate: false,
  withTracker: false,
};

// not used???
export const paymentError = {
  route: ['/app/payment-error'],
  path: '/payment-error',
  component: PaymentError,
  isPrivate: false,
  withTracker: false,
};

// not used???
export const connectBank = {
  route: ['/app/connect-bank'],
  path: '/connect-bank',
  component: withProvider(ConnectBankSimple),
  isPrivate: true,
  withTracker: false,
};

// not used???
export const paymentPlanBank = {
  route: ['/app/payment-plan-summary'],
  path: '/payment-plan-summary',
  component: withBankFunnel(PaymentPlan),
  isPrivate: true,
  withTracker: false,
};

// not used
export const createBill = {
  route: ['/partner/add-new-bill'],
  path: '/add-new-bill',
  component: withPartner(CreateBill),
  isPrivate: true,
  withTracker: false,
};

// not used
export const uploadBill = {
  route: ['/partner/upload-bill'],
  path: '/upload-bill',
  component: withPartner(UploadBill),
  isPrivate: true,
  withTracker: false,
};

// not used
export const personalInfo = {
  route: ['/partner/personal-info'],
  path: '/personal-info',
  component: withPartner(ReviewInfo),
  isPrivate: true,
  withTracker: false,
};

// not used
export const financialInfo = {
  route: ['/app/financial-info'],
  path: '/financial-info',
  component: withProvider(FinancialInfo),
  isPrivate: true,
  withTracker: false,
};

// not used
export const financialInfoPartner = {
  ...financialInfo,
  route: ['/partner/financial-info'],
  component: withPartner(FinancialInfo),
};

// not used
export const household = {
  route: ['/partner/household'],
  path: '/household',
  component: withPartner(Household),
  isPrivate: true,
  withTracker: false,
};

// not used
export const confirmSSN = {
  route: ['/app/confirm-ssn'],
  path: '/confirm-ssn',
  component: withProvider(ConfirmSSN),
  isPrivate: true,
  withTracker: false,
};

// not used
export const confirmSSNPartner = {
  ...confirmSSN,
  route: ['/partner/confirm-ssn'],
  component: withPartner(ConfirmSSN),
};

// not used
export const paymentPlanPartner = {
  ...paymentPlan,
  route: ['/partner/payment-plan'],
  component: withPartner(PaymentPlanPartner),
};

// not used
export const paymentPlanPartnerResume = {
  route: ['/partner/plan-summary'],
  path: '/plan-summary',
  component: withOffer(PaymentPlanPartnerResume),
  isPrivate: true,
  withTracker: false,
};

// not used
export const connectBankPartner = {
  ...connectBank,
  route: ['/partner/connect-bank'],
  component: withOffer(ConnectBank),
};

// not used
export const confirmPaymentPartner = {
  ...confirmPayment,
  route: ['/partner/confirm-payment'],
  component: withOffer(ConfirmPayment),
};

// not used
export const successPartner = {
  ...success,
  route: ['/partner/success'],
  component: withOffer(Success),
};

// not used
export const allSet = {
  route: ['/partner/all-set'],
  path: '/all-set',
  component: withPartner(AllSet),
  isPrivate: true,
  withTracker: false,
};

// not used
export const sendCode = {
  route: ['/partner/send-code'],
  path: '/send-code',
  component: SendCode,
  isPrivate: false,
  withTracker: false,
};

// not used
export const verifyCode = {
  route: ['/partner/verify-code'],
  path: '/verify-code',
  component: VerifyCode,
  isPrivate: false,
  withTracker: false,
};

// this export should be always the last one on this file
export const noMatch = {
  route: null,
  path: '/404',
  component: NoMatch,
  isPrivate: false,
  withTracker: false,
};
