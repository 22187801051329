import React from 'react';

import Card from 'components/Funnel/Card';
import Header from 'components/Header';
import Button from 'components/Button';
import CardFormat from 'components/CardFormat';
import { pathOr } from 'ramda';

import cardError from 'assets/images/card-error.svg';
import generatePath from 'utils/generatePath';
import { connectPaymentMethod } from 'libs/views';
import { useHistory } from 'react-router-dom';
import Currency from 'components/Currency';

const PaymentError = ({ offer }) => {
  const history = useHistory();
  const onClick = () => history.push(generatePath('app', connectPaymentMethod));
  const firstPaymentAmount = pathOr('', ['paymentsAmount', '0'])(offer);
  const errorMessage = pathOr('', ['location', 'state', 'errorMessage'])(history);
  const cardInfo = pathOr({}, ['location', 'state', 'cardInfo'])(history);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="wrapper funnel-wrapper">
        <Card
          content={(
            <div className="funnel-card__wrapper">
              <div className="funnel-card__form">
                <div className="text-center mb-4 mb-sm-6">
                  <div className="mb-5">
                    <img src={cardError} alt="" />
                  </div>
                  <h1 className="text-larger text-sm-xlarger mb-3 font-weight-normal text-tuna px-4">
                    Your payment didn’t go through
                  </h1>
                  <p className="text-tuna">
                    Please try a different payment method to enroll in your payment plan.
                  </p>
                </div>
                <div className="bg-linen pt-5 px-5 pb-4 mb-5">
                  <ul className="list-unstyled text-small text-tuna mb-0">
                    <li className="mb-2">
                      Reason for Error:
                      {' '}
                      <b className="text-flush-mahogany">{errorMessage}</b>
                    </li>
                    <li className="mb-2">
                      Payment Amount:
                      {' '}
                      <Currency
                        amount={firstPaymentAmount}
                        className="font-weight-bold"
                      />
                    </li>
                    <li data-private>
                      From:
                      {' '}
                      <CardFormat data={cardInfo} />
                    </li>
                  </ul>
                </div>
                <Button type="primary" className="btn-block" onClick={onClick}>
                  Update payment method
                </Button>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default PaymentError;
