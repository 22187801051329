import {
  includes,
  when,
  pipe,
  map,
  mapObjIndexed,
} from 'ramda';

const isObject = (value) => value && typeof value === 'object' && value.constructor === Object;
const isIterable = (value) => isObject(value) || Array.isArray(value);

const hide = (value, key) => (
  includes(key, [
    'firstName', 'middleName', 'lastName', 'phone', 'email',
    'insuranceId', 'annualIncome', 'additionalIncome',
    'householdIncome', 'spouseIncome', 'city',
    'state', 'postalCode', 'address', 'addressNumber',
    'birthDate', 'dateOfBirth',
    'medicalRecordNumber', 'mrn', 'ssn', 'riskGroupNum',
    'totalAmount', 'amount', 'prefundAmount', 'newAmount',
    'totalPayment', 'confirmationCode',
  ]) ? '***' : value
);

const clearPIIData = when(
  isIterable,
  pipe(
    when(
      isObject,
      mapObjIndexed(hide),
    ),
    map((x) => clearPIIData(x)),
  ),
);

export default clearPIIData;
