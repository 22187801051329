import React, { useState } from 'react';
import {
  prop, compose, join, values, propOr,
} from 'ramda';
import {
  Formik, Form,
} from 'formik';
import browserHistory from 'browserHistory';

import { phoneForm } from 'utils/validationSchemas';

import ErrorLayout from 'components/ErrorLayout';
import Icon from 'components/Icon';
import { PhoneInput, ErrorMessage as ErrorMsg } from 'components/Fields';
import Link from 'components/Link';
import Button from 'components/Button';

import { SUPPORT_PHONE_NUMBER } from 'constants/support';

const ErrorVault = ({ history, location, sendSlackMessage }) => {
  const { state } = browserHistory.location;
  const [errorState] = useState(location.state || state);
  const patientInfo = prop('patientInfo')(errorState);
  const provider = prop('provider')(errorState);
  const initialValues = { phone: '' };

  const onSubmit = ({ phone }) => {
    const data = compose(join(', '), values)({ ...patientInfo, phone });

    sendSlackMessage({
      type: 'error-form',
      title: 'Submitted form error page',
      provider,
      patientInfo: data,
    });

    return history.goBack();
  };

  return (
    <ErrorLayout>
      <div className="mb-6">
        <Icon name="dotted-person" className="error-page__icon mb-5 mm-md-6 text-alto" />
        <h1 className="h2 mb-5" data-cy="identity-error-title">Apologies</h1>
        <p className="text-center mb-0">
          We were unable to verify your identity or patient balance.
          Please double check your last name and date of birth for typos
        </p>
      </div>
      <div className="mb-6">
        <p className="text-center mb-5" data-cy="identity-error-help">
          Let us get in touch to help
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={phoneForm}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue, errors }) => {
            const errorMsg = propOr(false, 'phone')(errors);

            return (
              <Form>
                <div className="input-group input-group-round mb-4">
                  <PhoneInput
                    setField={setFieldValue}
                    id="phone"
                    type="tel"
                    name="phone"
                    className="form-control form-control--without-label pl-md-7"
                    placeholder="Provide your cell phone number"
                    data-cy="identity-contact"
                  />
                  <div className="input-group-append">
                    <Button
                      className="btn btn-outline-primary px-5 py-3"
                      type="submit"
                      disabled={isSubmitting}
                      data-cy="identity-send-contact"
                      eventName="send"
                    >
                      Send
                    </Button>
                  </div>
                </div>
                {errorMsg && <ErrorMsg error={errorMsg} />}
              </Form>
            );
          }}
        </Formik>
      </div>
      <p className="text-center mb-5">
        Or call us at
        {' '}
        <Link
          href={`tel:${SUPPORT_PHONE_NUMBER}`}
          eventName="call"
        >
          {SUPPORT_PHONE_NUMBER}
        </Link>
      </p>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        className="btn btn-primary btn-block text-decoration-none px-10 py-3"
        onClick={history.goBack}
        data-cy="identity-backToLogin"
        eventName="go-back"
      >
        Go Back
      </Link>
    </ErrorLayout>
  );
};

export default ErrorVault;
