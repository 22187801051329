import { connect } from 'react-redux';

import { getProviderConfig, selectProvider } from 'actions/provider';

import SelectAccount from './SelectAccount';

const mapStateToProps = ({
  application: { isEpicApp },
  organization,
}) => ({
  isEpicApp,
  organization,
});

const mapDispatchToProps = {
  selectProvider,
  getProviderConfig,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectAccount);
