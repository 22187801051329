import { compose } from 'redux';
import { connect } from 'react-redux';
import { prop, filter, propEq } from 'ramda';

import { getUser } from 'actions/user';
import { getPatient } from 'actions/patient';
import { getApplication } from 'actions/application';
import { getBill } from 'actions/bill';
import { getOffer } from 'actions/offer';
import { getOfferPayment } from 'actions/paymentMade';

import withProvider from './withProvider';

const mapStateToProps = ({
  user,
  patient,
  application: {
    getFromVaultStatus, getApplicationStatus, currentView, applicationId, status, offerId,
  },
  bill,
  bill: { bills },
  offer,
  paymentMade,
}) => {
  const pendingBills = filter(propEq('status', 'BILL_PENDING'))(bills);

  return {
    user,
    patient,
    getFromVaultStatus,
    getApplicationStatus,
    bill,
    offer,
    paymentMadeStatus: prop('status')(paymentMade),
    currentView,
    applicationId,
    applicationStatus: status,
    pendingBills,
    offerId,
  };
};

const mapDispatchToProps = {
  getUser,
  getPatient,
  getApplication,
  getBill,
  getOffer,
  getOfferPayment,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProvider,
);
