import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { withRouter } from 'react-router-dom';
import {
  path, isNil, compose, prop, last, omit, mapObjIndexed,
} from 'ramda';

import { Input, SearchInput, InfoBubble } from 'components/Fields';

import generatePath from 'utils/generatePath';
import { landingPartnerForm } from 'utils/validationSchemas';

import { uploadBill, error, verifyCode } from 'libs/views';
import { convertToCents } from 'utils/currency';

const onRequestOptions = (value) => ({ params: { q: value } });

const generateFormData = (data) => {
  const newData = {
    ...data,
    totalAmount: convertToCents(data.totalAmount),
    includedInOffer: true,
  };

  const form = new FormData();

  mapObjIndexed((value, key) => {
    form.set(key, value);
  }, newData);

  return form;
};

const LandingForm = ({
  createPatient,
  partnerId,
  history,
  inputClass,
  buttonClass,
  buttonText,
  getProviderName,
  createProvider,
  createPartnerBill,
  bills,
  updateApplication,
  updateBill,
}) => {
  const [focusTotalAmount, setFocusTotalAmount] = useState(false);
  const [focusProviderName, setFocusProviderName] = useState(false);
  const onFailure = () => history.push(generatePath('partner', error));
  const onSuccess = () => history.push(generatePath('partner', uploadBill));

  const createPartnerBillSuccess = (data, { patientId, applicationId }) => () => {
    updateApplication({
      patientId,
      applicationId,
      ...data,
      meta: null,
    })
      .then(onSuccess)
      .catch(onFailure);
  };

  const createProviderSuccess = (data, { patientId, applicationId }) => (response) => {
    const providerId = path(['value', 'providerId'])(response);
    const billId = compose(prop('billId'), last)(bills);
    const newData = { ...omit(['providerName', 'email'])(data), providerId };
    const formData = generateFormData(billId ? { ...newData, billId } : newData);
    const billPayload = { patientId, data: formData };

    return createPartnerBill(billPayload)
      .then(createPartnerBillSuccess(
        { providerId, medicalRecordNumber: data.accountId },
        { patientId, applicationId },
      ))
      .then(() => {
        if (billId) updateBill({ billId, offerId: null, status: 'BILL_PENDING' });
      })
      .catch(onFailure);
  };

  const createPatientSuccess = (values) => (response) => {
    const twoFactorToken = path(['value', 'twoFactorToken'])(response);
    const patientId = path(['value', 'application', 'patientId'])(response);
    const applicationId = path(['value', 'application', 'applicationId'])(response);

    if (twoFactorToken) return history.push(generatePath('partner', verifyCode), { email: values.email });

    return createProvider({ providerName: values.providerName })
      .then(createProviderSuccess(values, { patientId, applicationId }))
      .catch(onFailure);
  };

  const handleSubmit = (values) => {
    const data = isNil(partnerId)
      ? { email: values.email }
      : { email: values.email, partnerId };

    createPatient(data)
      .then(createPatientSuccess(values))
      .catch(onFailure);
  };

  return (
    <Formik
      initialValues={{ totalAmount: '', providerName: '', email: '' }}
      validationSchema={landingPartnerForm}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Field
            id="totalAmount"
            name="totalAmount"
            type="currency"
            className={inputClass}
            label="Bill amount"
            component={Input}
            icon="$"
            errorInside
            onFocus={() => setFocusTotalAmount(true)}
            onBlur={() => setFocusTotalAmount(false)}
          />
          {focusTotalAmount && (
            <div className="mb-2 mb-sm-4">
              <InfoBubble
                content={['Add one bill at a time', 'Bills between $250-$10,000 qualify', 'You can add additional bills later']}
                className="py-3 bg-white"
              />
            </div>
          )}
          <Field
            id="providerName"
            name="providerName"
            type="text"
            label="Provider Name"
            className={inputClass}
            request={getProviderName}
            requestOptions={onRequestOptions}
            component={SearchInput}
            errorInside
            onFocus={() => setFocusProviderName(true)}
            onBlur={() => setFocusProviderName(false)}
          />
          {focusProviderName && (
            <div className="mb-2 mb-sm-4">
              <InfoBubble
                content={['Enter your medical provider name (e.g. hospital, clinic)', 'Don’t enter the collection agency']}
                className="py-3 bg-white"
              />
            </div>
          )}
          <Field
            id="email"
            name="email"
            type="email"
            className={inputClass}
            label="Enter your email"
            component={Input}
            errorInside
          />
          <div>
            <p className="text-mustard text-small">Don&apos;t worry, we won&apos;t spam you</p>
          </div>
          <button
            type="submit"
            className={`btn btn-mustard btn-block ${buttonClass}`}
            disabled={isSubmitting}
          >
            {buttonText}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(LandingForm);
