import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  includes, omit, compose, prop, head, divide, path, pathOr, equals,
} from 'ramda';

import generatePath from 'utils/generatePath';
import { convertToDollars } from 'utils/currency';

import funnelViews from 'libs/funnelViews';
import {
  confirmPayment, paymentPlan, error as errorPage, paymentPlanPartnerResume,
} from 'libs/views';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Button from 'components/Button';
import Loading from 'components/Loading';
import { Input } from 'components/Fields';

import { connectBankForm } from 'utils/validationSchemas';
import getErrorMessage from 'utils/getErrorMessage';
import showNotification from 'utils/showNotification';

import imageBank from 'assets/images/bank.svg';
import imageECheck from 'assets/images/e-check.svg';

import imageRN1 from 'assets/images/rn-1.svg';
import imageRN2 from 'assets/images/rn-2.svg';
import imageAC1 from 'assets/images/ac-1.svg';
import imageAC2 from 'assets/images/ac-2.svg';
import hipaaLogo from 'assets/images/homepage/hipaa-logo.png';
import ahaLogo from 'assets/images/homepage/aha-logo.png';
import privacyLogo from 'assets/images/homepage/privacy-logo.png';

import ReadMore from './ReadMore';

const initialValuesManuallyForm = {
  routingNumber: '',
  accountNumber: '',
  accountHolderName: '',
  confirmAccountNumber: '',
  accountType: 'savings',
};

const Banner = ({ isAppFunnel }) => {
  if (isAppFunnel) {
    return (
      <div className="text-center">
        <p className="mb-0">
          Select a payment method to start
          {' '}
          <br className="d-sm-none" />
          your payment plan.
        </p>
      </div>
    );
  }

  return (
    <p className="pt-sm-1 pt-md-2 mb-0">
      Make your first payment to activate your plan.
    </p>
  );
};

const ConnectBank = ({
  history,
  createPlaidPaymentMethod,
  createManualPaymentMethod,
  logPlaidEvent,
  currentView,
  applicationId,
  patientId,
  updateApplication,
  offer,
  firstName,
  confirmPatientOffer,
  bills,
  location,
  funnelType,
}) => {
  const [connectAutomatically, setConnectAutomatically] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const isAppFunnel = equals(funnelType, 'app');
  const resumeApp = pathOr(false, ['state', 'resumeApp'])(location);
  const backRoute = isAppFunnel ? paymentPlan : paymentPlanPartnerResume;

  const toggleConnect = () => setConnectAutomatically(!connectAutomatically);
  const onSubmitSuccess = () => history.push(generatePath(funnelType, confirmPayment));

  const {
    totalPayment, totalAmount, newAmount,
  } = offer;

  const convertAmount = convertToDollars(newAmount || totalAmount);
  const monthlyPayment = divide(convertAmount, totalPayment);

  const onSubmitFailure = (data) => (error) => {
    setIsFetching(false);
    const { bankName, accountMask } = data;
    const errorMessage = getErrorMessage(error);

    if (includes('Insufficient funds', errorMessage)) {
      return history.push(generatePath(funnelType, errorPage), {
        type: 'income', monthlyPayment, reason: errorMessage, bankName, accountMask,
      });
    }

    return showNotification({ type: 'error', message: errorMessage });
  };

  const offerId = prop('offerId')(offer) || compose(prop('offerId'), head)(bills);

  const handleSubmit = (data, actions) => {
    const cleanData = omit(['confirmAccountNumber'])(data);

    setIsFetching(true);

    createManualPaymentMethod(cleanData)
      .then(() => confirmPatientOffer({ patientId, offerId, data: {} }))
      .then(onSubmitSuccess)
      .catch(onSubmitFailure({}));

    return actions.setSubmitting(false);
  };

  const handleOnExit = (error) => {
    if (error) {
      logPlaidEvent({ metadata: error });
    }
  };

  const onEvent = (eventName, metadata) => {
    logPlaidEvent({ eventName, metadata });
  };

  const handleOnSuccess = (publicToken, metadata) => {
    setIsFetching(true);

    const bankName = path(['institution', 'name'])(metadata);
    const accountMask = path(['account', 'mask'])(metadata);

    createPlaidPaymentMethod({
      publicToken,
      offerId,
      accountId: metadata.account.id,
      accountName: metadata.account.name,
      linkSessionId: metadata.link_session_id,
      bankName: metadata.institution.name,
      mask: metadata.account.mask,
    })
      .then(() => confirmPatientOffer({
        patientId,
        offerId,
        data: {
          plaidAccessToken: publicToken,
        },
      }))
      .then(onSubmitSuccess)
      .catch(onSubmitFailure({ bankName, accountMask }));
  };

  const plaidHandler = window.Plaid.create({
    clientName: 'PayZen',
    env: process.env.REACT_APP_PLAID_ENV,
    product: ['auth', 'transactions'],
    key: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    onExit: handleOnExit,
    onSuccess: handleOnSuccess,
    onEvent,
  });

  const openPlaid = () => plaidHandler.open();

  useEffect(() => {
    if (currentView !== funnelViews.connectBank && applicationId) {
      updateApplication({
        patientId,
        applicationId,
        currentView: funnelViews.connectBank,
      });
    }
  }, [currentView]);

  return (
    <Layout
      backLink={generatePath(funnelType, backRoute)}
    >
      <Card
        progressBarValue={88.9705882353}
        content={(
          <>
            <div className={`funnel-card__wrapper ${connectAutomatically ? 'px-3 px-sm-5' : 'pb-4'}`}>
              {isFetching && (
                <div className="connect-bank__loader text-center">
                  <Loading />
                </div>
              )}
              {connectAutomatically ? (
                <>
                  <div className="text-center mb-6">
                    {isAppFunnel && (
                      <p data-private className="mr-2">
                        {resumeApp ? `Welcome back, ${firstName}` : `Great ${firstName},`}
                      </p>
                    )}
                    <h1 className="text-larger text-sm-xlarger mb-0 font-weight-normal">
                      {isAppFunnel
                        ? 'Let’s make your first payment!'
                        : 'Select a payment method for your plan'}
                    </h1>
                  </div>
                  <div className="row no-gutters">
                    <div className="col text-center mr-2 mr-sm-4">
                      <div className="connect-bank__card">
                        <button type="button" className="connect-bank__button" onClick={openPlaid}>
                          Link Bank Account
                        </button>
                        <div className="connect-bank__icon bg-dodger-blue mb-4 pb-1">
                          <img src={imageBank} alt="" />
                        </div>
                        <p className="text-charade text-larger mb-1">
                          Link
                          <br />
                          Bank Account
                        </p>
                        <ReadMore />
                      </div>
                    </div>
                    <div className="col text-center">
                      <div className="connect-bank__card h-100">
                        <button type="button" className="connect-bank__button" onClick={toggleConnect}>
                          Pay by Electronic Check
                        </button>
                        <div className="connect-bank__icon bg-dodger-blue mb-4 pb-1">
                          <img src={imageECheck} alt="" className="ml-2 mt-2" />
                        </div>
                        <p className="text-charade text-larger">
                          Pay by
                          <br />
                          Electronic Check
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="text-center text-comet mt-5 mt-sm-6">
                    <p className="letter-spacing mb-1 mb-md-3">Your transaction is secure</p>
                    <div className="row align-items-center small-gutters">
                      <div className="col text-center">
                        <img src={hipaaLogo} alt="" className="img-multiply img-fluid" />
                      </div>
                      <div className="col text-center">
                        <img src={ahaLogo} alt="" className="img-multiply img-fluid" />
                      </div>
                      <div className="col text-center">
                        <img src={privacyLogo} alt="" className="img-multiply img-fluid" />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="text-center mb-5">
                    <Button type="link" className="p-0" onClick={toggleConnect}>Connect Automatically</Button>
                  </div>
                  <div className="text-center mb-5">
                    <h2 className="h6 mb-0">Pay by Electronic Check</h2>
                  </div>
                  <div className="funnel-card__form mb-8">
                    <Formik
                      initialValues={initialValuesManuallyForm}
                      validationSchema={connectBankForm}
                      onSubmit={handleSubmit}
                    >
                      {({ isSubmitting, isValid }) => (
                        <Form className="d-flex flex-column h-100">
                          <div className="row no-gutters mb-5">
                            <div className="col-12">
                              <Field id="accountHolderName" type="text" name="accountHolderName" label="Name on account" component={Input} />
                            </div>
                            <div className="col-12">
                              <Field id="routingNumber" type="text" name="routingNumber" label="Routing number" component={Input} />
                            </div>
                            <div className="col-12 mb-4">
                              <div className="d-flex align-items-start justify-content-between bg-alabaster-alt px-4 pt-5 pb-4">
                                <img src={imageRN1} alt="" />
                                <img src={imageRN2} alt="" />
                              </div>
                            </div>
                            <div className="col-12">
                              <Field id="accountNumber" type="text" name="accountNumber" label="Account number" component={Input} />
                            </div>
                            <div className="col-12 mb-4">
                              <div className="d-flex align-items-start justify-content-between bg-alabaster-alt px-4 pt-5 pb-4">
                                <img src={imageAC1} alt="" />
                                <img src={imageAC2} alt="" />
                              </div>
                            </div>
                            <div className="col-12">
                              <Field id="confirmAccountNumber" type="text" name="confirmAccountNumber" label="Confirm Account Number" component={Input} />
                            </div>
                          </div>
                          <Button type="primary" className="btn-block" isSubmit disabled={!isValid || isSubmitting}>Connect</Button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        banner={connectAutomatically && <Banner isAppFunnel={isAppFunnel} />}
      />
    </Layout>
  );
};

export default ConnectBank;
