import mixpanel from 'mixpanel-browser';
import LogRocket from 'logrocket';
import { pathOr } from 'ramda';
import { anonymizeEmail, anonymizeName } from 'utils/anonymize';

const mixpanelIdentify = (response) => {
  const user = pathOr({}, ['data', 'user'], response);

  if (user && user.firstName) {
    if (user.userId) {
      mixpanel.identify(user.userId);
    }

    const userId = user.userId ? { 'user id': user.userId } : {};
    const userEmail = user.email ? { $email: anonymizeEmail(user.email) } : {};

    LogRocket.getSessionURL((sessionURL) => {
      mixpanel.people.set({
        $first_name: anonymizeName(user.firstName),
        $last_name: anonymizeName(user.lastName),
        ...userEmail,
        ...userId,
        LogRocketURL: sessionURL,
      });
    });
  }

  return response;
};

export default mixpanelIdentify;
