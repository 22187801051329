import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import promiseMiddleware from 'redux-promise-middleware';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import { combineReducers } from 'redux';

import * as reducer from 'reducers';

const preloadedState = {};

const isProd = process.env.REACT_APP_ENV === 'production';

const middleware = (getDefaultMiddleware) => {
  const baseMiddleware = [promiseMiddleware, ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })];

  if (isProd) {
    return baseMiddleware;
  }

  return [...baseMiddleware, logger];
};

const persistConfig = {
  key: 'root',
  storage: storageSession,
};

export const store = configureStore({
  preloadedState,
  reducer: persistReducer(persistConfig, combineReducers(reducer)),
  middleware,
  devTools: !isProd,
});

export const persistor = persistStore(store);
