import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  includes,
  mapObjIndexed,
  trim,
  pathOr,
  filter,
  isEmpty,
  compose,
  join,
  values,
  omit,
} from 'ramda';
import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Button from 'components/Button';
import { Input } from 'components/Fields';
import Loading from 'components/Loading';

import { payzenPatientSSNForm } from 'utils/validationSchemas';
import getErrorMessage from 'utils/getErrorMessage';
import generatePath from 'utils/generatePath';
import getSubdomain from 'utils/getSubdomain';
import { guarantorValidation } from 'utils/guarantors';

import { error as errorPage, errorVault } from 'libs/views';

const SSN = ({
  funnelType,
  history,
  getFromVault,
  location,
  sendSlackMessage,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const providerName = getSubdomain(window);

  const initialValues = {
    payzenPatientSSN: '',
  };

  const onFailure = ({ data, actions }) => (error) => {
    actions.setSubmitting(false);
    setIsFetching(false);
    const errorMessage = getErrorMessage(error);
    const patientInfo = compose(join(', '), values)(data);

    sendSlackMessage({
      type: 'vault.failure',
      title: 'Failure - Get Info From Vault',
      provider: providerName,
      lastName: data.lastName,
      dateOfBirth: data.dateOfBirth,
      patientInfo,
      errorMessage,
    });

    if (includes('A User with this data was not found', errorMessage)) {
      const errorState = {
        type: 'vault',
        patientInfo: data,
        provider: providerName,
      };

      return history.replace(generatePath(funnelType, errorVault), errorState);
    }
    return history.replace(generatePath(funnelType, errorPage));
  };

  const onSuccess = (actions) => (response) => {
    const user = pathOr([], ['action', 'payload', 'user'])(response);
    setIsFetching(false);
    actions.setSubmitting(false);
    const patientInfo = compose(join(', '), values, omit(['middleName', 'suspendedUntil', 'typeName']))(user);

    sendSlackMessage({
      type: 'vault.success',
      title: 'Success - Get Info From Vault',
      provider: providerName,
      name: `${user.firstName} ${user.lastName}`,
      phone: user.phone,
      patientInfo,
    });

    return guarantorValidation(response, funnelType, history);
  };

  const handleSubmit = (data, actions) => {
    setIsFetching(true);
    const { state } = location;

    const obj = {
      ...mapObjIndexed(trim)(data),
      ...state,
    };

    return getFromVault(obj)
      .then(onSuccess(actions))
      .catch(onFailure({ data, actions }));
  };

  return (
    <Layout>
      <Card
        className="mt-2"
        progressBarValue={0.7352941176}
        content={(
          <div className="funnel-card__wrapper pb-8">
            {isFetching && (
              <div className="connect-bank__loader text-center">
                <Loading />
              </div>
            )}
            <div className="funnel-card__form text-center mb-5">
              <h1 className="text-larger text-sm-xlarger font-weight-normal">
                We need more information
              </h1>
              <p className="mb-0 px-2xs-4">
                Please enter the last four digits of your Social
                Security Number to confirm your identity.
              </p>
            </div>
            <div className="funnel-card__form">
              <Formik
                initialValues={initialValues}
                validationSchema={payzenPatientSSNForm}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, values: formData, errors }) => {
                  const isDataComplete = !compose(isEmpty, filter(isEmpty), values)(formData);
                  const WithErrors = !isEmpty(errors);
                  const isDisabled = isSubmitting || isDataComplete || WithErrors;

                  return (
                    <Form>
                      <div className="row no-gutters mb-5">
                        <div className="col mr-1 mr-3xs-4 pr-3xs-1">
                          <div className="form-control text-center">***</div>
                        </div>
                        <div className="col-2 mr-1 mr-3xs-4 pr-3xs-1">
                          <div className="form-control text-center">**</div>
                        </div>
                        <div className="col-5">
                          <Field
                            id="payzenPatientSSN"
                            type="text"
                            name="payzenPatientSSN"
                            component={Input}
                          />
                        </div>
                      </div>
                      <Button
                        type="primary"
                        className="btn-block"
                        isSubmit
                        disabled={isDisabled}
                        eventName="main-cta"
                      >
                        Continue
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
      />
    </Layout>
  );
};

export default SSN;
