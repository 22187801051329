import mixpanel from 'mixpanel-browser';
import { useLocation } from 'react-router-dom';

const useTrackMixpanelEvent = () => {
  const location = useLocation();
  const pathname = location.pathname.replace('/app/', '');

  const trackMixpanelEvent = (eventType, eventName, options = {}) => mixpanel.track(`${pathname}/${eventType}/${eventName}`, {
    ...options,
    'page name': location.pathname,
  });

  return trackMixpanelEvent;
};

export default useTrackMixpanelEvent;
