import React from 'react';
import PropTypes from 'prop-types';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import useTrackMixpanelEvent from 'utils/useTrackMixpanelEvent';

const Button = ({
  children,
  type,
  id,
  onClick,
  className,
  disabled,
  isSubmit,
  isAnchor,
  AnchorId,
  'data-cy': dataCy,
  'data-testid': dataTestId = '',
  eventName = '',
}) => {
  const trackMixpanelEvent = useTrackMixpanelEvent();

  const buttonClass = type ? `btn-${type}` : '';
  const disabledClass = disabled ? 'is-disabled' : '';
  const classes = `btn ${buttonClass} ${className} ${disabledClass}`;
  const rest = dataCy
    ? { 'data-cy': dataCy, 'data-testid': dataTestId }
    : { 'data-testid': dataTestId };

  const onClickWrapper = (e) => {
    trackMixpanelEvent('BC', eventName, {
      'button id': id,
      'button content': children,
    });

    return onClick(e);
  };

  if (isSubmit) {
    return (
      <button
        id={id}
        className={classes}
        onClick={onClickWrapper}
        disabled={disabled}
        type="submit"
        {...rest}
      >
        {children}
      </button>
    );
  }

  if (isAnchor) {
    return (
      <AnchorLink
        href={AnchorId}
        className={classes}
        onClick={onClickWrapper}
        {...rest}
      >
        {children}
      </AnchorLink>
    );
  }

  return (
    <button
      className={classes}
      onClick={(e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        onClickWrapper();
      }}
      disabled={disabled}
      type="button"
      {...rest}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  type: '',
  id: '',
  onClick: () => false,
  className: '',
  disabled: false,
  isSubmit: false,
  isAnchor: false,
  AnchorId: '',
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isSubmit: PropTypes.bool,
  isAnchor: PropTypes.bool,
  AnchorId: PropTypes.string,
};

export default Button;
