import React, { useState } from 'react';
import { prop } from 'ramda';
import { useLocation } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';
import browserHistory from 'browserHistory';

import Header from 'components/Header';
import Card from 'components/Funnel/Card';
import Button from 'components/Button';
import SupportPhoneModal from 'components/SupportPhoneModal';
import Link from 'components/Link';

import { SUPPORT_EMAIL, SUPPORT_PHONE_NUMBER } from 'constants/support';

import image from 'assets/images/call-center.svg';

import Footer from './Footer';

const LetsGetInTouch = ({
  sendSlackMessage,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  const { state } = browserHistory.location;
  const [errorState] = useState(location.state || state);
  const patientInfo = prop('patientInfo')(errorState);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="wrapper funnel-wrapper">
        <Card
          className="mt-2 mt-sm-0"
          content={(
            <div className="error-page text-center mx-auto text-charade">
              <div className="flex-fill">
                <img src={image} alt="" className="mb-5" />
                <h1 className="text-xlarger mb-3 font-weight-normal" data-testid="lets-get-in-touch-title">
                  Let&apos;s get in touch
                </h1>
                <p className="mb-3" data-testid="lets-get-in-touch-contact-text">
                  We were unable to verify your bills.
                  Contact our support team to assist you now:
                </p>
                <div className="funnel-card__form mb-5">
                  {isBrowser ? (
                    <>
                      <Button
                        type="primary"
                        className="btn-block mb-2 mw-100"
                        data-testid="lets-get-in-touch-phone-button-desktop"
                        onClick={() => setIsOpen(true)}
                        eventName="call"
                      >
                        Call
                        {' '}
                        {SUPPORT_PHONE_NUMBER}
                      </Button>
                      <SupportPhoneModal
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                      />
                    </>
                  ) : (
                    <Link
                      href={`tel:${SUPPORT_PHONE_NUMBER}`}
                      className="btn btn-primary btn-block mb-2 mw-100"
                      data-testid="lets-get-in-touch-phone-button-mobile"
                      target="_self"
                      eventName="call"
                    >
                      Call
                      {' '}
                      {SUPPORT_PHONE_NUMBER}
                    </Link>
                  )}
                  <Link
                    href={`mailto:${SUPPORT_EMAIL}`}
                    className="btn btn-alice-blue-alt btn-block mw-100"
                    data-testid="lets-get-in-touch-email-button"
                    target="_self"
                    eventName="email"
                  >
                    Email
                    {' '}
                    {SUPPORT_EMAIL}
                  </Link>
                </div>
                <Footer sendSlackMessage={sendSlackMessage} patientInfo={patientInfo} />
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default LetsGetInTouch;
