import { connect } from 'react-redux';
import { propOr } from 'ramda';

import { updateApplication } from 'actions/application';

import SelectUser from './SelectUser';

const mapStateToProps = ({
  user,
  user: { userBackup },
  patient: { patientId, birthDate },
  application: { applicationId, currentView, isEpicApp },
  bill: { bills },
  organization: { showOrganizationView },
}) => {
  const guarantors = propOr([], 'guarantors')(user);

  return {
    user,
    userBackup,
    patientId,
    applicationId,
    guarantors,
    birthDate,
    bills,
    currentView,
    isEpicApp,
    showOrganizationView,
  };
};

const mapDispatchToProps = {
  updateApplication,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectUser);
