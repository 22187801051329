import { createAsyncAction } from 'redux-promise-middleware-actions';
import { prop } from 'ramda';

import {
  reqCreatePatientOffers,
  reqUpdateOffer,
  reqGetOffer,
  reqConfirmPatientOffer,
  reqViewPatientOffer,
  reqGetOfferByCode,
  reqGenerateOffers,
  reqCreateOffer,
  reqGetGeneratedOffers,
} from 'api/offer';

export const createPatientOffers = createAsyncAction(
  'CREATE_PATIENT_OFFERS',
  (payload) => reqCreatePatientOffers(payload).then(prop('data')),
);

export const updateOffer = createAsyncAction(
  'UPDATE_OFFER',
  (payload) => reqUpdateOffer(payload).then(prop('data')),
);

export const getOffer = createAsyncAction(
  'GET_OFFER',
  (payload) => reqGetOffer(payload).then(prop('data')),
);

export const confirmPatientOffer = createAsyncAction(
  'CONFIRM_OFFER',
  (payload) => reqConfirmPatientOffer(payload).then(prop('data')),
);

export const viewPatientOffer = createAsyncAction(
  'VIEW_PATIENT_OFFER',
  (payload) => reqViewPatientOffer(payload).then(prop('data')),
);

export const getOfferByCode = createAsyncAction(
  'GET_OFFER_BY_CODE',
  (payload) => reqGetOfferByCode(payload).then(prop('data')),
);

export const generateOffers = createAsyncAction(
  'GENERATE_OFFERS',
  (payload) => reqGenerateOffers(payload).then(prop('data')),
);

export const createOffer = createAsyncAction(
  'CREATE_OFFER',
  (payload) => reqCreateOffer(payload).then(prop('data')),
);

export const getGeneratedOffers = createAsyncAction(
  'GET_GENERATED_OFFERS',
  () => reqGetGeneratedOffers().then(prop('data')),
);
