import React, { useState, useEffect } from 'react';
import {
  compose, prop, remove, propEq, filter, isEmpty,
} from 'ramda';

import StickyFooterSection from 'components/StickyFooterSection';
import Button from 'components/Button';
import Icon from 'components/Icon';

import { isAboveAllowedTotal, isBelowAllowedTotal, sumBillsTotalAmount } from 'utils/invoiceToPay';

import InputCheckbox from './InputCheckbox';
import BalanceError from './BalanceError';
import BalanceErrorPayInFull from './BalanceErrorPayInFull';
import SubmitButtons from './SubmitButtons';
import TotalAmount from './TotalAmount';
import PatientBalance from './PatientBalance';

const MultipleBills = ({
  bills: localBills,
  setIsFetching,
  updateBill,
  updateBillFailure,
  submitSuccess,
  checkBills,
  hasPayInFullRedirect,
  payInFullUri,
  providerName,
}) => {
  const [accumulator, setAccumulator] = useState(0);
  const [bills, setBills] = useState(localBills);
  const [billsToUpdate, setBillsToUpdate] = useState([]);
  const includedInOffer = propEq('includedInOffer', true);
  const notIncludedInOffer = propEq('includedInOffer', false);
  const billsNotIncludedInOffer = filter(notIncludedInOffer)(bills);
  const isAboveBalance = isAboveAllowedTotal(accumulator);
  const isBelowBalance = isBelowAllowedTotal(accumulator);
  const isDisabled = !accumulator || isAboveBalance || isBelowBalance;
  const hasBalanceError = isAboveBalance || isBelowBalance;

  const checkingBills = async () => {
    setIsFetching(true);
    await Promise.all(billsNotIncludedInOffer.map(({ billId }) =>
      updateBill({ billId, includedInOffer: true }).catch(updateBillFailure)));
    setIsFetching(false);
  };

  const handleOnChange = (e) => {
    const { target: { id, checked } } = e;

    const updatedBills = bills.map((bill) => {
      if (id === bill.billId) return { ...bill, includedInOffer: checked };
      return bill;
    });

    const includedBill = billsToUpdate.find((item) => item.id === id);

    if (includedBill) {
      const index = billsToUpdate.findIndex((item) => item.id === id);
      const result = remove(index, 1, billsToUpdate);
      setBillsToUpdate(result);
    } else {
      setBillsToUpdate([...billsToUpdate, { id, checked }]);
    }

    setBills(updatedBills);
  };

  const handleOnClick = async () => {
    setIsFetching(true);

    await Promise.all(billsToUpdate.map((bill) =>
      updateBill({ billId: bill.id, includedInOffer: bill.checked })
        .catch(updateBillFailure)));

    submitSuccess();
  };

  useEffect(() => {
    setBills(localBills);
  }, [localBills]);

  useEffect(() => {
    if (checkBills && billsNotIncludedInOffer.length > 0) {
      checkingBills();
    } else {
      setIsFetching(false);
    }
  }, [checkBills]);

  useEffect(() => {
    if (!isEmpty(bills)) {
      const acc = compose(sumBillsTotalAmount, filter(includedInOffer))(bills);
      setAccumulator(acc);
    }
  }, [bills]);

  const isPayInFull = hasPayInFullRedirect && !!payInFullUri;

  const [billsDetailsOpen, setBillsDetailsOpen] = useState(bills.map(() => false));

  const setIsDetailsOpen = (index, newState) => {
    const result = billsDetailsOpen.map((item, idx) => {
      if (idx === index) return newState;
      return item;
    });

    setBillsDetailsOpen(result);
  };

  const isCollapsed = billsDetailsOpen.every((item) => !item);

  const toggleAll = () => {
    if (isCollapsed) return setBillsDetailsOpen(billsDetailsOpen.map(() => true));
    return setBillsDetailsOpen(billsDetailsOpen.map(() => false));
  };

  return (
    <div data-testid="multiple-bills">
      <div className="px-4 mb-4">
        <div className="text-right mb-2">
          <Button
            type="button"
            className="py-0 px-4 d-inline-flex align-items-center text-dodger-blue text-smaller border-0 mr-1"
            onClick={toggleAll}
            data-testid="expand-collapse-all"
            eventName={isCollapsed ? 'expand-all' : 'collapse-all'}
          >
            <span className="text-underline mr-2">
              {isCollapsed ? 'Expand All' : 'Collapse All'}
            </span>
            <Icon
              name={isCollapsed ? 'caret-down-alt' : 'caret-up-alt'}
              className="text-xsmall"
            />
          </Button>
        </div>
        {bills.map((bill, index) => (
          <InputCheckbox
            key={prop('billId')(bill)}
            index={index}
            bill={bill}
            onChange={handleOnChange}
            isDetailsOpen={billsDetailsOpen[index]}
            setIsDetailsOpen={setIsDetailsOpen}
          />
        ))}
      </div>
      <div className="px-6">
        <TotalAmount bills={bills} />
        <PatientBalance accumulator={accumulator} hasBalanceError={hasBalanceError} />
        {isPayInFull
          ? <BalanceErrorPayInFull accumulator={accumulator} />
          : <BalanceError accumulator={accumulator} />}
      </div>
      <StickyFooterSection className="invoice-resume">
        <SubmitButtons
          buttonText="Continue"
          onSubmit={handleOnClick}
          isDisabled={isDisabled}
          isPayInFull={isPayInFull}
          payInFullUri={payInFullUri}
          totalAmount={accumulator}
          providerName={providerName}
        />
      </StickyFooterSection>
    </div>
  );
};

export default MultipleBills;
