import { compose } from 'redux';
import { connect } from 'react-redux';
import { prop } from 'ramda';

import { getUser } from 'actions/user';
import { getPatient } from 'actions/patient';
import { getApplication } from 'actions/application';
import { getBill } from 'actions/bill';
import { getOffer } from 'actions/offer';
import { getOfferPayment } from 'actions/paymentMade';

import withOffer from './withOffer';

const mapStateToProps = ({
  user,
  patient,
  application: {
    getFromVaultStatus, getApplicationStatus, currentView, applicationId,
  },
  bill,
  offer,
  paymentMade,
}) => ({
  user,
  patient,
  getFromVaultStatus,
  getApplicationStatus,
  bill,
  offer,
  paymentMadeStatus: prop('status')(paymentMade),
  currentView,
  applicationId,
});

const mapDispatchToProps = {
  getUser,
  getPatient,
  getApplication,
  getBill,
  getOffer,
  getOfferPayment,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withOffer,
);
