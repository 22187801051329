import { apiCall } from 'libs/authenticationService';
import { PUT, GET } from 'libs/httpMethods';
import { UPDATE_BILL, GET_BILL, GET_BILL_FILES } from 'libs/apiUrls';

export const reqUpdateBill = ({ billId, ...rest }) =>
  apiCall(PUT, UPDATE_BILL(billId), { data: rest });

export const reqGetBill = ({ patientId, providerId }) =>
  apiCall(GET, GET_BILL({ patientId, providerId }));

export const reqGetBillFiles = (billId) =>
  apiCall(GET, GET_BILL_FILES(billId));
