/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

const Content = ({ content }) => {
  if (content instanceof Array) {
    return (
      <ul className="info-bubble__list">
        {content.map((item, index) => <li key={index}>{item}</li>)}
      </ul>
    );
  }

  return <p className="mb-0">{content}</p>;
};

const InfoBubble = ({ title, content, className }) => (
  <div className={`info-bubble ${className}`}>
    {title && <h1 className="info-bubble__title">{title}</h1>}
    <div className="info-bubble__content">
      <Content content={content} />
    </div>
  </div>
);

InfoBubble.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.element]).isRequired,
};

InfoBubble.defaultProps = {
  title: '',
  className: '',
};

export default InfoBubble;
