import { connect } from 'react-redux';

import {
  createPlaidPaymentMethod,
  createManualPaymentMethod,
  logPlaidEvent,
} from 'actions/paymentMethod';

import { updateApplication } from 'actions/application';
import { confirmPatientOffer } from 'actions/offer';

import ConnectBank from './ConnectBank';

const mapStateToProps = ({
  user: { firstName },
  offer,
  application: { applicationId, currentView },
  patient: { patientId },
  bill: { bills },
}) => ({
  firstName,
  offer,
  applicationId,
  currentView,
  patientId,
  bills,
});

const mapDispatchToProps = {
  createPlaidPaymentMethod,
  createManualPaymentMethod,
  logPlaidEvent,
  updateApplication,
  confirmPatientOffer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectBank);
