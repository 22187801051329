import React, { useState } from 'react';
import { compose, split, last } from 'ramda';
import { isBrowser } from 'react-device-detect';

import Currency from 'components/Currency';
import Button from 'components/Button';
import Link from 'components/Link';

import PhoneNumberModal from './PhoneNumberModal';

const getPhoneNumberFromURL = (url) => {
  const isPhoneNumber = url && url.includes('tel:');

  if (isPhoneNumber) {
    return compose(last, split(':'))(url);
  }

  return '';
};

const SubmitButtons = ({
  buttonText,
  onSubmit,
  isDisabled,
  isPayInFull,
  payInFullUri,
  totalAmount,
  providerName,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  if (isPayInFull) {
    const phoneNumber = getPhoneNumberFromURL(payInFullUri);

    return (
      <div className="row small-gutters mb-md-0">
        <div className="col-6 text-center">
          {isBrowser && phoneNumber ? (
            <>
              <Button
                type="alice-blue-alt"
                className="btn-block mb-2"
                disabled={totalAmount === 0}
                data-cy="pay-in-full"
                onClick={() => setIsOpen(true)}
                eventName="pay-in-full"
              >
                Pay in full
              </Button>
              <PhoneNumberModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                phoneNumber={phoneNumber}
                providerName={providerName}
                phoneNumberURL={payInFullUri}
              />
            </>
          ) : (
            <Link
              type="alice-blue-alt"
              className="btn btn-alice-blue-alt btn-block mb-2"
              disabled={totalAmount === 0}
              href={payInFullUri}
              data-cy="pay-in-full"
              eventName="pay-in-full"
            >
              Pay in full
            </Link>
          )}
          <span className="text-smaller text-comet">
            <Currency
              amount={totalAmount}
              suffix=" due today"
            />
          </span>
        </div>
        <div className="col-6 text-center">
          <Button
            type="primary mb-2"
            className="btn-block"
            disabled={isDisabled}
            onClick={onSubmit}
            data-cy="pay-over-time"
            eventName="pay-over-time"
          >
            Pay over time
          </Button>
          <span className="text-smaller text-comet">No interest or fees</span>
        </div>
      </div>
    );
  }

  return (
    <div className="funnel-card__form">
      <Button
        type="primary"
        className="btn-block"
        disabled={isDisabled}
        data-cy="bills-continue"
        onClick={onSubmit}
        eventName="main-cta"
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default SubmitButtons;
