import React from 'react';
import Currency from 'components/Currency';

import {
  BALANCE_MAX,
  BALANCE_MIN,
  isAboveAllowedTotal,
  isBelowAllowedTotal,
} from 'utils/invoiceToPay';

const BalanceError = ({ accumulator }) => {
  const isAboveBalance = isAboveAllowedTotal(accumulator);
  const isBelowBalance = isBelowAllowedTotal(accumulator);
  const show = isAboveBalance || isBelowBalance;

  const message = () => {
    if (isAboveBalance) return 'Patient balance is above provider limit,';
    if (isBelowBalance) return 'Patient balance is below provider minimum,';
    return '';
  };

  const balanceLimit = () => {
    if (isAboveBalance) return BALANCE_MAX;
    if (isBelowBalance) return BALANCE_MIN;
    return 0;
  };

  if (show) {
    return (
      <div className="invalid-feedback">
        <p className="text-center mb-0" data-cy="bills-balance-error">
          {message()}
          {' '}
          <Currency
            amount={balanceLimit()}
          />
        </p>
      </div>
    );
  }

  return null;
};

export default BalanceError;
