import React from 'react';

import ErrorTULayout from 'components/ErrorTULayout';

const BankruptcyExists = () => (
  <ErrorTULayout>
    <p className="mb-0">
      Unfortunately, we cannot accept individuals
      <br />
      that are currently undergoing bankruptcy. Once
      <br />
      the process is complete, we can further assist
      <br />
      you.
    </p>
  </ErrorTULayout>
);

export default BankruptcyExists;
