/* eslint-disable no-console */
import decode from 'jwt-decode';
import {
  path, equals, propEq, anyPass,
} from 'ramda';
import { v4 as uuidv4 } from 'uuid';

import history from 'browserHistory';

import getErrorMessage from 'utils/getErrorMessage';
import isDemo from 'utils/isDemo';
import generatePath from 'utils/generatePath';

import http from './http';
import { errorMessage } from './views';

// Token string name
const TOKEN_NAME = 'token';

export const SESSION_ID = 'SESSION_ID';

export const getToken = () =>
  // Retrieves the user token from sessionStorage
  sessionStorage.getItem(TOKEN_NAME);

export const loggedIn = () => {
  // Checks if there is a saved token and it's still valid
  const token = getToken(); // Getting token from sessionstorage
  return !!token;
};

export const setToken = (token) => {
  // Saves user token to sessionStorage
  sessionStorage.setItem(TOKEN_NAME, token);
};

export const logout = () => {
  // Clear user token from sessionStorage
  sessionStorage.removeItem(TOKEN_NAME);
  sessionStorage.removeItem(SESSION_ID);
};

export const getProfile = () => {
  const token = getToken();
  let profile;
  try {
    profile = decode(token);
  } catch (e) { console.log(e); }

  return profile;
};

const getSessionId = () => {
  // Retrieves the session id from sessionStorage
  const currentSessionId = sessionStorage.getItem(SESSION_ID);
  if (currentSessionId) return currentSessionId;
  const newSessionId = uuidv4();
  sessionStorage.setItem(SESSION_ID, newSessionId);
  return newSessionId;
};

const checkStatus = (response) => {
  // raises an error in case response status is not a success
  if (response.status >= 200 && response.status < 300) {
    // Success status lies between 200 to 300
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
};

const unauthorizedHandler = (error) => {
  const { response } = error;
  const statusCode = path(['response', 'status'])(error);

  if (equals(statusCode, 406)) {
    history.push(generatePath('app', errorMessage), { errorMessage: getErrorMessage(error) });
  }

  if (response) {
    const is401 = propEq('status', 401);
    const is403 = propEq('status', 403);
    const shouldLogout = anyPass([is401, is403])(response);

    if (shouldLogout) {
      logout();
    }

    const err = new Error(response.statusText);
    err.response = response;
    throw err;
  }

  if (error.message === 'Network Error') {
    throw error;
  }
};

export const apiCall = (method, url, options) => {
  // performs api calls sending the required authentication headers
  const sessionId = getSessionId();
  const requestId = uuidv4();

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-session-id': sessionId || undefined,
    'request-id': requestId || undefined,
    'x-request-id': requestId || undefined,
  };

  // Setting offerId for Demo
  if (isDemo() && loggedIn()) {
    const persistRoot = sessionStorage.getItem('persist:root');
    if (persistRoot) {
      const parsedPersistRoot = JSON.parse(persistRoot);
      headers['X-Demo-Offer-Id'] = parsedPersistRoot && parsedPersistRoot.offer ? (JSON.parse(parsedPersistRoot.offer).offerId || '') : '';
    }
  }

  // Setting Authorization header if logged in
  // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
  if (loggedIn()) {
    headers.Authorization = `Bearer ${getToken()}`;
  }

  return http[method](url, {
    headers,
    ...options,
  })
    .then(checkStatus)
    .then((response) => response)
    .catch(unauthorizedHandler);
};
