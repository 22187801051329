import { connect } from 'react-redux';

import SendCode from './SendCode';

const mapStateToProps = () => ({ });

const mapDispatchToProps = { };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SendCode);
