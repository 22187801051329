import React from 'react';
import ReactModal from 'react-modal';
import NumberFormat from 'react-number-format';

import Button from 'components/Button';
import Link from 'components/Link';

const PhoneNumberModal = ({
  isOpen, setIsOpen, phoneNumber, providerName, phoneNumberURL,
}) => {
  const onCloseModal = () => setIsOpen(!isOpen);

  return (
    <ReactModal
      isOpen={isOpen}
      className="main-modal main-modal--small"
      overlayClassName="main-modal__overlay"
      contentLabel="Phone number Modal"
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      closeTimeoutMS={300}
    >
      <div className="main-modal__content">
        <div className="p-6">
          <h1 className="text-tuna font-weight-normal text-xlarger mb-5">
            Call
            {' '}
            {providerName}
            {' '}
            to pay in full
          </h1>
          <p className="text-storm-gray font-weight-bold mb-2">
            Provider Phone Number
          </p>
          <p className="text-larger text-tuna font-weight-bold mb-6">
            <NumberFormat
              value={phoneNumber}
              displayType="text"
              format="###-###-#####"
            />
          </p>
          <div>
            <Link
              className="btn btn-primary px-7 mr-4 font-weight-bold"
              href={phoneNumberURL}
              eventName="call-pay-in-full"
            >
              Call
              {' '}
              <NumberFormat
                value={phoneNumber}
                displayType="text"
                format="###-###-#####"
              />
            </Link>
            <Button
              type="link"
              className="font-weight-bold px-7 text-decoration-none"
              onClick={onCloseModal}
              eventName="dismiss-pay-in-full"
            >
              Dismiss
            </Button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default PhoneNumberModal;
