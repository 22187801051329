import React from 'react';
import { Formik, Form } from 'formik';
import { propOr } from 'ramda';

import { ErrorMessage, PhoneInput } from 'components/Fields';
import Button from 'components/Button';

import { phoneForm } from 'utils/validationSchemas';

const PhoneForm = ({ onSubmit }) => {
  const initialValues = {
    phone: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={phoneForm}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, setFieldValue, errors }) => {
        const errorMessage = propOr(false, 'phone')(errors);

        return (
          <Form data-testid="phone-form">
            <div className="input-group input-group-round">
              <PhoneInput
                setField={setFieldValue}
                id="phone"
                type="tel"
                name="phone"
                className="form-control form-control--without-label pl-md-7"
                placeholder="Enter your phone number"
                data-cy="identity-contact"
                data-testid="phone-form-input"
              />
              <div className="input-group-append">
                <Button
                  className="px-5 py-3"
                  type="outline-primary"
                  disabled={isSubmitting}
                  data-cy="identity-send-contact"
                  data-testid="phone-form-button"
                  isSubmit
                  eventName="send"
                >
                  Send
                </Button>
              </div>
            </div>
            {errorMessage && (
              <div className="pt-4">
                <ErrorMessage error={errorMessage} />
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default PhoneForm;
