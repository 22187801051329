import React from 'react';

import ErrorLayout from 'components/ErrorLayout';
import Icon from 'components/Icon';
import Link from 'components/Link';

const ErrorEnroll = ({ history }) => (
  <ErrorLayout>
    <div className="mb-6">
      <Icon name="band-aids" className="error-page__icon mb-5 mm-md-6 text-pizazz" />
      <h1 className="h2 mb-5">Apologies</h1>
      <p className="text-center mb-0" data-cy="enrollerror-title">
        We cannot enroll you in an online payment plan.
      </p>
      <p className="text-center mb-5">
        Common reasons:
      </p>
      <ul className="text-left text-small pl-4" data-cy="enrollerror-reasons">
        <li>Double check the personal information you provided</li>
        <li>Double check your income</li>
        <li>
          If you froze your credit, temporarily unfreeze it with
          {' '}
          <Link
            href="https://www.transunion.com/credit-freeze"
            target="_blank"
            rel="noreferrer"
            eventName="TransUnion"
          >
            TransUnion
          </Link>
        </li>
        <li>
          Other circumstances e.g. unpaid payment plan balance, current bankruptcy
        </li>
      </ul>
    </div>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <Link
      className="btn btn-primary btn-block text-decoration-none px-10 py-3"
      onClick={history.goBack}
      data-cy="enrollerror-continue"
      eventName="go-back"
    >
      Go Back
    </Link>
  </ErrorLayout>
);

export default ErrorEnroll;
