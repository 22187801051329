import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  includes,
  mapObjIndexed,
  trim,
  pathOr,
  isEmpty,
  compose,
  join,
  values,
  omit,
  prop,
} from 'ramda';
import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Button from 'components/Button';
import { Input } from 'components/Fields';
import Loading from 'components/Loading';

import { mrnForm } from 'utils/validationSchemas';
import getErrorMessage from 'utils/getErrorMessage';
import generatePath from 'utils/generatePath';
import getSubdomain from 'utils/getSubdomain';
import { guarantorValidation } from 'utils/guarantors';

import { error as errorPage, errorVault } from 'libs/views';

const MRN = ({
  funnelType,
  history,
  getFromVault,
  location,
  sendSlackMessage,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const providerName = getSubdomain(window);

  const initialValues = {
    payzenMedicalRecordNumber: '',
  };

  const onFailure = ({ data, actions }) => (error) => {
    actions.setSubmitting(false);
    setIsFetching(false);
    const errorMessage = getErrorMessage(error);
    const patientInfo = compose(join(', '), values)(data);

    sendSlackMessage({
      type: 'vault.failure',
      title: 'Failure - Get Info From Vault',
      provider: providerName,
      lastName: data.lastName,
      dateOfBirth: data.dateOfBirth,
      patientInfo,
      errorMessage,
    });

    if (includes('A User with this data was not found', errorMessage)) {
      const errorState = {
        type: 'vault',
        patientInfo: data,
        provider: providerName,
      };

      return history.replace(generatePath(funnelType, errorVault), errorState);
    }

    return history.replace(generatePath(funnelType, errorPage));
  };

  const onSuccess = (actions) => (response) => {
    const user = pathOr([], ['action', 'payload', 'user'])(response);
    setIsFetching(false);
    actions.setSubmitting(false);
    const patientInfo = compose(join(', '), values, omit(['middleName', 'suspendedUntil', 'typeName']))(user);

    sendSlackMessage({
      type: 'vault.success',
      title: 'Success - Get Info From Vault',
      provider: providerName,
      name: `${user.firstName} ${user.lastName}`,
      phone: user.phone,
      patientInfo,
    });

    return guarantorValidation(response, funnelType, history);
  };

  const handleSubmit = (data, actions) => {
    setIsFetching(true);
    const { state } = location;

    const obj = {
      ...mapObjIndexed(trim)(data),
      ...state,
    };

    return getFromVault(obj)
      .then(onSuccess(actions))
      .catch(onFailure({ data, actions }));
  };

  return (
    <Layout>
      <Card
        className="mt-2"
        progressBarValue={0.7352941176}
        content={(
          <div className="funnel-card__wrapper pb-8">
            {isFetching && (
              <div className="connect-bank__loader text-center">
                <Loading />
              </div>
            )}
            <div className="funnel-card__form text-center mb-5">
              <h1 className="text-larger text-sm-xlarger font-weight-normal" data-cy="mrn-title">
                We need more information
              </h1>
              <p className="mb-0 px-2xs-4" data-cy="mrn-description">
                Please enter your Medical Record Number.
                You should be able to locate it on your bill
              </p>
            </div>
            <div className="funnel-card__form">
              <Formik
                initialValues={initialValues}
                validationSchema={mrnForm}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, values: formData }) => {
                  const isDisabled = isSubmitting || compose(isEmpty, prop('payzenMedicalRecordNumber'))(formData);

                  return (
                    <Form>
                      <div className="mb-5">
                        <Field
                          id="payzenMedicalRecordNumber"
                          type="text"
                          name="payzenMedicalRecordNumber"
                          label="Medical Record Number"
                          component={Input}
                          data-cy="mrn-number"
                        />
                      </div>
                      <Button
                        type="primary"
                        className="btn-block"
                        isSubmit
                        disabled={isDisabled}
                        data-cy="mrn-continue"
                        eventName="main-cta"
                      >
                        Continue
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
      />
    </Layout>
  );
};

export default MRN;
