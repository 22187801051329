import PoweredByIcon from 'components/PowerByIcon';
import React from 'react';

const MonthlyPaymentsMessage = ({
  poweredByText,
  poweredByIconUrl,
  'data-testid': dataTestId,
}) => (
  <>
    <span className="text-smaller" data-testid={dataTestId}>
      Monthly Payments by
    </span>
    <PoweredByIcon poweredByText={poweredByText} poweredByIconUrl={poweredByIconUrl} />
  </>
);

export default MonthlyPaymentsMessage;
