import {
  pathOr,
  isEmpty,
  path,
  filter,
} from 'ramda';

import { redirectToApp } from 'utils/redirectTo';
import generatePath from 'utils/generatePath';
import getLower from 'utils/getLower';

import { selectUser } from 'libs/views';
import funnelViews from 'libs/funnelViews';

export const isSamePerson = (user, guarantor) => (
  getLower(user.firstName) === getLower(guarantor.firstName)
  && getLower(user.lastName) === getLower(guarantor.lastName)
);

export const filterGuarantors = (user, guarantors) =>
  guarantors.filter((guarantor) => !isSamePerson(user, guarantor));

export const cleanGuarantors = (guarantors) =>
  guarantors.filter((guarantor) => guarantor.firstName && guarantor.lastName);

export const guarantorValidation = (response, funnelType, history) => {
  const { currentView } = path(['value', 'application'])(response);
  const bills = pathOr([], ['value', 'bills'])(response);
  const user = pathOr([], ['value', 'user'])(response);
  const nonAcceptedBills = filter((item) => item.status !== 'BILL_ACCEPTED')(bills);
  const viewToShow = isEmpty(nonAcceptedBills) ? funnelViews.planExists : currentView;
  const guarantors = pathOr([], ['value', 'guarantors'])(response);

  const filteredGuarantors = filterGuarantors(user, cleanGuarantors(guarantors));

  const isSameGuarantor = isEmpty(filteredGuarantors);

  if (isSameGuarantor) {
    return redirectToApp({
      history, funnelType, currentView: viewToShow, checkBills: true,
    });
  }

  return history.push(generatePath(funnelType, selectUser), { checkBills: true });
};
