import React, { useEffect } from 'react';
import {
  path, isNil, toLower, pathOr,
} from 'ramda';
import queryString from 'qs';

import getSubdomain from 'utils/getSubdomain';
import { redirectToPartner, redirectToApp } from 'utils/redirectTo';
import generatePath from 'utils/generatePath';

import { setToken, getProfile } from 'libs/authenticationService';
import { billInfo, paymentPlanBank, maintenance } from 'libs/views';

const hackUseEffect = useEffect;

const RedirectToFunnel = (Component) => (props) => {
  const providerName = getSubdomain(window);

  const history = path(['history'])(props);
  const search = path(['location', 'search'])(props);
  const hash = path(['location', 'hash'])(props);
  const queryParam = queryString.parse(search, { ignoreQueryPrefix: true });
  const token = path(['token'])(queryParam);
  const offerId = path(['offerId'])(queryParam);
  const providerId = path(['providerId'])(queryParam);
  const code = path(['c'])(queryParam);
  const applicationCode = path(['ac'])(queryParam);

  const redirectToMyPayzen = () => window.location.replace('https://payzen.com/');

  const redirectHandler = (customRoute = false) =>
    props.getProviderConfig({ subdomain: providerName })
      .then((response) => {
        const maintenanceMode = pathOr(false, ['value', 'maintenanceMode'])(response);
        if (maintenanceMode) return history.replace(generatePath('app', maintenance));
        if (customRoute) return history.replace({ pathname: billInfo.route[0], search, hash });
        return history.replace(billInfo.path);
      })
      .catch(() => redirectToMyPayzen());

  const getApplicationSuccess = (response) => {
    const currentView = path(['value', 'currentView'])(response);
    const type = path(['value', 'type'])(response);
    const isTypeNil = isNil(type);
    const redirect = isTypeNil ? redirectToApp : redirectToMyPayzen();

    return redirect({
      history,
      funnelType: isTypeNil ? 'app' : toLower(type),
      currentView,
      resumeFromLink: true,
    });
  };

  const getApplicationByCodeSuccess = (response) => {
    setToken(path(['value', 'auth', 'token'])(response));
    const profile = getProfile();
    const patientId = path(['patient', 'patientId'])(profile);
    const applicationId = path(['applicationId'])(profile);

    if (isNil(profile)) {
      return redirectHandler();
    }

    return props.getApplication({ patientId, applicationId })
      .then(getApplicationSuccess)
      .catch(redirectHandler);
  };

  const redirectToBankFunnel = () => history.replace(paymentPlanBank.route[0], {
    resumeApp: true,
  });

  const redirectToBillInfo = () => history.replace(billInfo.route[0]);

  hackUseEffect(() => {
    if (code) {
      props.getOfferByCode(code)
        .then(redirectToBankFunnel)
        .catch(redirectToBillInfo);
    }

    if (applicationCode) {
      props.getFromVault({ code: applicationCode })
        .then(getApplicationByCodeSuccess)
        .catch(redirectHandler);
    }

    if (token) {
      setToken(token);
      const profile = getProfile();
      const patientId = path(['patient', 'patientId'])(profile);
      const applicationId = path(['applicationId'])(profile);

      if (isNil(profile)) {
        return redirectHandler();
      }

      if (applicationId) {
        props.getApplication({ patientId, applicationId })
          .then(getApplicationSuccess)
          .catch(redirectHandler);
      } else {
        sessionStorage.setItem('offerId', offerId);
        sessionStorage.setItem('providerId', providerId);

        redirectToMyPayzen();

        redirectToPartner({
          history,
          funnelType: 'partner',
          currentView: 'FUNNEL_CONNECT_BANK',
          resumeFromLink: true,
          offerId,
          providerId,
        });
      }
    } else {
      redirectHandler(true);
    }

    return null;
  }, []);

  return <Component {...props} />;
};

export default RedirectToFunnel;
