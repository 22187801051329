import { createReducer } from '@reduxjs/toolkit';

import { clearStore } from 'actions/application';
import { getFromMychart } from 'actions/epic';

export const initialState = {
  isLoaded: false,
  multiOrgId: null,
  providers: [],
  accounts: [],
  showOrganizationView: false,
  epic: {
    patientInfo: null,
    guarantorsAccountDemographics: null,
  },
};

export default createReducer(initialState, {
  [String(getFromMychart.pending)]: (state) => ({
    ...state,
    isLoaded: false,
  }),
  [String(getFromMychart.fulfilled)]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoaded: true,
  }),
  [String(getFromMychart.rejected)]: (state) => ({
    ...state,
    isLoaded: false,
  }),

  [String(clearStore)]: () => ({
    ...initialState,
  }),
});
