import { connect } from 'react-redux';

import { getProviderConfig } from 'actions/provider';

import ErrorTULayout from './ErrorTULayout';

const mapStateToProps = ({
  provider,
  application: { isEpicApp },
}) => ({
  provider,
  isEpicApp,
});

const mapDispatchToProps = {
  getProviderConfig,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorTULayout);
