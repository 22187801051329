import { createAsyncAction, createAction } from 'redux-promise-middleware-actions';
import { prop } from 'ramda';

import {
  reqGetFromVault,
  reqGetFromInvite,
  reqUpdateUser,
  reqGetUser,
  reqVerificationCode,
} from 'api/user';

export const getFromVault = createAsyncAction(
  'GET_FROM_VAULT',
  (payload) => reqGetFromVault(payload).then(prop('data')),
);

export const getFromInvite = createAsyncAction(
  'GET_FROM_INVITE',
  (payload) => reqGetFromInvite(payload).then(prop('data')),
);

export const updateUser = createAsyncAction(
  'UPDATE_USER',
  (payload) => reqUpdateUser(payload).then(prop('data')),
);

export const getUser = createAsyncAction(
  'GET_USER',
  () => reqGetUser().then(prop('data')),
);

export const verificationCode = createAsyncAction(
  'VERIFICATION_CODE',
  (payload) => reqVerificationCode(payload).then(prop('data')),
);

export const setIsSmsLink = createAction('SET_IS_SMS_LINK', (isSmsLink) => isSmsLink);
