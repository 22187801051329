import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  propOr,
  path,
  equals,
} from 'ramda';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Button from 'components/Button';
import Loading from 'components/Loading';

import generatePath from 'utils/generatePath';

import {
  financialInfo,
  error as errorPage,
  paymentPlan,
} from 'libs/views';

import Counter from './Counter';
import Checkbox from './Checkbox';

const Banner = ({ isAppFunnel }) => {
  if (!isAppFunnel) {
    return (
      <>
        Your household size helps us determine financial
        assistance eligibility with your provider.
      </>
    );
  }

  return '';
};

const Household = ({
  funnelType,
  history,
  updatePatient,
  patient,
  offer,
  application,
  viewPatientOffer,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [dirtyForm, setDirtyForm] = useState(false);
  const isAppFunnel = equals(funnelType, 'app');

  const onSetDirtyForm = (dirty) => {
    if (!dirtyForm && dirty) setDirtyForm(true);
    if (dirtyForm && !dirty) setDirtyForm(false);
  };

  const onFailure = () => history.push(generatePath(funnelType, errorPage));
  const onSuccess = () => {
    // we need to call this API to move patient to the next list in ActiveCampaign
    // (we can do this ONLY AFTER the patient has seen the offer)
    const offerId = path(['meta', 'offerId'], application) || path(['offerId'], offer);
    viewPatientOffer({
      patientId: patient.patientId,
      offerId,
    }).catch(() => {});
    history.push(generatePath(funnelType, paymentPlan));
  };

  const onSubmit = (data) => {
    setIsFetching(true);

    if (!dirtyForm) return onSuccess();

    return updatePatient(data)
      .then(onSuccess)
      .catch(onFailure);
  };

  const initialValues = {
    householdSize: propOr(0, 'householdSize')(patient),
    affectedByCoronavirus: propOr(false, 'affectedByCoronavirus')(patient),
  };

  return (
    <Layout
      backLink={generatePath(funnelType, financialInfo)}
    >
      <Card
        progressBarValue={18.3823529412}
        content={(
          <div className="funnel-card__wrapper d-flex flex-column financial-info">
            {isFetching && (
              <div className="connect-bank__loader text-center">
                <Loading />
              </div>
            )}
            <h1 className="text-center h4 font-weight-light mb-9">
              How Many People
              <br />
              Live In Your Household?
            </h1>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ dirty, values }) => {
                onSetDirtyForm(dirty);

                return (
                  <Form>
                    <div className="text-center mb-7">
                      <Field
                        id="householdSize"
                        name="householdSize"
                        component={Counter}
                      />
                    </div>
                    <div className="bg-alabaster-alt py-4 px-4 px-md-5 mx-md-5 mb-6">
                      <div className="row small-gutters align-items-center">
                        <div className="col">
                          <p className="text-tuna mb-0">
                            Was your household income
                            <br />
                            negatively impacted by COVID-19?
                          </p>
                        </div>
                        <div className="col-auto">
                          <Field
                            id="affectedByCoronavirus"
                            name="affectedByCoronavirus"
                            component={Checkbox}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="funnel-card__form w-100 px-4">
                      <Button
                        type="primary"
                        className="btn btn-primary btn-block"
                        isSubmit
                        disabled={equals(values.householdSize, 0)}
                      >
                        Continue
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
        banner={<Banner isAppFunnel={isAppFunnel} />}
      />
    </Layout>
  );
};

export default Household;
