import { connect } from 'react-redux';

import { updatePatient } from 'actions/patient';
import { createPatientOffers, getOffer } from 'actions/offer';
import { updateApplication } from 'actions/application';

import FinancialInfo from './FinancialInfo';

const mapStateToProps = ({
  patient,
  bill: { bills },
  application: { applicationId, currentView },
  offer: { offerId, totalAmount: offerTotalAmount },
  user: { firstName },
}) => ({
  patient,
  bills,
  applicationId,
  currentView,
  offerId,
  offerTotalAmount,
  firstName,
});

const mapDispatchToProps = {
  updatePatient, createPatientOffers, updateApplication, getOffer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FinancialInfo);
