import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  compose, sum, filter, pluck, propEq,
} from 'ramda';

import { ssnForm } from 'utils/validationSchemas';
import generatePath from 'utils/generatePath';

import { paymentPlan, financialInfo, errorIdentity } from 'libs/views';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Button from 'components/Button';
import { Input } from 'components/Fields';
import Loading from 'components/Loading';

import imageShield from 'assets/images/mobile/shield.svg';
import getErrorMessage from 'utils/getErrorMessage';
import { getTUErrorPage } from 'utils/redirectTo';

const initialValues = {
  ssnLast4Digits: '',
};

const ConfirmSSN = ({
  history,
  user,
  bills,
  generateOffers,
}) => {
  const [isFetching, setIsFetching] = useState(false);

  const includedInOffer = propEq('includedInOffer', true);
  const statusPending = propEq('status', 'BILL_PENDING');
  const validOffers = compose(filter(includedInOffer), filter(statusPending));

  const totalBalance = compose(sum, pluck('totalAmount'), validOffers)(bills);
  const selectedBills = compose(pluck('billId'), validOffers)(bills);

  const submitSuccess = () => history.push(generatePath('app', paymentPlan));

  const onSubmitFailure = (error) => {
    const errorMessage = getErrorMessage(error);

    setIsFetching(false);

    const TUErrorPage = getTUErrorPage(errorMessage);

    if (TUErrorPage) return history.push(generatePath('app', TUErrorPage));

    return history.push(generatePath('app', errorIdentity));
  };

  const handleSubmit = ({ ssnLast4Digits }) => {
    setIsFetching(true);

    return generateOffers({
      ssnLast4Digits,
      totalBalance,
      bills: selectedBills,
      firstBillingDate: new Date(),
    })
      .then(submitSuccess)
      .catch(onSubmitFailure);
  };

  return (
    <Layout backLink={generatePath('app', financialInfo)}>
      <Card
        progressBarValue={27.2058823529}
        content={(
          <div className="funnel-card__wrapper">
            {isFetching && (
              <div className="financial-info__loader text-center">
                <Loading />
              </div>
            )}
            <div className="text-center">
              {`Thanks ${user.firstName}!`}
            </div>
            <h1 className="text-center h4 font-weight-light mb-5">Let’s Confirm It’s You</h1>
            <div className="funnel-card__form">
              <div className="text-center">
                <p className="mb-9">Please provide the last 4 digits of your your social security number so we can verify it’s you, and get the best offer for you.</p>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={ssnForm}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="mb-md-10">
                    <div className="row no-gutters mb-5">
                      <div className="col mr-1 mr-3xs-4 pr-3xs-1">
                        <div className="form-control text-center">***</div>
                      </div>
                      <div className="col-2 mr-1 mr-3xs-4 pr-3xs-1">
                        <div className="form-control text-center">**</div>
                      </div>
                      <div className="col-5">
                        <Field
                          id="ssnLast4Digits"
                          type="text"
                          name="ssnLast4Digits"
                          component={Input}
                        />
                      </div>
                    </div>
                    <Button type="primary" className="btn-block" isSubmit disabled={isSubmitting}>Continue</Button>
                  </Form>
                )}
              </Formik>
              <div className="text-center d-none d-md-block">
                <p className="text-small">
                  Your privacy and security is our number one priority. We keep your information
                  {' '}
                  <a href="#link">safe and secure.</a>
                </p>
              </div>
            </div>
          </div>
        )}
        banner={(
          <div className="media align-items-center">
            <img src={imageShield} alt="" className="mr-4" />
            <div className="media-body">
              <p className="mb-0">Please help us verify your personal identity.</p>
            </div>
          </div>
        )}
      />
    </Layout>
  );
};

export default ConfirmSSN;
