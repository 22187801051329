import generatePath from 'utils/generatePath';

import funnelViews from 'libs/funnelViews';
import {
  uploadBill,
  personalInfo,
  paymentPlan,
  success,
  invoiceToPay,
  paymentPlanPartnerResume,
  planExists,
  errorNoCreditRecordFound,
  errorCreditFreeze,
  errorOFACHit,
  errorBankruptcyExists,
} from 'libs/views';
import { includes } from 'ramda';

const resumeApp = true;

export const redirectToApp = ({
  history, funnelType, currentView, resumeFromLink = false, checkBills = false,
}) => {
  switch (currentView) {
    case funnelViews.bills:
    case funnelViews.personalInfo:
    case funnelViews.financialInfo: {
      return history.push(generatePath(funnelType, invoiceToPay), {
        resumeFromLink, resumeApp, checkBills,
      });
    }
    case funnelViews.offer:
    case funnelViews.connectBank: {
      return history.push(generatePath(funnelType, paymentPlan), { resumeFromLink, resumeApp });
    }
    case funnelViews.success: {
      return history.push(generatePath(funnelType, success), { resumeFromLink });
    }
    case funnelViews.planExists: {
      return history.push(generatePath(funnelType, planExists), { resumeFromLink });
    }
    default:
      return history.push(generatePath(funnelType, invoiceToPay), { resumeFromLink, checkBills });
  }
};

export const redirectToPartner = ({
  history, funnelType, currentView, resumeFromLink = false,
}) => {
  switch (currentView) {
    case funnelViews.personalInfo:
    case funnelViews.financialInfo: {
      return history.push(generatePath(funnelType, personalInfo), { resumeFromLink, resumeApp });
    }
    case funnelViews.offer: {
      return history.push(generatePath(funnelType, paymentPlan), { resumeFromLink, resumeApp });
    }
    case funnelViews.connectBank: {
      return history.push(generatePath(funnelType, paymentPlanPartnerResume), {
        resumeFromLink, resumeApp,
      });
    }
    case funnelViews.success: {
      return history.push(generatePath(funnelType, success), { resumeFromLink });
    }
    default:
      return history.push(generatePath(funnelType, uploadBill), { resumeFromLink });
  }
};

export const getTUErrorPage = (errorMessage) => {
  if (includes('No TransUnion Hit', errorMessage)) return errorNoCreditRecordFound;
  if (includes('CreditFreeze', errorMessage)) return errorCreditFreeze;
  if (includes('OFAC hit', errorMessage)) return errorOFACHit;
  if (includes('Bankruptcy', errorMessage)) return errorBankruptcyExists;
  return undefined;
};
