/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { prop } from 'ramda';

const Checkbox = ({ field, form }) => {
  const value = prop('value')(field);
  const name = prop('name')(field);
  const setFieldValue = prop('setFieldValue')(form);

  const handleOnChange = (event) => setFieldValue(name, event.target.checked);

  return (
    <>
      <input
        id={name}
        name={name}
        type="checkbox"
        className="switch-checkbox sr-only"
        onChange={handleOnChange}
        checked={value}
      />
      <label className="switch" htmlFor={name}>
        <span className="on">Yes</span>
        <span className="off">No</span>
      </label>
    </>
  );
};

export default Checkbox;
