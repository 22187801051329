import { createReducer } from '@reduxjs/toolkit';
import {
  compose, prop, head, path, isNil, isEmpty, pathOr, propOr,
} from 'ramda';

import { setToken } from 'libs/authenticationService';

import {
  createPatient, updatePatient, getPatient, getPartnerInfo, sendToCrm, setPatientProp,
} from 'actions/patient';
import {
  getFromVault,
  getFromInvite,
} from 'actions/user';
import { updateApplicationPatient, clearStore, updateApplication } from 'actions/application';
import { getFromMychart } from 'actions/epic';
import { selectProvider } from 'actions/provider';

const initialState = {
  additionalIncome: null,
  address: null,
  addressNumber: null,
  annualIncome: null,
  birthDate: null,
  city: null,
  createdAt: null,
  householdIncome: null,
  insuranceId: null,
  isLoaded: false,
  patientId: null,
  postalCode: null,
  riskGroupNum: null,
  spouseIncome: null,
  state: null,
  status: null,
  updatedAt: null,
  partnerId: null,
  sentToCrm: null,
  patientBackup: {
    address: null,
    city: null,
    dateOfBirth: null,
    postalCode: null,
    annualIncome: null,
    hasAddress: null,
  },
};

const getActionReducer = (action) => ({
  [String(action.pending)]: (state) => ({
    ...state,
    status: 'pending',
    isLoaded: false,
  }),
  [String(action.fulfilled)]: (state, action) => ({
    ...state,
    status: 'fulfilled',
    isLoaded: true,
    ...action.payload.patient,
    patientBackup: {
      ...state.patientBackup,
      ...action.payload.patient,
    },
  }),
  [String(action.rejected)]: (state) => ({
    ...state,
    status: 'rejected',
    isLoaded: false,
  }),
});

export default createReducer(initialState, {
  ...getActionReducer(getFromVault),
  ...getActionReducer(getFromInvite),
  ...getActionReducer(getFromMychart),
  ...getActionReducer(selectProvider),

  [String(updateApplicationPatient.pending)]: (state) => ({
    ...state,
    status: 'pending',
    isLoaded: false,
  }),
  [String(updateApplicationPatient.fulfilled)]: (state, action) => {
    const isGuarantorApplication = pathOr(false, ['payload', 'application', 'isGuarantorApplication'])(action);
    const guarantorData = pathOr({}, ['payload', 'guarantorData'])(action);
    const guarantor = { ...pathOr({}, ['payload', 'guarantor'])(action), ...guarantorData };

    const data = isGuarantorApplication
      ? {
        patientId: pathOr(null, ['payload', 'application', 'patientId'])(action),
        address: propOr(null, 'address')(guarantor),
        city: propOr(null, 'city')(guarantor),
        dateOfBirth: propOr(null, 'dateOfBirth')(guarantor),
        postalCode: propOr(null, 'postalCode')(guarantor),
        annualIncome: propOr(null, 'annualIncome')(guarantor),
        hasAddress: propOr(null, 'hasAddress')(guarantor),
      } : action.payload.patient;

    const patientBackup = isGuarantorApplication ? {} : action.payload.patient;

    return {
      ...state,
      status: 'fulfilled',
      isLoaded: true,
      ...data,
      patientBackup: {
        ...state.patientBackup,
        ...patientBackup,
      },
    };
  },
  [String(updateApplicationPatient.rejected)]: (state) => ({
    ...state,
    status: 'rejected',
    isLoaded: false,
  }),

  [String(updateApplication.pending)]: (state) => ({
    ...state,
  }),
  [String(updateApplication.fulfilled)]: (state, action) => {
    const isGuarantorApplication = pathOr(false, ['payload', 'isGuarantorApplication'])(action);
    const guarantorData = pathOr({}, ['payload', 'guarantorData'])(action);

    const guarantor = {
      ...state.guarantor,
      ...guarantorData,
      ...pathOr({}, ['payload', 'guarantor'])(action),
    };

    const data = isGuarantorApplication
      ? {
        address: propOr(null, 'address')(guarantor),
        city: propOr(null, 'city')(guarantor),
        dateOfBirth: propOr(null, 'dateOfBirth')(guarantor),
        postalCode: propOr(null, 'postalCode')(guarantor),
        annualIncome: propOr(null, 'annualIncome')(guarantor),
        hasAddress: propOr(null, 'hasAddress')(guarantor),
      } : {
        address: pathOr(null, ['patientBackup', 'address'])(state),
        city: pathOr(null, ['patientBackup', 'city'])(state),
        dateOfBirth: pathOr(null, ['patientBackup', 'dateOfBirth'])(state),
        postalCode: pathOr(null, ['patientBackup', 'postalCode'])(state),
        annualIncome: pathOr(null, ['patientBackup', 'annualIncome'])(state),
        hasAddress: pathOr(null, ['patientBackup', 'hasAddress'])(state),
      };

    return {
      ...state,
      ...data,
    };
  },
  [String(updateApplication.rejected)]: (state) => ({
    ...state,
  }),

  [String(createPatient.pending)]: (state) => ({
    ...state,
  }),
  [String(createPatient.fulfilled)]: (state, action) => {
    const token = path(['payload', 'auth', 'token'])(action);
    if (isNil(token) || isEmpty(token)) return state;
    setToken(token);

    return {
      ...state,
      ...action.payload.patient,
    };
  },
  [String(createPatient.rejected)]: (state) => ({
    ...state,
  }),

  [String(updatePatient.pending)]: (state) => ({
    ...state,
    status: 'pending',
    isLoaded: false,
  }),
  [String(updatePatient.fulfilled)]: (state, action) => ({
    ...state,
    status: 'fulfilled',
    isLoaded: true,
    ...action.payload,
  }),
  [String(updatePatient.rejected)]: (state) => ({
    ...state,
    status: 'rejected',
    isLoaded: false,
  }),

  [String(getPatient.pending)]: (state) => ({
    ...state,
    status: 'pending',
    isLoaded: false,
  }),
  [String(getPatient.fulfilled)]: (state, action) => ({
    ...state,
    status: 'fulfilled',
    isLoaded: true,
    ...action.payload,
  }),
  [String(getPatient.rejected)]: (state) => ({
    ...state,
    status: 'rejected',
    isLoaded: false,
  }),

  [String(getPartnerInfo.pending)]: (state) => ({
    ...state,
    isLoaded: false,
  }),
  [String(getPartnerInfo.fulfilled)]: (state, action) => {
    const partnerId = compose(prop('partnerId'), head, prop('payload'))(action);
    const newState = { ...state, isLoaded: true };

    if (isNil(partnerId)) return newState;
    return { ...newState, partnerId };
  },
  [String(getPartnerInfo.rejected)]: (state) => ({
    ...state,
    isLoaded: false,
  }),

  [String(sendToCrm.pending)]: (state) => ({
    ...state,
    sentToCrm: false,
  }),
  [String(sendToCrm.fulfilled)]: (state) => ({
    ...state,
    sentToCrm: true,
  }),
  [String(sendToCrm.rejected)]: (state) => ({
    ...state,
    sentToCrm: false,
  }),

  [String(clearStore)]: () => ({
    ...initialState,
  }),

  [String(setPatientProp)]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
});
