import { connect } from 'react-redux';

import ErrorLayout from './ErrorLayout';

const mapStateToProps = ({
  application: { isEpicApp },
}) => ({
  isEpicApp,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorLayout);
