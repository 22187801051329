import React from 'react';

import ErrorLayout from 'components/ErrorLayout';
import Icon from 'components/Icon';
import Link from 'components/Link';

const Error = ({ history }) => (
  <ErrorLayout>
    <div className="mb-16">
      <Icon name="band-aids" className="error-page__icon mb-5 mm-md-6 text-pizazz" />
      <h1 className="h2 mb-5" data-cy="error-title">Apologies</h1>
      <p className="mb-0">
        Sorry, something went wrong.
        <br />
        Please try again.
      </p>
    </div>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <Link
      className="btn btn-primary btn-block text-decoration-none px-10 py-3"
      onClick={history.goBack}
      data-cy="error-back"
      eventName="go-back"
    >
      Go Back
    </Link>
  </ErrorLayout>
);

export default Error;
