import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import LogRocket from 'logrocket';
import mixpanel from 'mixpanel-browser';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from 'store';

import App from 'components/App';
import IconsLibrary from 'components/Icon/IconsLibrary';

import clearPIIData from 'utils/cleanPIIData';

import { GET_FROM_VAULT } from 'libs/apiUrls';

import 'react-toastify/dist/ReactToastify.css';
import 'assets/styles/index.scss';

mixpanel.init(process.env.REACT_APP_MIXPANEL_APP_ID || '');
if (['production', 'staging', 'uat'].includes(process.env.REACT_APP_ENV)) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID, {
    dom: {
      // hide all inputs by default
      inputSanitizer: true,
    },
    // remove all PII info from request/response bodies
    network: {
      requestSanitizer: (request) => {
        if (request.url.toLowerCase().includes(GET_FROM_VAULT)) {
          // ignore PII info in query string
          request.url = `/${GET_FROM_VAULT}`;
        }

        try {
          const body = JSON.parse(request.body);
          request.body = clearPIIData(body);
          // eslint-disable-next-line no-empty
        } catch (e) {}

        return request;
      },
      responseSanitizer: (response) => {
        response.body = clearPIIData(response.body);
        return response;
      },
    },
  });
} else {
  mixpanel.disable();
}

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <IconsLibrary />
      <ToastContainer autoClose={10000} />
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
