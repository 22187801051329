import React from 'react';
import { Link } from 'react-router-dom';

import Header from 'components/Header';

import generatePath from 'utils/generatePath';
import getFunnelType from 'utils/getFunnelType';

const NoMatch = ({ location }) => {
  const funnelType = location && getFunnelType(location);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="d-flex align-items-center flex-fill">
        <div className="text-center mx-auto text-jumbo">
          <h1 className="h2 mb-2">Page not found</h1>
          <p className="mb-5">We can’t seem to find the page you’re looking for.</p>
          <Link to={generatePath(funnelType, '/')} className="btn btn-primary text-decoration-none px-10 py-3">Go to Homepage</Link>
        </div>
      </div>
    </div>
  );
};

export default NoMatch;
