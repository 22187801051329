import React, { useEffect, useState } from 'react';
import {
  equals, divide, compose, prop, head, filter, propEq,
} from 'ramda';
import { format, parseISO } from 'date-fns';

import generatePath from 'utils/generatePath';

import funnelViews from 'libs/funnelViews';
import { connectBank } from 'libs/views';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Currency from 'components/Currency';

const formatDate = (date) => date && format(parseISO(date), 'MM/dd/yyyy');
const hash = Math.random().toString(36).slice(2).substring(5);

const PaymentPlanPartnerResume = ({
  history,
  offer,
  applicationId,
  currentView,
  patientId,
  updateApplication,
  bill: { bills },
  user,
  funnelType,
  getDocumentSigned,
  documentSigned,
}) => {
  const [isFetching, setIsFetching] = useState(false);

  const onContinue = () => history.push(generatePath(funnelType, connectBank));

  const {
    totalPayment, totalAmount, newAmount, offerId, updatedAt,
  } = offer;

  const bill = compose(head, filter(propEq('offerId', offerId)))(bills);
  const providerName = prop('providerName')(bill);
  const accountId = prop('accountId')(bill);

  const amountToPay = newAmount || totalAmount;

  const monthlyPayment = totalPayment && divide(amountToPay, totalPayment);

  useEffect(() => {
    if (equals(offer.getOfferStatus, 'pending') || !user.isLoaded) {
      return setIsFetching(true);
    }
    return setIsFetching(false);
  }, [offer.getOfferStatus, offer.isLoaded]);

  useEffect(() => {
    if (offerId) {
      setIsFetching(true);

      getDocumentSigned({ offerId, apiDocId: 'payment-plan-agreement' })
        .then(() => setIsFetching(false))
        .catch(() => setIsFetching(false));
    }
  }, [offerId]);

  useEffect(() => {
    if (currentView !== funnelViews.offer && applicationId) {
      updateApplication({
        patientId,
        applicationId,
        currentView: funnelViews.offer,
      });
    }
  }, [currentView]);

  const paymentPlanUrl = prop('url')(documentSigned);

  return (
    <Layout
      backLink="/"
      hideBackLink
    >
      <Card
        progressBarValue={66.9117647059}
        content={(
          <div className="funnel-card__wrapper w-100">
            {isFetching && (
              <div className="connect-bank__loader text-center">
                <Loading />
              </div>
            )}
            <div className="text-larger text-center font-weight-light mb-5">
              <p data-private className="text-center text-base mb-2">
                {`Welcome back, ${user.firstName}`}
              </p>
              <h1 className="h4 font-weight-normal mb-0">
                Your payment plan is
                <br />
                approved and ready to go
              </h1>
            </div>
            <div className="payment-plan__box py-5 mb-6 px-5 px-sm-7 bg-alice-blue-alt">
              <div className="text-xlarger text-center">
                {monthlyPayment && (
                  <span className="font-weight-semibold">
                    <span data-private>
                      <Currency amount={monthlyPayment} />
                    </span>
                    <span>/mo</span>
                  </span>
                )}
                {' '}
                <span className="font-weight-light">for</span>
                {' '}
                <span data-private className="font-weight-semibold">{totalPayment && `${totalPayment} Months`}</span>
              </div>
              <hr className="payment-plan__box__hr" />
              <div className="text-small text-comet">
                <div data-private className="d-flex justify-content-between align-items-center mb-3">
                  <span>Patient Balance</span>
                  <Currency
                    amount={amountToPay}
                    className="font-weight-bold"
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <span>Provider name</span>
                  <span>{providerName}</span>
                </div>
                <div data-private className="d-flex justify-content-between align-items-center mb-3">
                  <span>Reference number</span>
                  <span>{accountId}</span>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <span>Approval date</span>
                  <span>{formatDate(updatedAt)}</span>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <a
                    target={`${hash}-payment-plan-agreement`}
                    href={paymentPlanUrl}
                    className="text-decoration-none"
                  >
                    Payment plan agreement
                  </a>
                </div>
              </div>
            </div>
            <div className="funnel-card__form">
              <Button type="primary" className="btn-block" onClick={onContinue}>Activate My Plan</Button>
            </div>
          </div>
        )}
      />
    </Layout>
  );
};

export default PaymentPlanPartnerResume;
