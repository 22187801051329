import { connect } from 'react-redux';

import { updatePatient } from 'actions/patient';
import { viewPatientOffer } from 'actions/offer';

import Household from './Household';

const mapStateToProps = ({
  patient,
  application,
  offer,
}) => ({
  patient,
  application,
  offer,
});

const mapDispatchToProps = {
  updatePatient,
  viewPatientOffer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Household);
