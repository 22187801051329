import { createReducer } from '@reduxjs/toolkit';

import {
  getOfferPayment,
  makePatientPayment,
  makePatientPaymentPartner,
} from 'actions/paymentMade';
import { clearStore } from 'actions/application';

const initialState = {
  status: null,
  isLoaded: false,
};

export default createReducer(initialState, {
  [String(getOfferPayment.pending)]: (state) => ({
    ...state,
    status: 'pending',
    isLoaded: false,
  }),
  [String(getOfferPayment.fulfilled)]: (state, action) => ({
    ...state,
    status: 'fulfilled',
    isLoaded: true,
    ...action.payload,
  }),
  [String(getOfferPayment.rejected)]: (state) => ({
    ...state,
    status: 'rejected',
    isLoaded: false,
  }),

  [String(makePatientPayment.pending)]: (state) => ({
    ...state,
    status: 'pending',
  }),
  [String(makePatientPayment.fulfilled)]: (state, action) => ({
    ...state,
    status: 'fulfilled',
    ...action.payload,
  }),
  [String(makePatientPayment.rejected)]: (state) => ({
    ...state,
    status: 'rejected',
  }),

  [String(makePatientPaymentPartner.pending)]: (state) => ({
    ...state,
    status: 'pending',
  }),
  [String(makePatientPaymentPartner.fulfilled)]: (state, action) => ({
    ...state,
    status: 'fulfilled',
    ...action.payload,
  }),
  [String(makePatientPaymentPartner.rejected)]: (state) => ({
    ...state,
    status: 'rejected',
  }),

  [String(clearStore)]: () => ({
    ...initialState,
  }),
});
