import { apiCall } from 'libs/authenticationService';
import { POST, GET } from 'libs/httpMethods';
import {
  GET_OFFER_PAYMENT,
  MAKE_PATIENT_PAYMENT,
  MAKE_PATIENT_PAYMENT_PARTNER,
} from 'libs/apiUrls';

export const reqGetOfferPayment = (offerId) =>
  apiCall(GET, GET_OFFER_PAYMENT(offerId));

export const reqMakePatientPayment = (data) =>
  apiCall(POST, MAKE_PATIENT_PAYMENT, { data });

export const reqMakePatientPaymentPartner = (data) =>
  apiCall(POST, MAKE_PATIENT_PAYMENT_PARTNER, { data });
