import {
  equals,
  filter,
  isEmpty,
  path,
  pathOr,
} from 'ramda';
import queryString from 'qs';

import React, { useEffect, useState } from 'react';
import funnelViews from 'libs/funnelViews';
import { redirectToApp } from 'utils/redirectTo';
import generatePath from 'utils/generatePath';
import {
  error as errorPage, invoiceToPay, selectAccount,
} from 'libs/views';
import Loading from 'components/Loading';
import { setToken } from 'libs/authenticationService';
import Icon from 'components/Icon';
import Card from 'components/Funnel/Card/CardContainer';

const isValidMyChartRequest = (params) => params.iss && params.iss.length > 0
  && params.launch && params.launch.length > 0;

const Mychart = ({
  location,
  history,
  funnelType,
  getFromMychart,
  setIsEpicApp,
  getProviderConfig,
}) => {
  const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true });
  const [noBillsFound, setNoBillsFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isValidRequest = isValidMyChartRequest(queryParams);

  const submitSuccess = (response) => {
    setIsEpicApp(true);
    const showOrganizationView = path(['action', 'payload', 'showOrganizationView'])(response);

    if (showOrganizationView) {
      return history.push(generatePath(funnelType, selectAccount));
    }

    const { currentView, providerId } = path(['action', 'payload', 'application'])(response);
    const bills = pathOr([], ['action', 'payload', 'bills'])(response);
    const user = pathOr([], ['action', 'payload', 'user'])(response);
    const guarantors = pathOr([], ['action', 'payload', 'guarantors'])(response);
    const nonAcceptedBills = filter((item) => item.status !== 'BILL_ACCEPTED')(bills);
    let viewToShow = isEmpty(nonAcceptedBills) ? funnelViews.success : currentView;
    viewToShow = viewToShow || funnelViews.bills;
    const userFirstName = pathOr('', ['firstName'])(user);
    const userLastName = pathOr('', ['lastName'])(user);
    const guarantorFirstName = pathOr('', ['0', 'firstName'])(guarantors);
    const guarantorLastName = pathOr('', ['0', 'lastName'])(guarantors);
    const isSameFirstName = equals(userFirstName, guarantorFirstName);
    const isSameLastName = equals(userLastName, guarantorLastName);
    const isEmptyGuarantor = isEmpty(guarantorFirstName) || isEmpty(guarantorLastName);
    const token = path(['action', 'payload', 'auth', 'token'])(response);
    setToken(token);
    getProviderConfig({ providerId });

    if (isEmpty(guarantors) || isEmptyGuarantor) {
      return redirectToApp({
        history,
        funnelType,
        currentView: viewToShow,
        checkBills: true,
      });
    }

    if (isSameFirstName && isSameLastName) {
      return redirectToApp({
        history,
        funnelType,
        currentView: viewToShow,
        checkBills: true,
      });
    }

    return history.push(generatePath(funnelType, invoiceToPay));
  };

  const handleError = (error) => {
    const errorStatus = path(['response', 'status'])(error);
    setIsLoading(false);
    if ([400, 404].includes(errorStatus)) {
      setNoBillsFound(true);
      return {};
    }
    return history.push(generatePath(funnelType, errorPage));
  };

  useEffect(() => {
    if (isValidRequest) {
      getFromMychart(queryParams)
        .then(submitSuccess)
        .catch((e) => handleError(e));
    } else setIsLoading(false);
  }, [isValidRequest]);

  return (
    <div className="d-flex flex-column min-vh-100">
      {isLoading && (
        <div className="connect-bank__loader text-center">
          <Loading message="Please Wait" />
        </div>
      )}
      {noBillsFound && (
        <div className="wrapper funnel-wrapper-epic">
          <Card
            className="mt-2 mt-sm-0"
            content={(
              <div className="py-8 px-5 px-md-8 text-center mx-auto text-emperor font-weight-semibold d-flex align-items-center">
                <div className="flex-fill">
                  <Icon name="peace-of-mind" className="error-page__icon mb-5 mm-md-6 text-pizazz" />
                  <h1 className="h4 mb-0" data-cy="error-title">You don’t have any eligible bills at this time</h1>
                </div>
              </div>
            )}
          />
        </div>
      )}
      {!isValidRequest && (
        <div className="wrapper funnel-wrapper-epic">
          <Card
            className="mt-2 mt-sm-0"
            content={(
              <div className="py-8 px-5 px-md-8 text-center mx-auto text-emperor font-weight-semibold d-flex align-items-center">
                <div className="flex-fill">
                  <Icon name="band-aids" className="error-page__icon mb-5 mm-md-6 text-pizazz" />
                  <h1 className="h4 mb-4" data-cy="error-title">Apologies</h1>
                  <p className="mb-0">
                    Invalid request parameters from Epic.
                  </p>
                </div>
              </div>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default Mychart;
