import React from 'react';
import { map, values, compose } from 'ramda';
import { Route, Switch, withRouter } from 'react-router-dom';

import * as views from 'libs/views';

import getFunnelType from 'utils/getFunnelType';

import PrivateRoute from 'components/PrivateRoute';
import WithGATracker from 'components/WithGATracker';

const Routes = ({ location }) => {
  const funnelType = getFunnelType(location);

  return (
    <Switch>
      {compose(map((view) => {
        const Component = view.component;
        const apiDocId = view.apiDocId || null;
        const path = view.route;
        const withTracker = view.withTracker || false;
        const eventPath = view.path && view.path.replace('/', '');

        const AuthorizedRoute = view.isPrivate ? PrivateRoute : Route;
        const RouteComponent = withTracker ? WithGATracker(AuthorizedRoute) : AuthorizedRoute;

        return (
          <RouteComponent
            apiDocId={apiDocId}
            eventPath={eventPath}
            funnelType={funnelType}
            key={view.path}
            exact
            path={path}
            component={(props) => {
              const newProps = {
                ...props,
                funnelType,
                apiDocId,
              };

              return <Component {...newProps} />;
            }}
          />
        );
      }), values)(views)}
    </Switch>
  );
};

export default withRouter(Routes);
