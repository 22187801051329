import React from 'react';
import {
  Formik, Form, Field,
} from 'formik';

import { Input } from 'components/Fields';
import Button from 'components/Button';

import getSubdomain from 'utils/getSubdomain';

import providerForm from './providerForm';

const BillInfo = ({
  onSubmit,
  buttonLabel,
}) => {
  const providerName = getSubdomain(window);
  const { initialValues, validationSchema, fields } = providerForm(providerName);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="mb-5">
            {fields.map((item) => (
              <Field
                key={item.name}
                id={item.name}
                type={item.type}
                name={item.name}
                label={item.label}
                placeholder={item.placeholder}
                className={item.className}
                onChangeHandler={item.onChangeHandler}
                component={Input}
                data-cy={item.name}
                data-testid={item.name}
              />
            ))}
          </div>
          <Button
            type="primary"
            className="btn-block"
            disabled={isSubmitting}
            data-cy="login-continue"
            data-testid="login-button"
            eventName="main-cta"
            isSubmit
          >
            {buttonLabel}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default BillInfo;
