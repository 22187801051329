import React, { useEffect } from 'react';
import { path } from 'ramda';

import { getProfile } from 'libs/authenticationService';
import { error as errorPage } from 'libs/views';

import generatePath from 'utils/generatePath';
import getFunnelType from 'utils/getFunnelType';

const withOffer = (Component) => (props) => {
  const {
    user,
    getUser,
    getPatient,
    getBill,
    getOffer,
    getOfferPayment,
    history,
    location,
  } = props;

  const offerId = sessionStorage.getItem('offerId');
  const providerId = sessionStorage.getItem('providerId');
  const profile = getProfile();
  const patientId = path(['patient', 'patientId'])(profile);
  const funnelType = getFunnelType(location);

  const onFailure = () => history.push(generatePath(funnelType, errorPage));

  const getOfferPaymentSuccess = () => getBill({ patientId, providerId }).catch(onFailure);

  const getOfferSuccess = () => getOfferPayment(offerId)
    .then(getOfferPaymentSuccess).catch(onFailure);

  const getPatientSuccess = () => getOffer(offerId).then(getOfferSuccess).catch(onFailure);

  const getUserSuccess = () => getPatient().then(getPatientSuccess).catch(onFailure);

  useEffect(() => {
    if (!user.isLoaded) {
      getUser().then(getUserSuccess).catch(onFailure);
    }
  }, []);

  return <Component {...props} />;
};

export default withOffer;
