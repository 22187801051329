import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  prop, path, last, compose, mapObjIndexed, omit, isEmpty,
} from 'ramda';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Button from 'components/Button';
import { Input, SearchInput, InfoBubble } from 'components/Fields';
import Loading from 'components/Loading';

import generatePath from 'utils/generatePath';
import { uploadBillForm } from 'utils/validationSchemas';

import { error, uploadBill } from 'libs/views';
import { loggedIn, getProfile } from 'libs/authenticationService';
import { convertToCents } from 'utils/currency';

const generateFormData = (data) => {
  const newData = {
    ...data,
    totalAmount: convertToCents(data.totalAmount),
    includedInOffer: true,
  };

  const form = new FormData();

  mapObjIndexed((value, key) => {
    form.set(key, value);
  }, newData);

  return form;
};

const UploadBill = ({
  history,
  funnelType,
  createProvider,
  createPartnerBill,
  updateApplication,
  bills,
  updateBill,
  getProviderName,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [applicationId, setApplicationId] = useState('');
  const [dirtyForm, setDirtyForm] = useState(false);
  const [focusTotalAmount, setFocusTotalAmount] = useState(false);
  const [focusProviderName, setFocusProviderName] = useState(false);

  useEffect(() => {
    const profile = loggedIn() && getProfile();
    setPatientId(path(['patient', 'patientId'])(profile));
    setApplicationId(prop('applicationId')(profile));
  }, []);

  const isSubmitButtonDisabled = ({ totalAmount, providerName }) =>
    !isEmpty(totalAmount) && !isEmpty(providerName);

  const onFailure = () => history.push(generatePath(funnelType, error));

  const onSuccess = () => history.push(generatePath(funnelType, uploadBill));

  const createPartnerBillSuccess = (data) => () => {
    updateApplication({
      patientId,
      applicationId,
      ...data,
      meta: null,
    })
      .then(onSuccess)
      .catch(onFailure);
  };

  const createProviderSuccess = (data) => (response) => {
    const providerId = path(['value', 'providerId'])(response);
    const billId = compose(prop('billId'), last)(bills);
    const newData = { ...omit(['providerName'])(data), providerId };
    const formData = generateFormData(billId ? { ...newData, billId } : newData);
    const billPayload = { patientId, data: formData };

    return createPartnerBill(billPayload)
      .then(createPartnerBillSuccess({ providerId, medicalRecordNumber: data.accountId }))
      .then(() => {
        if (billId) updateBill({ billId, offerId: null, status: 'BILL_PENDING' });
      })
      .catch(onFailure);
  };

  const handleSubmit = ({ providerName, totalAmount }) => {
    setIsFetching(true);

    if (!dirtyForm) return onSuccess();

    return createProvider({ providerName })
      .then(createProviderSuccess({ providerName, totalAmount }))
      .catch(onFailure);
  };

  const initialValues = {
    totalAmount: '',
    providerName: '',
  };

  const onRequestOptions = (value) => ({ params: { q: value } });

  const hackUseEffect = useEffect;

  return (
    <Layout
      backLink="/"
    >
      <Card
        className="mt-2"
        content={(
          <div className="funnel-card__wrapper">
            {isFetching && (
              <div className="connect-bank__loader text-center">
                <Loading />
              </div>
            )}
            <div className="mb-5">
              <div className="text-center mb-2">Welcome Back</div>
              <h1 className="text-center h4 font-weight-light mb-0">Let’s work on your next bill</h1>
            </div>
            <div className="funnel-card__form">
              <Formik
                initialValues={initialValues}
                validationSchema={uploadBillForm}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ isSubmitting, values, dirty }) => {
                  hackUseEffect(() => {
                    setDirtyForm(dirty);
                  }, [dirty]);

                  const isDisabled = isSubmitButtonDisabled(values);

                  return (
                    <Form>
                      <div className="mb-5">
                        <Field
                          id="totalAmount"
                          name="totalAmount"
                          type="currency"
                          label="Bill amount"
                          component={Input}
                          isRequired
                          onFocus={() => setFocusTotalAmount(true)}
                          onBlur={() => setFocusTotalAmount(false)}
                        />
                        {focusTotalAmount && (
                          <div className="mb-2 mb-sm-4">
                            <InfoBubble
                              content={['Add one bill at a time', 'Bills between $250-$10,000 qualify', 'You can add additional bills later']}
                              className="py-3"
                            />
                          </div>
                        )}
                        <Field
                          id="providerName"
                          name="providerName"
                          type="text"
                          label="Provider Name"
                          request={getProviderName}
                          requestOptions={onRequestOptions}
                          component={SearchInput}
                          isRequired
                          onFocus={() => setFocusProviderName(true)}
                          onBlur={() => setFocusProviderName(false)}
                        />
                        {focusProviderName && (
                          <div className="mb-2 mb-sm-4">
                            <InfoBubble
                              content={['Enter your medical provider name (e.g. hospital, clinic)', 'Don’t enter the collection agency']}
                              className="py-3"
                            />
                          </div>
                        )}
                      </div>
                      <Button type="primary" className="btn-block mb-3" isSubmit disabled={!isDisabled || isSubmitting}>Confirm & Continue</Button>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
      />
    </Layout>
  );
};

export default UploadBill;
