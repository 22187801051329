import { differenceInYears } from 'date-fns';
import { lte } from 'ramda';

const isMinorPatient = (birthDate) => {
  const diffYears = differenceInYears(new Date(), new Date(birthDate));

  return lte(diffYears, 18);
};

export default isMinorPatient;
