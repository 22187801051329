import { connect } from 'react-redux';

import { clearStore, setABTesting } from 'actions/application';
import { getProviderConfig } from 'actions/provider';

import Layout from './Layout';

const mapStateToProps = ({
  user,
  provider,
  application: { isEpicApp },
}) => ({
  user,
  provider,
  isEpicApp,
});

const mapDispatchToProps = {
  clearStore,
  setABTesting,
  getProviderConfig,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Layout);
