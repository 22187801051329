import { connect } from 'react-redux';

import { generateOffers } from 'actions/offer';

import ConfirmSSN from './ConfirmSSN';

const mapStateToProps = ({
  user,
  bill: { bills },
}) => ({
  user,
  bills,
});

const mapDispatchToProps = { generateOffers };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmSSN);
