import { createAsyncAction, createAction } from 'redux-promise-middleware-actions';
import { prop } from 'ramda';

import {
  reqUpdateApplication,
  reqGetApplication,
  reqResetApplication,
  reqUpdateApplicationPatient,
  reqGetApplicationByCode,
  reqSendSlackMessage,
} from 'api/application';

export const updateApplication = createAsyncAction(
  'UPDATE_APPLICATION',
  (payload) => reqUpdateApplication(payload).then(prop('data')),
);

export const updateApplicationPatient = createAsyncAction(
  'UPDATE_APPLICATION_PATIENT',
  (payload) => reqUpdateApplicationPatient(payload).then(prop('data')),
);

export const getApplication = createAsyncAction(
  'GET_APPLICATION',
  (payload) => reqGetApplication(payload).then(prop('data')),
);

export const resetApplication = createAsyncAction(
  'RESET_APPLICATION',
  (payload) => reqResetApplication(payload).then(prop('data')),
);

export const getApplicationByCode = createAsyncAction(
  'GET_APPLICATION_BY_CODE',
  (payload) => reqGetApplicationByCode(payload).then(prop('data')),
);

export const clearStore = createAction('CLEAR_STORE');

export const sendSlackMessage = createAsyncAction(
  'SEND_SLACK_MESSAGE',
  (payload) => reqSendSlackMessage(payload).then(prop('data')),
);

export const setABTesting = createAction('SET_AB_TESTING', (value) => value);

export const updateLoginAttempts = createAction('UPDATE_LOGIN_ATTEMPTS');

export const setIsEpicApp = createAction('SET_IS_EPIC_APP', (value) => value);
