import React from 'react';
import {
  __, gt, lt, is, toLower, isNil, compose, isEmpty, trim, sum, pluck,
} from 'ramda';
import { format, parseISO } from 'date-fns';

import Currency from 'components/Currency';

const formatBillMetaTitle = (str) => {
  if (is(String, str)) return str.replace(/_/g, ' ');
  return null;
};

const formatBillMetaValue = (value) => {
  if (is(String, value)) return toLower(value);
  if (is(Number, value)) return <Currency amount={value} />;
  return value || '';
};

const getValidValue = (value) => {
  if (isNil(value) || value === 0 || compose(isEmpty, trim)(value)) return null;
  return value;
};

const formatDate = (date) => date && format(parseISO(date), 'MM/dd/yyyy');

const BALANCE_MIN = 25000;
const BALANCE_MAX = 1000000;

const isAboveAllowedTotal = gt(__, BALANCE_MAX);
const isBelowAllowedTotal = lt(__, BALANCE_MIN);

const sumBillsTotalAmount = compose(sum, pluck('totalAmount'));

export {
  formatBillMetaTitle,
  formatBillMetaValue,
  getValidValue,
  formatDate,
  BALANCE_MIN,
  BALANCE_MAX,
  isAboveAllowedTotal,
  isBelowAllowedTotal,
  sumBillsTotalAmount,
};
