import React, { useEffect, useRef, useState } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';

const StickyFooterSection = ({
  className,
  children,
}) => {
  const ref = useRef(null);
  const [condition, setCondition] = useState(false);

  const entry = useIntersectionObserver(ref, {
    freezeOnceVisible: false,
  });

  const intersectionRatio = entry && entry.intersectionRatio;

  useEffect(() => {
    if (intersectionRatio < 1 && !condition) {
      setCondition(true);
    }
  });

  return (
    <div ref={ref} className={`${className} ${condition ? 'is-sticky' : ''}`}>
      {children}
    </div>
  );
};

export default StickyFooterSection;
