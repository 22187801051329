import { connect } from 'react-redux';

import { updateOffer, getOffer } from 'actions/offer';
import { updateApplication } from 'actions/application';
import { sendToCrm } from 'actions/patient';

import PaymentPlanPartner from './PaymentPlanPartner';

const mapStateToProps = ({
  offer,
  application: { applicationId, currentView, patientId },
  bill,
  user,
}) => ({
  offer,
  applicationId,
  currentView,
  patientId,
  bill,
  user,
});

const mapDispatchToProps = {
  updateOffer,
  updateApplication,
  getOffer,
  sendToCrm,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentPlanPartner);
