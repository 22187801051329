import {
  object, string, number, boolean, date,
} from 'yup';
import Joi from 'joi';
import { path } from 'ramda';

import tldsList from 'libs/tldsList';
import isValidDOB from './isValidDOB';

const emailSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: tldsList } }),
});

const isEmailValid = (val) => {
  const result = emailSchema.validate({ email: val });
  if (path(['error'])(result)) return false;
  return true;
};

const phoneRegExp = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;
const requiredMsg = (field) => `${field} is a required field`;

const CORRECT_INCOME = 'Make sure you entered your yearly income correctly';
const MIN_INCOME = 'The minimum yearly income is $5,000';
const VALID_EMAIL = 'Must be a valid email';
const VALID_NUMBER = 'Must be a number';
const VALID_PHONE = 'Phone number is not valid';
const totalAmountMsg = 'Account balance needs to be between $250-$10,000 to qualify';

export const personalInfoForm = object().shape({
  fullAddress: string()
    .trim()
    .required(requiredMsg('Valid US address')),
  email: string()
    .test('email', VALID_EMAIL, isEmailValid)
    .trim()
    .required(requiredMsg('Email')),
  phone: string()
    .trim()
    .matches(phoneRegExp, VALID_PHONE)
    .required(requiredMsg('Phone number')),
  annualIncome: number()
    .nullable()
    .min(5000, MIN_INCOME)
    .max(1000000, CORRECT_INCOME)
    .required(requiredMsg('Annual household income')),
});

export const personalInfoWithoutIncome = object().shape({
  fullAddress: string()
    .trim()
    .required(requiredMsg('Valid US address')),
  email: string()
    .test('email', VALID_EMAIL, isEmailValid)
    .trim()
    .required(requiredMsg('Email')),
  phone: string()
    .trim()
    .matches(phoneRegExp, VALID_PHONE)
    .required(requiredMsg('Phone number')),
});

export const personalInfoWithoutAddressForm = object().shape({
  email: string()
    .test('email', VALID_EMAIL, isEmailValid)
    .trim()
    .required(requiredMsg('Email')),
  phone: string()
    .trim()
    .matches(phoneRegExp, VALID_PHONE)
    .required(requiredMsg('Phone number')),
  annualIncome: number()
    .nullable()
    .min(5000, MIN_INCOME)
    .max(1000000, CORRECT_INCOME)
    .required(requiredMsg('Annual household income')),
});

export const personalInfoWithoutAddressAndIncomeForm = object().shape({
  email: string()
    .test('email', VALID_EMAIL, isEmailValid)
    .trim()
    .required(requiredMsg('Email')),
  phone: string()
    .trim()
    .matches(phoneRegExp, VALID_PHONE)
    .required(requiredMsg('Phone number')),
});

export const manualAddressForm = object().shape({
  address: string()
    .trim()
    .required(requiredMsg('Address')),
  city: string()
    .trim()
    .required(requiredMsg('City')),
  state: string()
    .trim()
    .required(requiredMsg('State')),
  postalCode: string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits')
    .required(requiredMsg('Zip Code')),
});

export const financialInfoForm = ({
  spouseIncome, householdIncome, additionalIncome,
}) => object().shape({
  annualIncome: number()
    .nullable()
    .min(10000, CORRECT_INCOME)
    .required(requiredMsg('Annual Income')),
  spouseIncome: spouseIncome && number()
    .nullable()
    .min(1, 'Spouse Income must be a positive number')
    .required(requiredMsg('Spouse Income')),
  householdIncome: householdIncome && number()
    .nullable()
    .min(1, 'Property Income must be a positive number')
    .required(requiredMsg('Property Income')),
  additionalIncome: additionalIncome && number()
    .nullable()
    .min(1, 'Other Income must be a positive number')
    .required(requiredMsg('Other Income')),
});

export const connectBankForm = object().shape({
  accountHolderName: string()
    .trim()
    .required(requiredMsg('Account holder name')),
  routingNumber: string()
    .matches(/^[0-9]+$/, VALID_NUMBER)
    .min(9, 'Please provide a valid routing number')
    .max(9, 'Please provide a valid routing number')
    .required(requiredMsg('Routing number')),
  accountNumber: string()
    .matches(/^[0-9]+$/, VALID_NUMBER)
    .min(4, 'Please provide a valid account number')
    .max(17, 'Please provide a valid account number')
    .required(requiredMsg('Account number')),
});

export const ssnForm = object().shape({
  ssnLast4Digits: string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(4, 'Must be exactly 4 digits')
    .max(4, 'Must be exactly 4 digits'),
});

export const payzenPatientSSNForm = object().shape({
  payzenPatientSSN: string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(4, 'Must be exactly 4 digits')
    .max(4, 'Must be exactly 4 digits'),
});

export const confirmPaymentForm = object().shape({
  agree: boolean().oneOf([true]),
});

export const landingForm = object().shape({
  name: string()
    .trim()
    .required(requiredMsg('Name')),
  providerName: string()
    .trim()
    .required(requiredMsg('Provider name')),
  email: string()
    .trim()
    .test('email', VALID_EMAIL, isEmailValid)
    .required(requiredMsg('Email')),
  message: string()
    .trim()
    .required(requiredMsg('A message')),
});

export const loginPartnerForm = object().shape({
  email: string()
    .test('email', VALID_EMAIL, isEmailValid)
    .trim()
    .required(requiredMsg('Email')),
});

export const uploadBillForm = object().shape({
  totalAmount: number()
    .min(250, totalAmountMsg)
    .max(10000, totalAmountMsg)
    .required(requiredMsg('Account balance')),
  providerName: string()
    .trim()
    .required(requiredMsg('Provider name')),
});

export const verifyCodeForm = object().shape({
  twoFactorCode: string()
    .trim()
    .required(requiredMsg('This')),
});

export const landingPartnerForm = object().shape({
  totalAmount: number()
    .nullable()
    .min(250, 'Plans between $250-$10,000 qualify')
    .max(10000, 'Plans between $250-$10,000 qualify')
    .required('Required'),
  providerName: string()
    .trim()
    .required('Required'),
  email: string()
    .trim()
    .test('email', 'Invalid email', isEmailValid)
    .required('Required'),
});

export const billInfoTestForm = object().shape({
  lastName: string()
    .trim()
    .required('Please enter the patient’s last name'),
  medicalRecordNumber: string()
    .trim()
    .required('Please enter the patient’s medical record number'),
  dateOfBirth: date()
    .test('dob', 'Please enter a valid date of birth', isValidDOB)
    .typeError('Please enter a valid date of birth')
    .required('Please enter a valid date of birth'),
});

export const billInfoDefaultForm = object().shape({
  lastName: string()
    .trim()
    .required('Please enter the patient’s last name'),
  dateOfBirth: date()
    .test('dob', 'Please enter a valid date of birth', isValidDOB)
    .typeError('Please enter a valid date of birth')
    .required('Please enter a valid date of birth'),
});

export const mrnForm = object().shape({
  payzenMedicalRecordNumber: string()
    .trim()
    .required(requiredMsg('Medical record number')),
});

export const phoneForm = object().shape({
  phone: string()
    .trim()
    .matches(phoneRegExp, VALID_PHONE)
    .required(requiredMsg('Phone number')),
});
