import React, { useEffect, useState } from 'react';
import {
  path,
  compose,
  filter,
  prop,
  propEq,
  head,
  isNil,
  equals,
  pathOr,
} from 'ramda';

import { loggedIn, getProfile } from 'libs/authenticationService';
import funnelViews from 'libs/funnelViews';
import { error as errorPage } from 'libs/views';

import generatePath from 'utils/generatePath';
import getFunnelType from 'utils/getFunnelType';

const withPartner = (Component) => (props) => {
  const {
    user,
    getUser,
    getPatient,
    getApplication,
    getBill,
    getOffer,
    getOfferPayment,
    getFromVaultStatus,
    getApplicationStatus,
    bill,
    history,
    offer,
    paymentMadeStatus,
    currentView,
    location,
  } = props;
  const [spinner, setSpinner] = useState(false);
  const { applicationId } = loggedIn() && getProfile();
  const funnelType = getFunnelType(location);
  const isPartnerFunnel = equals(funnelType, 'partner');
  const resumeFromLink = pathOr(false, ['state', 'resumeFromLink'])(location);

  const onFailure = () => {
    setSpinner(false);

    history.push(generatePath(funnelType, errorPage));
  };

  const getBillSuccess = (response) => {
    const offerId = compose(
      prop('offerId'),
      head,
      filter(prop('offerId')),
      path(['action', 'payload']),
    )(response);

    return getOffer(offerId)
      .then(() => setSpinner(false))
      .catch(onFailure);
  };

  const getApplicationSuccess = (response) => {
    const currentView = path(['action', 'payload', 'currentView'])(response);
    const patientId = path(['action', 'payload', 'patientId'])(response);
    const providerId = path(['action', 'payload', 'providerId'])(response);

    switch (currentView) {
      case funnelViews.bills:
      case funnelViews.personalInfo:
      case funnelViews.financialInfo: {
        return getBill({ patientId, providerId })
          .then(() => setSpinner(false))
          .catch(onFailure);
      }
      case funnelViews.offer: {
        return getBill({ patientId, providerId })
          .then(getBillSuccess)
          .catch(onFailure);
      }
      default:
        return null;
    }
  };

  const getPatientSuccess = (response) => {
    const patientId = path(['action', 'payload', 'patientId'])(response);

    return getApplication({ patientId, applicationId })
      .then(getApplicationSuccess)
      .catch(onFailure);
  };

  const getUserSuccess = () => getPatient().then(getPatientSuccess).catch(onFailure);

  useEffect(() => {
    const offerId = prop('offerId')(offer) || compose(prop('offerId'), head, filter(propEq('status', 'BILL_PENDING')), prop('bills'))(bill);
    const isConnectBankView = equals(currentView, 'FUNNEL_CONNECT_BANK');
    const { action } = history;
    const isActionPop = equals(action, 'POP');
    const isLoggedIn = loggedIn();
    const isNilGetApplicationStatus = resumeFromLink ? true : isNil(getApplicationStatus);

    if (isLoggedIn && equals(getFromVaultStatus, 'fulfilled') && isNil(paymentMadeStatus) && !isNil(offerId) && isConnectBankView) {
      setSpinner(true);

      getOfferPayment(offerId)
        .then(() => setSpinner(false))
        .catch(onFailure);
    }

    if (
      isLoggedIn
      && isNilGetApplicationStatus
      && !user.isLoaded
      && applicationId
      && (isActionPop || isPartnerFunnel)
    ) {
      setSpinner(true);

      getUser()
        .then(getUserSuccess)
        .catch(onFailure);
    }
  }, [getFromVaultStatus, getApplicationStatus, currentView]);

  const propsWithSpinner = { ...props, spinner, setSpinner };

  return <Component {...propsWithSpinner} />;
};

export default withPartner;
