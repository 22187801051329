import { createAsyncAction, createAction } from 'redux-promise-middleware-actions';
import { prop } from 'ramda';

import {
  reqCreatePlaidPaymentMethod,
  reqCreateManualPaymentMethod,
  reqLogPlaidEvent,
  reqCreateManualCardPaymentMethod,
} from 'api/paymentMethod';

export const createPlaidPaymentMethod = createAsyncAction(
  'CREATE_PLAID_PAYMENT_METHOD',
  (payload) => reqCreatePlaidPaymentMethod(payload).then(prop('data')),
);

export const createManualPaymentMethod = createAsyncAction(
  'CREATE_MANUAL_PAYMENT_METHOD',
  (payload) => reqCreateManualPaymentMethod(payload).then(prop('data')),
);

export const logPlaidEvent = createAsyncAction(
  'LOG_PLAID_EVENT',
  (payload) => reqLogPlaidEvent(payload)
    .then(prop('data')).catch(() => { }),
);

export const createManualCardPaymentMethod = createAsyncAction(
  'CREATE_MANUAL_CARD_PAYMENT_METHOD',
  (payload) => reqCreateManualCardPaymentMethod(payload).then(prop('data')),
);

export const setCardInfo = createAction('SET_CARD_INFO', (data) => data);
