import { createAsyncAction } from 'redux-promise-middleware-actions';
import { prop } from 'ramda';

import { reqGetProviderConfig, reqSelectProvider } from 'api/provider';

export const getProviderConfig = createAsyncAction(
  'GET_PROVIDER_CONFIG',
  (payload) => reqGetProviderConfig(payload).then(prop('data')),
);

export const selectProvider = createAsyncAction(
  'SELECT_PROVIDER',
  (payload) => reqSelectProvider(payload).then(prop('data')),
);
