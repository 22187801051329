import { createReducer } from '@reduxjs/toolkit';

import { getProviderConfig } from 'actions/provider';
import { clearStore } from 'actions/application';

export const initialState = {
  isLoaded: false,
  providerId: null,
  servicingVendor: null,
  providerName: '',
  providerPhone: '',
  billingPhoneNumber: '',
  logoUrl: [],
  payInFullUri: null,
  paymentMethodTypes: [],
  providerUrl: null,
};

export default createReducer(initialState, {
  [String(getProviderConfig.pending)]: (state) => ({
    ...state,
    isLoaded: false,
  }),
  [String(getProviderConfig.fulfilled)]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoaded: true,
  }),
  [String(getProviderConfig.rejected)]: (state) => ({
    ...state,
    isLoaded: false,
  }),

  [String(clearStore)]: () => ({
    ...initialState,
  }),
});
