import React from 'react';

import ErrorTULayout from 'components/ErrorTULayout';

const OFACHit = () => (
  <ErrorTULayout>
    <p className="mb-0">
      Unfortunately, we are not able to offer you a
      <br />
      payment plan with the information you provided.
    </p>
  </ErrorTULayout>
);

export default OFACHit;
