import React from 'react';
import { toLower } from 'ramda';
import NumberFormat from 'react-number-format';

import visa from 'assets/images/cards/visa.png';
import mastercard from 'assets/images/cards/mastercard.png';
import amex from 'assets/images/cards/amex.png';
import discover from 'assets/images/cards/discover.png';

const getCard = (cardType) => {
  switch (cardType && toLower(cardType)) {
    case 'visa':
      return visa;
    case 'mastercard':
      return mastercard;
    case 'amex':
    case 'american express':
      return amex;
    case 'discover':
      return discover;
    default:
      return null;
  }
};

const CardFormat = ({ data, 'data-testid': dataTestId = '' }) => {
  const { type = '', number = '', exp = '' } = data;
  const cardImage = getCard(type);

  return (
    <span data-testid={dataTestId}>
      {cardImage && <img src={cardImage} className="mr-2" alt={type} height="19" />}
      <b className="mr-2">{`••••${number.substr(number.length - 4)}`}</b>
      <NumberFormat value={exp} displayType="text" format="##/##" className="text-storm-gray" />
    </span>
  );
};

export default CardFormat;
