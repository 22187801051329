import { apiCall } from 'libs/authenticationService';
import { GET, PUT, POST } from 'libs/httpMethods';
import {
  GET_FROM_VAULT,
  GET_FROM_INVITE,
  UPDATE_USER,
  GET_USER,
  TWO_FACTOR_CODE_VERIFICATION,
  ADDRESS_AUTO_COMPLETE,
} from 'libs/apiUrls';

import mixpanelIdentify from 'utils/mixpanelIdentify';

export const reqGetFromVault = (data) => apiCall(GET, GET_FROM_VAULT, { params: data })
  .then(mixpanelIdentify);

export const reqGetFromInvite = (data) => apiCall(GET, GET_FROM_INVITE, { params: data })
  .then(mixpanelIdentify);

export const reqUpdateUser = (data) => apiCall(PUT, UPDATE_USER, { data });

export const reqGetUser = () => apiCall(GET, GET_USER);

export const reqVerificationCode = (data) => apiCall(POST, TWO_FACTOR_CODE_VERIFICATION, { data });

export const reqGetAddressSuggestions = (data) =>
  apiCall(GET, ADDRESS_AUTO_COMPLETE, { params: data });
