import { createReducer } from '@reduxjs/toolkit';
import { clearStore } from 'actions/application';
import { bankAccountValidate } from 'actions/bankAccount';

const initialState = {
  bankName: null,
  recommendation: null,
  text: null,
};

export default createReducer(initialState, {
  [String(bankAccountValidate.pending)]: (state) => ({
    ...state,
  }),
  [String(bankAccountValidate.fulfilled)]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [String(bankAccountValidate.rejected)]: (state) => ({
    ...state,
  }),

  [String(clearStore)]: () => ({
    ...initialState,
  }),
});
