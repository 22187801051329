import { connect } from 'react-redux';
import Header from './Header';

const mapStateToProps = ({
  provider: {
    logoUrl,
    pageTitle,
    poweredByText,
    poweredByIconUrl,
  },
  application: { isEpicApp },
}) => ({
  logoUrl,
  pageTitle,
  poweredByText,
  poweredByIconUrl,
  isEpicApp,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
