import React from 'react';

import ErrorLayout from 'components/ErrorLayout';
import Icon from 'components/Icon';
import Link from 'components/Link';

const ErrorIdentity = ({ history }) => (
  <ErrorLayout>
    <div className="mb-6">
      <Icon name="dotted-person" className="error-page__icon mb-5 mm-md-6 text-alto" />
      <h1 className="h2 mb-5" data-cy="identity-title">Apologies</h1>
      <p className="text-center mb-0">
        We were unable to verify your identity or patient balance.
      </p>
      <p className="text-center mb-5">
        Common reasons:
      </p>
      <ul className="text-left text-small pl-4" data-cy="identity-reasons">
        <li>Double check the information you provided</li>
        <li>Check your billing statement</li>
        <li>Contact your provider</li>
      </ul>
    </div>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <Link
      className="btn btn-primary btn-block text-decoration-none px-10 py-3"
      onClick={history.goBack}
      data-cy="identity-back"
      eventName="go-back"
    >
      Go Back
    </Link>
  </ErrorLayout>
);

export default ErrorIdentity;
