import { connect } from 'react-redux';

import { updatePatient } from 'actions/patient';
import { updateUser } from 'actions/user';
import { updateApplication, updateApplicationPatient } from 'actions/application';
import { getOffer, generateOffers, getGeneratedOffers } from 'actions/offer';

import ReviewInfo from './ReviewInfo';

const mapStateToProps = ({
  user,
  patient,
  patient: {
    patientId,
  },
  application: {
    applicationId, currentView, offerId, status,
  },
  bill: { bills },
  offer: { totalAmount: offerTotalAmount, hasError: hasCreateOfferError },
}) => ({
  user,
  patient,
  applicationId,
  currentView,
  patientId,
  bills,
  offerId,
  offerTotalAmount,
  hasCreateOfferError,
  applicationStatus: status,
});

const mapDispatchToProps = {
  updatePatient,
  updateUser,
  updateApplication,
  updateApplicationPatient,
  getOffer,
  generateOffers,
  getGeneratedOffers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReviewInfo);
