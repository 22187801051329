import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import { connect } from 'react-redux';

if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}

const WithGATracker = (Component, options = {}) => {
  const trackPage = (page, isPayInFull, eventPath) => {
    ReactGA.set({
      page,
      ...options,
    });

    const eventName = eventPath ? `${eventPath}/PV` : `Page Visit (${page})`;
    mixpanel.track(eventName, { 'page url': isPayInFull ? `${page}?PF221` : page });
    mixpanel.people.set({ 'last page': page });
  };

  const HOC = (props) => {
    const {
      location,
      hasPayInFullRedirect,
      payInFullUri,
      eventPath,
      apiDocId,
    } = props;

    const eventNamePath = apiDocId || eventPath;
    const isPayInFull = hasPayInFullRedirect && !!payInFullUri;

    useEffect(() => {
      trackPage(location.pathname + location.search, isPayInFull, eventNamePath);
    }, [location]);

    return <Component {...props} />;
  };

  const mapStateToProps = ({
    application: { tags: { hasPayInFullRedirect } },
    provider: { payInFullUri },
  }) => ({
    hasPayInFullRedirect,
    payInFullUri,
  });

  return connect(
    mapStateToProps,
    null,
  )(HOC);
};

export default WithGATracker;
