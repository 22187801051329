/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import { Input } from 'components/Fields';
import Loading from 'components/Loading';

import { loginPartnerForm } from 'utils/validationSchemas';

import icon from 'assets/images/icn_padlock_sheet.svg';

const SendCode = () => {
  const [isFetching, setIsFetching] = useState(false);

  const onSubmit = () => {
    setIsFetching(true);
  };

  return (
    <Layout
      backLink="/"
    >
      <Card
        content={(
          <div className="funnel-card__wrapper d-flex flex-column financial-info">
            {isFetching && (
              <div className="connect-bank__loader text-center">
                <Loading />
              </div>
            )}
            <div className="text-center mb-6">
              <img src={icon} alt="" className="mb-5" />
              <h1 className="h4 font-weight-normal mb-5">
                Let’s make sure it’s really you
              </h1>
              <p className="mb-0">
                To keep your information safety we need to confirm your identity. Please enter your
                email and we&apos;ll send you a code
              </p>
            </div>
            <div className="funnel-card__form w-100 px-4">
              <Formik
                initialValues={{ email: '' }}
                validationSchema={loginPartnerForm}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="text-center">
                    <label htmlFor="email" className="text-comet mb-2">Enter your email or mobile phone number</label>
                    <Field
                      id="email"
                      type="email"
                      name="email"
                      className="mb-7 "
                      component={Input}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      disabled={isSubmitting}
                    >
                      Send Code
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      />
    </Layout>
  );
};

export default SendCode;
