import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { equals } from 'ramda';

import { loggedIn } from 'libs/authenticationService';
import { billInfo } from 'libs/views';
import generatePath from 'utils/generatePath';

const PrivateRoute = ({
  component: Component,
  computedMatch,
  location,
  funnelType,
  ...rest
}) => {
  const redirectToPath = equals(funnelType, 'app') ? generatePath(funnelType, billInfo) : '/';

  return (
    <Route
      {...rest}
      render={(props) => (loggedIn() ? (
        <Component {...props} funnelType={funnelType} />
      ) : (
        <Redirect
          to={{
            pathname: redirectToPath,
            state: { from: location },
            search: location.search,
          }}
        />
      ))}
    />
  );
};

export default PrivateRoute;
