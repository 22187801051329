import { apiCall } from 'libs/authenticationService';
import {
  POST, PUT, GET, POST_MULTIPART,
} from 'libs/httpMethods';
import {
  CREATE_PATIENT,
  UPDATE_PATIENT,
  GET_PATIENT,
  GET_PARTNER_INFO,
  GET_PROVIDER_NAME,
  CREATE_PROVIDER,
  CREATE_PARTNER_BILL,
  SEND_TO_CRM,
} from 'libs/apiUrls';

export const reqCreatePatient = (data) => apiCall(POST, CREATE_PATIENT, { data });

export const reqUpdatePatient = (data) => apiCall(PUT, UPDATE_PATIENT, { data });

export const reqGetPatient = () => apiCall(GET, GET_PATIENT);

export const reqGetPartnerInfo = ({ params }) => apiCall(GET, GET_PARTNER_INFO, { params });

export const reqGetProviderName = ({ params }) => apiCall(GET, GET_PROVIDER_NAME, { params });

export const reqCreateProvider = (data) => apiCall(POST, CREATE_PROVIDER, { data });

export const reqCreatePartnerBill = ({ patientId, data }) =>
  apiCall(POST_MULTIPART, CREATE_PARTNER_BILL(patientId), { data });

export const reqSendToCrm = (data) => apiCall(POST, SEND_TO_CRM, { data });
