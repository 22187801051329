import { connect } from 'react-redux';

import { updateOffer, getOffer } from 'actions/offer';
import { updateApplication } from 'actions/application';

import getBillsOfferId from 'utils/getBillsOfferId';

import PaymentPlan from './PaymentPlan';

const mapStateToProps = ({
  offer,
  application: {
    applicationId, currentView, patientId, offerId, status,
  },
  user,
  bill: { bills },
}) => {
  const billsOfferId = getBillsOfferId(bills);

  return {
    offer,
    applicationId,
    currentView,
    patientId,
    user,
    applicationStatus: status,
    offerId: offerId || billsOfferId,
  };
};

const mapDispatchToProps = {
  updateOffer,
  updateApplication,
  getOffer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentPlan);
