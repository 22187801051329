/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useRef } from 'react';
import { includes } from 'ramda';

import generatePath from 'utils/generatePath';

import funnelViews from 'libs/funnelViews';
import { paymentPlan, connectBank } from 'libs/views';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Loading from 'components/Loading';
import DebitCardForm from 'components/ConnectPaymentMethod/DebitCardForm';
import ACHForm from 'components/ConnectPaymentMethod/ACHForm';
import LocateRoutingAndAccountNumber from 'components/ConnectPaymentMethod/LocateRoutingAndAccountNumber';
import Button from 'components/Button';

import getSubdomain from 'utils/getSubdomain';
import useTrackMixpanelEvent from 'utils/useTrackMixpanelEvent';

const ConnectPaymentMethod = ({
  history,
  createManualPaymentMethod,
  currentView,
  applicationId,
  patientId,
  updateApplication,
  confirmPatientOffer,
  funnelType,
  offerId,
  applicationStatus,
  sendSlackMessage,
  user,
  bankAccountValidate,
  createManualCardPaymentMethod,
  setCardInfo,
  paymentMethodTypes,
}) => {
  const formRef = useRef();
  const trackMixpanelEvent = useTrackMixpanelEvent();

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const [isFetching, setIsFetching] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState('');
  const [isValidating, setIsValidating] = useState(false);
  const hasDebitCardOption = includes('debit card', paymentMethodTypes);

  const providerName = getSubdomain(window);
  const isDebitCardPayment = currentPaymentMethod === 'debitCard';
  const isECheckPayment = currentPaymentMethod === 'eCheck';

  const TITLE_TEXT = 'How would you like to make monthly payments?';
  const BUTTON_TEXT = 'Continue';

  const onChangeHandler = (event) => setCurrentPaymentMethod(event.target.value);

  const onSendNotificationToSlack = () => sendSlackMessage({
    title: 'Inactivity on Connect Bank Screen',
    provider: providerName,
    name: `${user.firstName} ${user.lastName}`,
    phone: user.phone,
    email: user.email,
  });

  useEffect(() => {
    if (!hasDebitCardOption) {
      history.replace(generatePath(funnelType, connectBank));
    }
  }, [hasDebitCardOption]);

  useEffect(() => {
    if (currentView !== funnelViews.connectBank && applicationId && applicationStatus !== 'APPLICATION_COMPLETED') {
      updateApplication({
        patientId,
        applicationId,
        currentView: funnelViews.connectBank,
      });
    }
  }, [currentView]);

  useEffect(() => {
    const timer = setTimeout(onSendNotificationToSlack, 300000);

    const backListener = () => {
      if (history.action === 'POP') {
        onSendNotificationToSlack();
      }
    };

    return () => {
      clearTimeout(timer);
      backListener();
    };
  }, []);

  const onSubmitDebitCard = () => {
    setIsValidating(true);
  };

  const getButtonProps = () => {
    if (isDebitCardPayment) return { id: 'payButton', onClick: onSubmitDebitCard };
    if (isECheckPayment) return { disabled: isButtonDisabled, onClick: handleSubmit };
    return { disabled: true };
  };

  return (
    <Layout
      backLink={generatePath(funnelType, paymentPlan)}
      backCallback={onSendNotificationToSlack}
      hideBackLink={isFetching}
    >
      <Card
        progressBarValue={88.9705882353}
        content={(
          <>
            <div className="funnel-card__wrapper">
              {isFetching && (
                <div className="connect-bank__loader text-center">
                  <Loading />
                </div>
              )}
              <div className="funnel-card__form">
                <div className="text-center mb-4 mb-sm-6">
                  <h1 className="text-larger text-sm-xlarger mb-3 font-weight-normal" data-cy="bank-title">
                    {TITLE_TEXT}
                  </h1>
                </div>
                <div>
                  <div className="payment-method-box mb-4">
                    <label htmlFor="eCheck" className="d-flex align-items-center mb-0 py-3 px-4">
                      <div className="mr-3">
                        <input
                          id="eCheck"
                          type="radio"
                          className="form-radio__input"
                          name="payment-method"
                          value="eCheck"
                          onChange={(event) => {
                            trackMixpanelEvent('BC', 'checking', { 'button content': 'Checking Account' });
                            onChangeHandler(event);
                          }}
                          checked={isECheckPayment}
                          data-testid="connect-payment-method-e-check-radio"
                        />
                        <div className="form-radio__label mb-0">
                          <span className="fake-radio fake-radio--sm" />
                        </div>
                      </div>
                      <div>
                        <p className="text-black mb-0">Checking Account</p>
                      </div>
                    </label>
                    {isECheckPayment && (
                      <div className="px-4 pb-4 pb-sm-2">
                        <ACHForm
                          history={history}
                          funnelType={funnelType}
                          user={user}
                          patientId={patientId}
                          offerId={offerId}
                          setIsFetching={setIsFetching}
                          bankAccountValidate={bankAccountValidate}
                          confirmPatientOffer={confirmPatientOffer}
                          createManualPaymentMethod={createManualPaymentMethod}
                          setIsButtonDisabled={setIsButtonDisabled}
                          formRef={formRef}
                        />
                        <LocateRoutingAndAccountNumber />
                      </div>
                    )}
                  </div>
                  <div className="payment-method-box mb-5">
                    <label htmlFor="debitCard" className="d-flex align-items-center mb-0 py-3 px-4">
                      <div className="mr-3">
                        <input
                          id="debitCard"
                          type="radio"
                          className="form-radio__input"
                          name="payment-method"
                          value="debitCard"
                          onChange={(event) => {
                            trackMixpanelEvent('BC', 'debit', { 'button content': 'Debit Card' });
                            onChangeHandler(event);
                          }}
                          checked={isDebitCardPayment}
                          data-testid="connect-payment-method-debit-card-radio"
                        />
                        <div className="form-radio__label mb-0">
                          <span className="fake-radio fake-radio--sm" />
                        </div>
                      </div>
                      <div>
                        <p className="text-black mb-0">Debit Card</p>
                        <p className="text-comet text-small mb-0" data-testid="connect-payment-method-hsa-fsa">
                          HSA / FSA accepted
                        </p>
                      </div>
                    </label>
                    {isDebitCardPayment && (
                      <div className="px-4 pb-5">
                        <DebitCardForm
                          setIsFetching={setIsFetching}
                          createManualCardPaymentMethod={createManualCardPaymentMethod}
                          setCardInfo={setCardInfo}
                          patientId={patientId}
                          offerId={offerId}
                          confirmPatientOffer={confirmPatientOffer}
                          isValidating={isValidating}
                          setIsValidating={setIsValidating}
                        />
                      </div>
                    )}
                  </div>
                  <Button
                    type="primary"
                    className="btn-block"
                    {...getButtonProps()}
                    isSubmit
                    eventName="main-cta"
                  >
                    {BUTTON_TEXT}
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      />
    </Layout>
  );
};

export default ConnectPaymentMethod;
