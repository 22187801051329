import React, { useState, useEffect } from 'react';
import { prop, isNil } from 'ramda';

import generatePath from 'utils/generatePath';

import {
  paymentPlan, createBill, error,
} from 'libs/views';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Loading from 'components/Loading';
import Button from 'components/Button';
import Icon from 'components/Icon';

import imageCheckColor from 'assets/images/check-color.png';
import imageCheckGradient from 'assets/images/check-gradient.png';

const AllSet = ({
  user,
  funnelType,
  history,
  clearStore,
  createPatient,
  partnerId,
  applicationId,
  patientId,
  updateApplication,
}) => {
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    window.gtag('event', 'conversion', { send_to: process.env.REACT_APP_GA_ADWORDS_CONVERSION_ID });

    if (patientId && applicationId && !isFetching) {
      updateApplication({
        patientId,
        applicationId,
        status: 'APPLICATION_COMPLETED',
      });
    }
  }, []);

  const onSuccess = () => {
    setIsFetching(false);
    clearStore();
    history.push(generatePath(funnelType, createBill));
  };

  const onFailure = () => {
    setIsFetching(false);
    history.push(generatePath(funnelType, error));
  };

  const addAnotherBill = () => {
    setIsFetching(true);

    const email = prop('email')(user);
    const data = isNil(partnerId) ? { email } : { email, partnerId };

    createPatient(data)
      .then(onSuccess)
      .catch(onFailure);
  };

  return (
    <Layout
      backLink={generatePath(funnelType, paymentPlan)}
    >
      <Card
        className="mt-2"
        progressBarValue={100}
        content={(
          <>
            <div className="funnel-card__wrapper">
              {isFetching && (
                <div className="connect-bank__loader text-center">
                  <Loading />
                </div>
              )}
              <div className="text-center">
                <img src={imageCheckColor} alt="" width="64" className="mb-5" />
                <h1 className="h4 font-weight-light mb-2">You’re All Set!</h1>
                <p className="mb-5">Here’s what will happen next.</p>
              </div>
              <div className="text-trout font-weight-light">
                <div className="media mb-3 mb-sm-5">
                  <img src={imageCheckGradient} alt="" className="mr-3 mt-1" />
                  <div className="media-body">
                    <p className="mb-0">We’ll evaluate whether you qualify under your provider’s financial assistance program</p>
                  </div>
                </div>
                <div className="media mb-3 mb-sm-5">
                  <img src={imageCheckGradient} alt="" className="mr-3 mt-1" />
                  <div className="media-body">
                    <p className="mb-0">If you don’t qualify, we’ll contact your provider to obtain confirmation of your proposed payment plan</p>
                  </div>
                </div>
                <div className="media mb-3 mb-sm-5">
                  <img src={imageCheckGradient} alt="" className="mr-3 mt-1" />
                  <div className="media-body">
                    <p className="mb-0">You will receive an email from us within 5 business days</p>
                  </div>
                </div>
                <p>
                  P.S. Remember, you can use PayZen with any future medical bill for your entire
                  family. Never stress about medical bills!
                </p>
              </div>
            </div>
            <div className="bg-alabaster-alt p-2" />
            <div className="text-center py-3">
              <Button type="link" className="text-decoration-none d-inline-flex align-items-center" onClick={addAnotherBill}>
                <Icon name="sheet-add" className="text-xlarger mr-4" />
                Add another medical bill
              </Button>
            </div>
          </>
        )}
      />
    </Layout>
  );
};

export default AllSet;
