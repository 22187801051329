/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { confirmPayment } from 'libs/views';

import generatePath from 'utils/generatePath';
import getErrorMessage from 'utils/getErrorMessage';

import Loading from 'components/Loading';

const Input = ({
  id, value, onChange, isValidating, setIsValidating,
}) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const onFocus = () => setIsInvalid(false);
  const onValidate = (e) => setIsInvalid(!e.target.value);

  useEffect(() => {
    if (isValidating && !value) {
      setIsInvalid(true);
    }
    setIsValidating(false);
  }, [isValidating]);

  return (
    <input
      id={id}
      type="text"
      className={`simple-input ${isInvalid ? 'is-invalid' : ''}`}
      value={value}
      onChange={onChange}
      onBlur={onValidate}
      onFocus={onFocus}
    />
  );
};

const CreditCardForm = ({
  setIsFetching,
  createManualCardPaymentMethod,
  setCardInfo,
  confirmPatientOffer,
  patientId,
  offerId,
  isValidating,
  setIsValidating,
}) => {
  const history = useHistory();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [stateForm, setStateForm] = useState({
    paymentToken: '',
    type: 'debit card',
  });

  const onChangeFirstName = (e) => setFirstName(e.target.value);
  const onChangeLastName = (e) => setLastName(e.target.value);

  const onCallback = (response) => {
    setStateForm({
      ...stateForm,
      card: response.card,
      paymentToken: response.token,
    });
  };

  const onConfirmPatientOffer = () => confirmPatientOffer({
    patientId,
    offerId,
    data: {},
  });

  const createManualCardPaymentMethodSuccess = () => {
    setIsFetching(false);
    setCardInfo({
      ...stateForm,
      ...stateForm.card,
      firstName,
      lastName,
    });

    history.push(generatePath('app', confirmPayment), { paymentType: 'CARD_PAYMENT' });
  };

  const createManualCardPaymentMethodFailure = (error) => {
    setIsFetching(false);
    const message = getErrorMessage(error);
    setErrorMessage(message);
  };

  useEffect(() => {
    if (stateForm.paymentToken) {
      setIsFetching(true);

      const { card, ...rest } = stateForm;

      const body = {
        ...rest,
        firstName,
        lastName,
      };

      createManualCardPaymentMethod(body)
        .then(onConfirmPatientOffer)
        .then(createManualCardPaymentMethodSuccess)
        .catch(createManualCardPaymentMethodFailure);
    }
  }, [stateForm.paymentToken]);

  useEffect(() => {
    window.CollectJS.configure({
      variant: 'inline',
      callback: onCallback,
      fieldsAvailableCallback: () => setIsLoading(false),
      styleSniffer: true,
      customCss: {
        border: '1px solid #CDCDCF',
        'border-radius': '2px',
        'background-color': 'white',
        color: '#2D2E37',
        height: '40px',
        padding: '8px 12px',
      },
      invalidCss: {
        color: '#FF8800',
        'border-color': '#FF8800',
        'box-shadow': '0px 0px 4px rgb(255 136 0 / 12%)',
      },
      focusCss: {
        color: '#2D2E37',
        'background-color': 'white',
        'border-color': '#4570FE',
        'box-shadow': '0px 0px 4px #4570fe',
      },
      placeholderCss: {
        color: '#71758D',
      },
      fields: {
        ccnumber: {
          selector: '#ccnumber',
        },
        ccexp: {
          selector: '#ccexp',
          placeholder: 'MM/YY',
        },
        cvv: {
          selector: '#cvv',
        },
      },
    });
  }, []);

  return (
    <div className="position-relative form-nmi">
      {isLoading && (
        <div className="loading-nmi bg-white d-flex justify-content-center  align-items-center">
          <Loading />
        </div>
      )}
      <div className="row smaller-gutters">
        <div className="col-6 mb-2">
          <label className="simple-label" htmlFor="firstName">Cardholder First Name</label>
          <Input
            id="firstName"
            value={firstName}
            onChange={onChangeFirstName}
            isValidating={isValidating}
            setIsValidating={setIsValidating}
          />
        </div>
        <div className="col-6 mb-2">
          <label className="simple-label" htmlFor="lastName">Cardholder Last Name</label>
          <Input
            id="lastName"
            value={lastName}
            onChange={onChangeLastName}
            isValidating={isValidating}
            setIsValidating={setIsValidating}
          />
        </div>
        <div className="col-12 mb-2">
          <label className="simple-label" htmlFor="ccnumber">Card Number</label>
          <div id="ccnumber" />
        </div>
        <div className={`col-6 ${errorMessage ? 'mb-2' : ''}`}>
          <label className="simple-label" htmlFor="ccexp">Expiration Date</label>
          <div id="ccexp" />
        </div>
        <div className={`col-6 ${errorMessage ? 'mb-2' : ''}`}>
          <label className="simple-label" htmlFor="cvv">CVV</label>
          <div id="cvv" />
        </div>
        {errorMessage && (
          <div className="col-12">
            <div className="bg-linen rounded p-4">
              <p className="text-flush-mahogany text-small mb-0">
                <b>{errorMessage}</b>
                {' '}
                Please update and retry.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreditCardForm;
