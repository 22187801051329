import { connect } from 'react-redux';
import { propEq, filter } from 'ramda';

import { updateBill } from 'actions/bill';
import { updateApplication } from 'actions/application';

import InvoiceToPay from './InvoiceToPay';

const mapStateToProps = ({
  user: {
    firstName,
    guarantors,
    userBackup,
  },
  bill: { bills },
  application: {
    applicationId, currentView, patientId, status,
    tags: { hasPayInFullRedirect },
    isEpicApp,
  },
  provider: {
    providerName,
    providerPhone,
    payInFullUri,
  },
  patient: { birthDate },
  organization: { showOrganizationView },
}) => {
  const pendingBills = filter(propEq('status', 'BILL_PENDING'))(bills);

  return {
    firstName,
    bills: pendingBills,
    applicationId,
    currentView,
    patientId,
    applicationStatus: status,
    providerName,
    providerPhone,
    hasPayInFullRedirect,
    payInFullUri,
    guarantors,
    birthDate,
    userBackup,
    isEpicApp,
    showOrganizationView,
  };
};

const mapDispatchToProps = { updateBill, updateApplication };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvoiceToPay);
