import React from 'react';

import generatePath from 'utils/generatePath';

import { TERMS_OF_SERVICE } from 'libs/views';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Form from 'components/Forms/BillInfo';
import Loading from 'components/Loading';
import Link from 'components/Link';

import hipaaLogo from 'assets/images/homepage/hipaa-logo.png';
import ahaLogo from 'assets/images/homepage/aha-logo.png';
import privacyLogo from 'assets/images/homepage/privacy-logo.png';

const BillInfoLayout = ({
  isFetching,
  handleSubmit,
  children,
}) => {
  const hash = Math.random().toString(36).slice(2).substring(5);

  return (
    <Layout>
      <Card
        className="mt-2"
        progressBarValue={0}
        content={(
          <div className="funnel-card__wrapper pb-8">
            {isFetching && (
              <div className="connect-bank__loader text-center">
                <Loading />
              </div>
            )}
            {children}
            <div className="funnel-card__form">
              <Form onSubmit={handleSubmit} buttonLabel="Review my bill" />
              <div className="pt-4 mb-5 text-center">
                <p className="text-small">
                  By continuing, I agree to the
                  {' '}
                  <Link
                    target={`${hash}-terms-of-service`}
                    to={generatePath('app', TERMS_OF_SERVICE)}
                    data-cy="login-tos"
                    eventName="terms-of-service"
                  >
                    Terms of Service
                  </Link>
                  .
                </p>
              </div>
              <div className="text-center text-comet mt-5 mt-sm-6">
                <div className="row align-items-center small-gutters">
                  <div className="col text-center">
                    <img src={hipaaLogo} alt="" className="img-multiply img-fluid" />
                  </div>
                  <div className="col text-center">
                    <img src={ahaLogo} alt="" className="img-multiply img-fluid" />
                  </div>
                  <div className="col text-center">
                    <img src={privacyLogo} alt="" className="img-multiply img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    </Layout>
  );
};
export default BillInfoLayout;
