/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { propOr, prop, isNil } from 'ramda';
import Textarea from 'react-textarea-autosize';
import { getIn } from 'formik';

import Icon from 'components/Icon';
import {
  ErrorMessage,
  ManualDateInput,
  PhoneInput,
  CurrencyInput,
  EncryptedInput,
  AutoCompleteAddress,
} from 'components/Fields';

import useTrackMixpanelEvent from 'utils/useTrackMixpanelEvent';

const Input = ({
  id,
  type,
  className,
  disabled,
  field,
  label,
  icon,
  form,
  onChangeHandler,
  errorInside,
  isRequired,
  isOptional,
  onFocus,
  onBlur,
  options,
  autoComplete,
  'data-testid': dataTestId = '',
  eventName = '',
}) => {
  const name = propOr('', 'name')(field);
  const errors = propOr({}, 'errors')(form);
  const touched = propOr({}, 'touched')(form);
  const handleBlur = prop('handleBlur')(form);
  const error = getIn(errors, name);
  const hasError = getIn(touched, name) && !isNil(error);
  const isValidClass = isNil(error) && field.value ? 'is-valid' : '';
  const invalidClass = hasError ? 'is-invalid' : '';
  const withoutLabelClass = label ? '' : 'form-control--without-label';
  const errorInsideClass = errorInside ? 'is-invalid-inside' : '';
  const autoCompleteValue = process.env.REACT_APP_ENV === 'development' ? 'on' : autoComplete;

  const { setFieldTouched, validateOnMount } = form;

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    validateOnMount && setFieldTouched(name, true);
  }, [error]);

  const trackMixpanelEvent = useTrackMixpanelEvent();

  switch (type) {
    case 'textarea':
      return (
        <>
          <div className={`form-group mb-2 mb-sm-4 ${icon && 'input-group'}`}>
            {icon && (
              <div className="input-group-prepend">
                <span className="input-group-text">{icon}</span>
              </div>
            )}
            <Textarea
              data-private
              id={id}
              type={type}
              placeholder={label}
              className={`form-control ${className} ${invalidClass} ${isValidClass} ${withoutLabelClass}`}
              disabled={disabled}
              {...field}
              minRows={3}
              maxRows={3}
            />
            {label && (
              <label htmlFor={id} className="form-control-placeholder">
                {label}
                {isRequired && <span className="ml-1 text-burnt-sienna">*</span>}
              </label>
            )}
          </div>
          {hasError && <ErrorMessage error={error} />}
        </>
      );
    case 'currency':
      return (
        <>
          <div className={`form-group mb-2 mb-sm-4 position-relative ${icon && 'input-group'}`}>
            {icon && (
              <div className="input-group-prepend">
                <span className="input-group-text">{icon}</span>
              </div>
            )}
            <CurrencyInput
              data-private
              setField={form.setFieldValue}
              id={id}
              type={type}
              placeholder={label}
              className={`form-control ${className} ${invalidClass} ${isValidClass} ${withoutLabelClass} ${errorInsideClass}`}
              disabled={disabled}
              {...field}
              onFocus={onFocus}
              onBlur={(e) => {
                onBlur();
                return handleBlur(e);
              }}
              onChange={(e) => {
                form.setFieldValue(name, e.target.value.replace(/,/g, ''));
              }}
            />
            {label && (
              <label htmlFor={id} className="form-control-placeholder">
                {label}
                {isRequired && <span className="ml-1 text-burnt-sienna">*</span>}
              </label>
            )}
            {hasError && errorInside && <ErrorMessage error={error} errorInside />}
          </div>
          {hasError && !errorInside && <ErrorMessage error={error} />}
        </>
      );
    case 'tel':
      return (
        <>
          <div className={`form-group mb-2 mb-sm-4 ${icon && 'input-group'}`}>
            {icon && (
              <div className="input-group-prepend">
                <span className="input-group-text">{icon}</span>
              </div>
            )}
            <PhoneInput
              data-private
              setField={form.setFieldValue}
              id={id}
              type={type}
              placeholder={label}
              className={`form-control ${className} ${invalidClass} ${isValidClass} ${withoutLabelClass}`}
              disabled={disabled}
              {...field}
            />
            {label && (
              <label htmlFor={id} className="form-control-placeholder">
                {label}
                {isRequired && <span className="ml-1 text-burnt-sienna">*</span>}
              </label>
            )}
          </div>
          {hasError && <ErrorMessage error={error} />}
        </>
      );
    case 'address':
      return (
        <>
          <div className={`form-group mb-2 mb-sm-4 ${icon && 'input-group'}`}>
            {icon && (
              <div className="input-group-prepend">
                <span className="input-group-text">{icon}</span>
              </div>
            )}
            <AutoCompleteAddress
              data-private
              setField={form.setFieldValue}
              id={id}
              type={type}
              label={label}
              className={`form-control ${className} ${invalidClass} ${isValidClass} ${withoutLabelClass}`}
              disabled={disabled}
              field={field}
              form={form}
            />
          </div>
        </>
      );
    case 'date':
      return (
        <>
          <div className={`form-group mb-2 mb-sm-4 ${icon && 'input-group'}`}>
            {icon && (
              <div className="input-group-prepend">
                <span className="input-group-text">{icon}</span>
              </div>
            )}
            <ManualDateInput
              data-private
              setField={form.setFieldValue}
              id={id}
              label={label}
              className={`form-control ${className} ${invalidClass} ${isValidClass} ${withoutLabelClass}`}
              disabled={disabled}
              {...field}
              data-testid={dataTestId}
            />
          </div>
          {hasError && <ErrorMessage error={error} />}
        </>
      );
    case 'radio':
      return (
        <div className="form-radio mr-3">
          <input
            data-private
            id={id}
            type={type}
            {...field}
            checked={form.values[name] === field.value}
            className="form-radio__input"
          />
          <label htmlFor={id} className="form-radio__label mb-0">
            <span className="fake-radio" />
            <span>{label}</span>
          </label>
        </div>
      );
    case 'checkbox':
      return (
        <div className={`form-checkbox mr-3 ${invalidClass}`}>
          <input
            data-private
            id={id}
            type={type}
            {...field}
            checked={form.values[name]}
            className="form-checkbox__input"
            onChange={(event) => {
              trackMixpanelEvent('BC', eventName, { 'button content': eventName });
              field.onChange(event);
            }}
          />
          <label htmlFor={id} className="form-checkbox__label mb-0">
            <Icon name="checkmark" className="form-checkbox__label__icon" />
          </label>
        </div>
      );
    case 'select':
      return (
        <div className={`form-group mb-2 mb-sm-4 position-relative ${icon && 'input-group'}`}>
          <select
            data-private
            id={id}
            className={`form-control form-control--select ${className} ${invalidClass} ${isValidClass} ${withoutLabelClass} ${errorInsideClass}`}
            disabled={disabled}
            {...field}
            data-testid={dataTestId}
          >
            <option value="" label="Select a state" />
            {options.map((item) => (<option key={item} value={item}>{item}</option>))}
          </select>
          {label && (
            <label htmlFor={id} className="form-control-placeholder">
              {label}
              {isRequired && <span className="ml-1 text-burnt-sienna">*</span>}
            </label>
          )}
        </div>
      );
    case 'encrypted':
      return (
        <>
          <div className={`form-group mb-2 mb-sm-4 position-relative ${icon && 'input-group'}`}>
            {icon && (
              <div className="input-group-prepend">
                <span className="input-group-text">{icon}</span>
              </div>
            )}
            <EncryptedInput
              data-private
              id={id}
              type={type}
              placeholder={label}
              className={`form-control form-control--encrypted ${className} ${invalidClass} ${isValidClass} ${withoutLabelClass} ${errorInsideClass}`}
              disabled={disabled}
              autoComplete={autoCompleteValue}
              {...field}
              onChange={(e) => {
                const value = onChangeHandler(e.target.value);
                form.setFieldValue(name, value);
              }}
            />
            {label && (
              <label htmlFor={id} className="form-control-placeholder">
                {label}
                {isRequired && <span className="ml-1 text-burnt-sienna">*</span>}
              </label>
            )}
            {isOptional && <span className="optional-label text-turquoise text-smaller">optional</span>}
            {hasError && errorInside && <ErrorMessage error={error} errorInside />}
          </div>
          {hasError && !errorInside && <ErrorMessage error={error} />}
        </>
      );
    default:
      return (
        <>
          <div className={`form-group mb-2 mb-sm-4 position-relative ${icon && 'input-group'}`}>
            {icon && (
              <div className="input-group-prepend">
                <span className="input-group-text">{icon}</span>
              </div>
            )}
            <input
              data-private
              id={id}
              type={type}
              placeholder={label}
              className={`form-control ${className} ${invalidClass} ${isValidClass} ${withoutLabelClass} ${errorInsideClass}`}
              disabled={disabled}
              autoComplete={autoCompleteValue}
              {...field}
              onChange={(e) => {
                const value = onChangeHandler(e.target.value);
                form.setFieldValue(name, value);
              }}
              data-testid={dataTestId}
            />
            {label && (
              <label htmlFor={id} className="form-control-placeholder">
                {label}
                {isRequired && <span className="ml-1 text-burnt-sienna">*</span>}
              </label>
            )}
            {isOptional && <span className="optional-label text-turquoise text-smaller">optional</span>}
            {hasError && errorInside && <ErrorMessage error={error} errorInside />}
          </div>
          {hasError && !errorInside && <ErrorMessage error={error} />}
        </>
      );
  }
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  onChangeHandler: PropTypes.func,
  errorInside: PropTypes.bool,
  isRequired: PropTypes.bool,
  isOptional: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  options: PropTypes.array,
};

Input.defaultProps = {
  className: '',
  disabled: false,
  icon: '',
  label: '',
  onChangeHandler: (val) => val,
  errorInside: false,
  isRequired: false,
  isOptional: false,
  onFocus: (val) => val,
  onBlur: (val) => val,
  options: [],
};

export default Input;
