import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  compose,
  isEmpty,
  values,
  filter,
  pathOr,
  toLower,
  includes,
} from 'ramda';

import generatePath from 'utils/generatePath';

import funnelViews from 'libs/funnelViews';
import { confirmPayment, paymentPlan } from 'libs/views';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Button from 'components/Button';
import Loading from 'components/Loading';
import { Input } from 'components/Fields';

import { connectBankForm } from 'utils/validationSchemas';
import getErrorMessage from 'utils/getErrorMessage';
import showNotification from 'utils/showNotification';
import getSubdomain from 'utils/getSubdomain';

import imageRN1 from 'assets/images/rn-1.svg';
import imageRN2 from 'assets/images/rn-2.svg';
import imageAC1 from 'assets/images/ac-1.svg';
import imageAC2 from 'assets/images/ac-2.svg';
import hipaaLogo from 'assets/images/homepage/hipaa-logo.png';
import ahaLogo from 'assets/images/homepage/aha-logo.png';
import privacyLogo from 'assets/images/homepage/privacy-logo.png';
import isDemo from 'utils/isDemo';

const initialValuesManuallyForm = {
  routingNumber: '',
  accountNumber: '',
  accountHolderName: '',
  accountType: 'savings',
};

const ConnectBankSimple = ({
  history,
  createManualPaymentMethod,
  currentView,
  applicationId,
  patientId,
  updateApplication,
  confirmPatientOffer,
  funnelType,
  offerId,
  applicationStatus,
  sendSlackMessage,
  user,
  bankAccountValidate,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const providerName = getSubdomain(window);

  const onCreateManualPaymentMethodSuccess = () =>
    history.push(generatePath(funnelType, confirmPayment));

  const onSubmitFailure = (error) => {
    setIsFetching(false);
    const errorMessage = getErrorMessage(error);

    return showNotification({ type: 'error', message: errorMessage });
  };

  const enableView = (actions) => {
    setIsFetching(false);
    actions.setSubmitting(false);
  };

  const onConfirmPatientOffer = () => confirmPatientOffer({
    patientId,
    offerId,
    data: {},
  });

  const onSubmitSuccess = ({ data, actions }) => (response) => {
    const recommendation = compose(toLower, pathOr('', ['value', 'recommendation']))(response);
    const text = compose(toLower, pathOr('', ['value', 'text']))(response);

    if (text === 'invalid routing number') {
      enableView(actions);
      return showNotification({ type: 'error', message: 'Please provide a valid routing number' });
    }

    if (recommendation === 'deny') {
      enableView(actions);
      return showNotification({ type: 'error', message: 'Please provide a valid account number' });
    }

    return createManualPaymentMethod(data)
      .then(onConfirmPatientOffer)
      .then(onCreateManualPaymentMethodSuccess)
      .catch(onSubmitFailure)
      .finally(() => enableView(actions));
  };

  const handleSubmit = (data, actions) => {
    setIsFetching(true);

    if (isDemo()) return onSubmitSuccess({ data, actions })();

    return bankAccountValidate({
      routingNumber: data.routingNumber,
      accountNumber: data.accountNumber,
      firstName: user.firstName,
      lastName: user.lastName,
    }).then(onSubmitSuccess({ data, actions }))
      .catch(onSubmitSuccess({ data, actions }));
  };

  useEffect(() => {
    if (currentView !== funnelViews.connectBank && applicationId && applicationStatus !== 'APPLICATION_COMPLETED') {
      updateApplication({
        patientId,
        applicationId,
        currentView: funnelViews.connectBank,
      });
    }
  }, [currentView]);

  const onSendNotificationToSlack = () => sendSlackMessage({
    title: 'Inactivity on Connect Bank Screen',
    provider: providerName,
    name: `${user.firstName} ${user.lastName}`,
    phone: user.phone,
    email: user.email,
  });

  useEffect(() => {
    const timer = setTimeout(onSendNotificationToSlack, 300000);

    const backListener = () => {
      if (history.action === 'POP') {
        onSendNotificationToSlack();
      }
    };

    return () => {
      clearTimeout(timer);
      backListener();
    };
  }, []);

  const TITLE_TEXT = 'Let\'s set up your first payment!';
  const INFO_TEXT = 'Your banking information is encrypted and secure.';

  return (
    <Layout
      backLink={generatePath(funnelType, paymentPlan)}
      backCallback={onSendNotificationToSlack}
      hideBackLink={isFetching}
    >
      <Card
        progressBarValue={88.9705882353}
        content={(
          <>
            <div className="funnel-card__wrapper">
              {isFetching && (
                <div className="connect-bank__loader text-center">
                  <Loading />
                </div>
              )}
              <div className="funnel-card__form">
                <div className="text-center mb-4 mb-sm-6">
                  <h1 className="text-larger text-sm-xlarger mb-3 font-weight-normal" data-cy="bank-title">
                    {TITLE_TEXT}
                  </h1>
                  <p className="mb-0" data-cy="bank-description">
                    {INFO_TEXT}
                  </p>
                </div>
                <Formik
                  initialValues={initialValuesManuallyForm}
                  validationSchema={connectBankForm}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, errors, values: formData }) => {
                    const isDataComplete = !compose(isEmpty, filter(isEmpty), values)(formData);
                    const WithErrors = !isEmpty(errors);
                    const isDisabled = isSubmitting || isDataComplete || WithErrors;

                    return (
                      <Form className="d-flex flex-column h-100">
                        <div className="row no-gutters mb-3 mb-sm-5">
                          <div className="col-12">
                            <Field
                              id="accountHolderName"
                              type="text"
                              name="accountHolderName"
                              label="Name on account"
                              component={Input}
                              data-cy="bank-holdername"
                              autoComplete="off"
                            />
                          </div>
                          <div className="col-12">
                            <Field
                              id="routingNumber"
                              type="text"
                              name="routingNumber"
                              label="Routing number"
                              component={Input}
                              data-cy="bank-routingnumber"
                              autoComplete="off"
                            />
                          </div>
                          <div className="col-12 mb-2 mb-sm-4">
                            <div className="d-flex align-items-start justify-content-between bg-alabaster-alt px-4 pt-5 pb-4">
                              <img src={imageRN1} alt="" />
                              <img src={imageRN2} alt="" />
                            </div>
                          </div>
                          <div className="col-12">
                            <Field
                              id="accountNumber"
                              type="encrypted"
                              name="accountNumber"
                              label="Account number"
                              component={Input}
                              data-cy="bank-accountnumber"
                              autoComplete="off"
                            />
                          </div>
                          <div className="col-12 mb-2 mb-sm-4">
                            <div className="d-flex align-items-start justify-content-between bg-alabaster-alt px-4 pt-5 pb-4">
                              <img src={imageAC1} alt="" />
                              <img src={imageAC2} alt="" />
                            </div>
                          </div>
                        </div>
                        <Button type="primary" className="btn-block" isSubmit disabled={isDisabled} data-cy="bank-continue">Continue</Button>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
              <div className="text-center text-comet mt-5 mt-sm-6">
                <p className="letter-spacing mb-1 mb-md-3">Your transaction is secure</p>
                <div className="row align-items-center small-gutters">
                  <div className="col text-center">
                    <img src={hipaaLogo} alt="" className="img-multiply img-fluid" />
                  </div>
                  <div className="col text-center">
                    <img src={ahaLogo} alt="" className="img-multiply img-fluid" />
                  </div>
                  <div className="col text-center">
                    <img src={privacyLogo} alt="" className="img-multiply img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      />
    </Layout>
  );
};

export default ConnectBankSimple;
