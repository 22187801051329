import React, { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  compose,
  isEmpty,
  values,
  filter,
  pathOr,
  toLower,
} from 'ramda';

import { Input } from 'components/Fields';

import { connectBankForm } from 'utils/validationSchemas';
import getErrorMessage from 'utils/getErrorMessage';
import generatePath from 'utils/generatePath';
import showNotification from 'utils/showNotification';
import isDemo from 'utils/isDemo';

import { confirmPayment } from 'libs/views';

const initialValuesManuallyForm = {
  routingNumber: '',
  accountNumber: '',
  accountHolderName: '',
  accountType: 'savings',
};

const ACHForm = ({
  history,
  funnelType,
  user,
  patientId,
  offerId,
  setIsFetching,
  bankAccountValidate,
  confirmPatientOffer,
  createManualPaymentMethod,
  setIsButtonDisabled,
  formRef,
}) => {
  const onCreateManualPaymentMethodSuccess = () =>
    history.push(generatePath(funnelType, confirmPayment), { paymentType: 'ACH_PAYMENT' });

  const onSubmitFailure = (error) => {
    setIsFetching(false);
    const errorMessage = getErrorMessage(error);

    return showNotification({ type: 'error', message: errorMessage });
  };

  const enableView = (actions) => {
    setIsFetching(false);
    actions.setSubmitting(false);
  };

  const onConfirmPatientOffer = () => confirmPatientOffer({
    patientId,
    offerId,
    data: {},
  });

  const onSubmitSuccess = ({ data, actions }) => (response) => {
    const recommendation = compose(toLower, pathOr('', ['value', 'recommendation']))(response);
    const text = compose(toLower, pathOr('', ['value', 'text']))(response);

    if (text === 'invalid routing number') {
      enableView(actions);
      return showNotification({ type: 'error', message: 'Please provide a valid routing number' });
    }

    if (recommendation === 'deny') {
      enableView(actions);
      return showNotification({ type: 'error', message: 'Please provide a valid account number' });
    }

    return createManualPaymentMethod(data)
      .then(onConfirmPatientOffer)
      .then(onCreateManualPaymentMethodSuccess)
      .catch(onSubmitFailure)
      .finally(() => enableView(actions));
  };

  const handleSubmit = (data, actions) => {
    setIsFetching(true);

    if (isDemo()) return onSubmitSuccess({ data, actions })();

    return bankAccountValidate({
      routingNumber: data.routingNumber,
      accountNumber: data.accountNumber,
      firstName: user.firstName,
      lastName: user.lastName,
    }).then(onSubmitSuccess({ data, actions }))
      .catch(onSubmitSuccess({ data, actions }));
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValuesManuallyForm}
      validationSchema={connectBankForm}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, values: formData }) => {
        const isDataComplete = !compose(isEmpty, filter(isEmpty), values)(formData);
        const WithErrors = !isEmpty(errors);
        const isDisabled = isSubmitting || isDataComplete || WithErrors;

        useEffect(() => {
          setIsButtonDisabled(isDisabled);
        }, [isDisabled]);

        return (
          <Form>
            <Field
              id="accountHolderName"
              type="text"
              name="accountHolderName"
              label="Name on account"
              component={Input}
              data-cy="bank-holdername"
              autoComplete="off"
            />
            <Field
              id="routingNumber"
              type="text"
              name="routingNumber"
              label="Routing number"
              component={Input}
              data-cy="bank-routingnumber"
              autoComplete="off"
            />
            <Field
              id="accountNumber"
              type="encrypted"
              name="accountNumber"
              label="Account number"
              component={Input}
              data-cy="bank-accountnumber"
              autoComplete="off"
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ACHForm;
