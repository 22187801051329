import React from 'react';

import Currency from 'components/Currency';

import { sumBillsTotalAmount } from 'utils/invoiceToPay';

const TotalAmount = ({ bills }) => (
  <div data-private className="d-flex justify-content-between align-items-center mb-1" data-cy="bills-totalamount">
    <p className="text-storm-gray text-smaller mb-0">Total Amount</p>
    <Currency
      amount={sumBillsTotalAmount(bills)}
      className="text-tuna font-weight-semibold"
    />
  </div>
);

export default TotalAmount;
