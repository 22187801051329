import React, { useState } from 'react';

import Button from 'components/Button';
import Icon from 'components/Icon';

import image from 'assets/images/routing-account-number.svg';

const LocateRoutingAndAccountNumber = () => {
  const [show, setShow] = useState(false);
  const toggle = () => setShow((prevShow) => !prevShow);

  return (
    <div className={show ? '' : 'mb-0 mb-sm-4'} data-testid="locate-routing-account-number">
      <div className={`text-center ${show ? 'mb-2 mb-sm-4' : ''}`}>
        <Button
          type="link"
          onClick={toggle}
          className="rounded-0 text-dodger-blue p-0 text-small text-underline d-inline-flex align-items-center"
          data-testid="locate-routing-account-number-btn"
          eventName="locate-routing"
        >
          Locate routing and account number
          <Icon
            name={show ? 'caret-up-alt' : 'caret-down-alt'}
            className="ml-1 text-xsmall"
          />
        </Button>
      </div>
      {show && (
        <img src={image} alt="" className="img-fluid w-100" data-testid="locate-routing-account-number-img" />
      )}
    </div>
  );
};

export default LocateRoutingAndAccountNumber;
