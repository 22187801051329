import React, { useEffect, useState } from 'react';
import {
  path,
  prop,
  mapObjIndexed,
  trim,
  propOr,
  equals,
  pathOr,
  compose,
  join,
  values,
  omit,
  head,
} from 'ramda';
import { format } from 'date-fns';
import queryString from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

import getErrorMessage from 'utils/getErrorMessage';
import generatePath from 'utils/generatePath';
import getSubdomain from 'utils/getSubdomain';
import { guarantorValidation } from 'utils/guarantors';

import { logout } from 'libs/authenticationService';

import {
  mrn as mrnPage,
  ssn as ssnPage,
  billInfoError,
  errorMessage as errorMessageRoute,
} from 'libs/views';

import BillInfoLayout from 'components/BillInfoLayout';

const formatDate = (date) => format(new Date(date), 'MM-dd-yyyy');

const BillInfo = ({
  getFromVault,
  getFromInvite,
  funnelType,
  setIsSmsLink,
  sendSlackMessage,
  clearStore,
  payInFullUri,
  updateLoginAttempts,
  providerId,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [isFetching, setIsFetching] = useState(true);
  const queryParams = queryString.parse(location.search, { ignoreQueryPrefix: true });
  const providerName = getSubdomain(window);

  useEffect(() => {
    if (providerId) {
      setIsFetching(false);
    }
  }, [providerId]);

  const submitFailure = (obj) => (error) => {
    updateLoginAttempts();
    setIsFetching(false);
    const statusCode = path(['response', 'status'])(error);
    const responseErrorMessage = getErrorMessage(error);
    const patientInfo = compose(join(', '), values)(obj);

    sendSlackMessage({
      type: 'vault.failure',
      title: 'Failure - Get Info From Vault',
      provider: providerName,
      lastName: obj.lastName,
      dateOfBirth: obj.dateOfBirth,
      patientInfo,
      errorMessage: responseErrorMessage,
    });

    if (equals(statusCode, 406)) {
      return history.push(generatePath(funnelType, errorMessageRoute), {
        errorMessage: responseErrorMessage,
      });
    }

    if (equals(statusCode, 409)) {
      const parameter = compose(
        prop('parameter'),
        head,
        path(['response', 'data', 'errors']),
      )(error);

      if (equals(parameter, 'payzenPatientSSN')) {
        return history.push(generatePath(funnelType, ssnPage), obj);
      }

      if (equals(parameter, 'payzenPatientMRN')) {
        return history.push(generatePath(funnelType, mrnPage), obj);
      }
    }

    return history.push(generatePath(funnelType, billInfoError));
  };

  const submitSuccess = (response) => {
    const user = pathOr([], ['action', 'payload', 'user'])(response);
    const isSms = propOr(false, 'sms')(queryParams);
    if (isSms) { setIsSmsLink(isSms); }
    setIsFetching(false);
    const patientInfo = compose(join(', '), values, omit(['middleName', 'suspendedUntil', 'typeName']))(user);

    sendSlackMessage({
      type: 'vault.success',
      title: 'Success - Get Info From Vault',
      provider: providerName,
      name: `${user.firstName} ${user.lastName}`,
      phone: user.phone,
      patientInfo,
    });

    return guarantorValidation(response, funnelType, history);
  };

  const handleSubmit = (data, actions) => {
    setIsFetching(true);

    const { dateOfBirth, ...rest } = data;

    const obj = {
      ...mapObjIndexed(trim)(rest),
      dateOfBirth: formatDate(dateOfBirth),
    };

    getFromVault(obj)
      .then(submitSuccess)
      .catch(submitFailure(obj));

    return actions.setSubmitting(false);
  };

  const processPatientInvite = (patientInviteId) => {
    setIsFetching(true);

    getFromInvite({ patientInviteId })
      .then(submitSuccess)
      .catch(submitFailure());
  };

  useEffect(() => {
    logout();
    clearStore();
  }, []);

  useEffect(() => {
    const patientInviteId = prop('patientInviteId', queryParams);
    if (patientInviteId) { processPatientInvite(patientInviteId); }
  }, []);

  return (
    <BillInfoLayout isFetching={isFetching} handleSubmit={handleSubmit}>
      <div className="funnel-card__form text-center mb-5">
        {payInFullUri ? (
          <h1 className="text-larger text-sm-xlarger font-weight-normal" data-cy="login-title">
            Confirm patient last name and date of birth
          </h1>
        ) : (
          <>
            <h1 className="text-larger text-sm-xlarger font-weight-normal" data-cy="login-title">
              Break your bill into affordable monthly payments
            </h1>
            <p className="mb-0" data-cy="login-description">
              There&apos;s no interest, fees, or application.
              To get started simply enter your information below.
            </p>
          </>
        )}
      </div>
    </BillInfoLayout>
  );
};

export default BillInfo;
