import { apiCall } from 'libs/authenticationService';
import { POST } from 'libs/httpMethods';
import {
  CREATE_PLAID_PAYMENT_METHOD,
  CREATE_MANUAL_PAYMENT_METHOD,
  LOG_PLAID_EVENT,
  CREATE_MANUAL_CARD_PAYMENT_METHOD,
} from 'libs/apiUrls';

export const reqCreatePlaidPaymentMethod = (data) =>
  apiCall(POST, CREATE_PLAID_PAYMENT_METHOD, { data });

export const reqCreateManualPaymentMethod = (data) =>
  apiCall(POST, CREATE_MANUAL_PAYMENT_METHOD, { data });

export const reqLogPlaidEvent = (data) =>
  apiCall(POST, LOG_PLAID_EVENT, { data });

export const reqCreateManualCardPaymentMethod = (data) =>
  apiCall(POST, CREATE_MANUAL_CARD_PAYMENT_METHOD, { data });
