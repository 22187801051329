import {
  ascend,
  compose, descend, equals, filter, includes, isEmpty, map, path, pluck, prop, propEq, sort, sum,
} from 'ramda';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import generatePath from 'utils/generatePath';
import getErrorMessage from 'utils/getErrorMessage';

import funnelViews from 'libs/funnelViews';
import {
  connectBank, connectPaymentMethod, error as errorPage, invoiceToPay, reviewInfo,
} from 'libs/views';

import Card from 'components/Funnel/Card';
import Layout from 'components/Funnel/Layout';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import Button from 'components/Button';
import Currency from 'components/Currency';
import { getTUErrorPage } from 'utils/redirectTo';

const MAXIMUM_OFFERS = 5;
const MINIMUM_OFFERS = 3;

const PaymentPlanOptions = ({
  applicationId,
  currentView,
  patientId,
  updateApplication,
  generatedOffers,
  createOffer,
  bills,
  updateOffer,
  offerId,
  getGeneratedOffers,
  applicationStatus,
  isBillsLoaded,
  generateOffers,
  paymentMethodTypes,
}) => {
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(false);
  const [showAllOffers, setShowAllOffers] = useState(false);

  const includedInOffer = propEq('includedInOffer', true);
  const statusPending = propEq('status', 'BILL_PENDING');
  const validOffers = compose(filter(includedInOffer), filter(statusPending));
  const totalBalance = compose(sum, pluck('totalAmount'), validOffers)(bills);
  const billIds = compose(pluck('billId'), validOffers)(bills);

  const hasDebitCardOption = includes('debit card', paymentMethodTypes);
  const nextView = hasDebitCardOption ? connectPaymentMethod : connectBank;

  const onSubmitFailure = (error) => {
    const errorMessage = getErrorMessage(error);

    const TUErrorPage = getTUErrorPage(errorMessage);

    if (TUErrorPage) return history.push(generatePath('app', TUErrorPage));

    return history.push(generatePath('app', errorPage));
  };

  const goToConnectBank = () => history.push(generatePath('app', nextView));

  const onSubmitSuccess = (response) => updateOffer({
    id: path(['value', 'offerId'])(response),
    data: {
      status: 'OFFER_APPROVED',
      totalBalance: path(['value', 'totalAmount'])(response),
    },
  })
    .then(goToConnectBank)
    .catch(onSubmitFailure);

  const onSelect = (data) => {
    setIsFetching(true);

    const body = {
      bills: billIds,
      totalBalance: data.totalAmount,
      totalPayment: data.totalPayment,
      selectedOffer: data.id,
    };

    if (offerId) {
      return updateOffer({
        id: offerId,
        data: {
          ...body,
          status: 'OFFER_APPROVED',
        },
      })
        .then(goToConnectBank)
        .catch(onSubmitFailure);
    }

    return createOffer(body)
      .then(onSubmitSuccess)
      .catch(onSubmitFailure);
  };

  useEffect(() => {
    if (currentView !== funnelViews.offer && applicationId && applicationStatus !== 'APPLICATION_COMPLETED') {
      updateApplication({
        patientId,
        applicationId,
        currentView: funnelViews.offer,
      });
    }
  }, [currentView]);

  const onGenerateOffers = () => generateOffers({
    totalBalance,
    bills: billIds,
    firstBillingDate: new Date(),
  })
    .then(() => setIsFetching(false))
    .catch(onSubmitFailure);

  const onFailureGetGeneratedOffers = (error) => {
    const errorMessage = getErrorMessage(error);

    if (equals(errorMessage, 'No GDS data available')) {
      return onGenerateOffers();
    }

    setIsFetching(false);

    return onSubmitFailure();
  };

  const onSuccessGetGeneratedOffers = (response) => {
    const totalAmountOffer = path(['value', 0, 'totalAmount'])(response);
    const isTotalBalanceEqual = equals(totalAmountOffer, totalBalance);

    if (isTotalBalanceEqual) return setIsFetching(false);

    return history.replace(generatePath('app', invoiceToPay));
  };

  useEffect(() => {
    if (isEmpty(generatedOffers) && isBillsLoaded) {
      setIsFetching(true);

      getGeneratedOffers()
        .then(onSuccessGetGeneratedOffers)
        .catch(onFailureGetGeneratedOffers);
    }
  }, [generatedOffers, isBillsLoaded]);

  const colorMap = (index) => {
    switch (index) {
      case 0:
        return 'dodger-blue';
      case 1:
        return 'sapphire';
      case 2:
        return 'royal-blue';
      case 3:
        return 'dodger-blue';
      case 4:
        return 'sapphire';
      case 5:
        return 'royal-blue';
      default:
        return 'dodger-blue';
    }
  };

  const convertMonthlyPaymentToNumber = map((item) => ({
    ...item,
    monthlyPayment: Number(item.monthlyPayment),
  }));

  const offers = compose(convertMonthlyPaymentToNumber, filter(prop('monthlyPayment')))(generatedOffers);
  const noMoreOffers = offers.length <= MINIMUM_OFFERS;
  const byMonthlyPayment = descend(prop('monthlyPayment'));
  const offersSortedByMonthlyPayment = sort(byMonthlyPayment, offers);

  const shownOffers = showAllOffers || noMoreOffers
    ? offersSortedByMonthlyPayment
    : offersSortedByMonthlyPayment.slice(0, MINIMUM_OFFERS);

  return (
    <Layout
      backLink={generatePath('app', reviewInfo)}
      hideBackLink={!applicationId}
    >
      <Card
        progressBarValue={66.9117647059}
        content={(
          <div className="funnel-card__wrapper w-100">
            {isFetching && (
            <div className="connect-bank__loader text-center">
              <Loading />
            </div>
            )}
            <div className="text-center font-weight-light mb-5">
              <h1 className="h4 font-weight-normal mb-2" data-cy="offers-title">
                Pick your payment plan
              </h1>
              <div className="funnel-card__form">
                <p className="text-center mb-0">
                  You pay exactly what you owe, and not a penny more. Forever.
                </p>
              </div>
            </div>
            <div className="funnel-card__form">
              {shownOffers.map((item, index) => (
                <Button
                  key={item.id}
                  className="payment-plan__option"
                  onClick={() => onSelect(item)}
                  data-cy="offers-offeritem"
                  data-testid="offers-offeritem"
                  eventName={`offer-${index + 1}`}
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-fill mr-2 mr-2xs-4">
                      <div
                        className="d-flex justify-content-between align-items-center mb-4 text-small font-weight-bold"
                      >
                        <span className={`text-${colorMap(item.id)}`}>
                          <Currency
                            amount={item.monthlyPayment}
                            className="text-xlarge"
                            data-cy="offers-monthlyamount"
                          />
                          {' '}
                          <span data-cy="offers-monthlabel">/month</span>
                        </span>
                        <span
                          className={`payment-plan__tag bg-${colorMap(item.id)}`}
                          data-cy="offers-totalmonths"
                        >
                          {item.totalPayment}
                          {' '}
                          Months
                        </span>
                      </div>
                      <div
                        className=" text-small text-2xs-base d-flex justify-content-between align-items-center font-weight-light font-family-alt"
                      >
                        <p className="mb-0">No interest or fees</p>
                        <Currency
                          amount={item.newAmount}
                          prefix="Total: $"
                          data-cy="offers-totalamount"
                        />
                      </div>
                    </div>
                    <Icon name="caret-right" className="text-xlarger" />
                  </div>
                </Button>
              ))}
              {!noMoreOffers && !showAllOffers && (
                <Button
                  type="outline-primary-alice-blue"
                  className="mb-2 px-4 py-3 btn-block mt-4"
                  onClick={() => {
                    setShowAllOffers(true);
                  }}
                >
                  Show More Plans
                </Button>
              )}
            </div>
          </div>
                )}
      />
    </Layout>
  );
};

export default PaymentPlanOptions;
