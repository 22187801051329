import { createReducer } from '@reduxjs/toolkit';
import { map } from 'ramda';

import {
  getFromVault,
  getFromInvite,
} from 'actions/user';
import { updateBill, getBill, getBillFiles } from 'actions/bill';
import { clearStore } from 'actions/application';
import { createPartnerBill } from 'actions/patient';
import { getFromMychart } from 'actions/epic';
import { selectProvider } from 'actions/provider';

const initialState = {
  isLoaded: false,
  status: null,
  bills: [],
  billsFiles: [],
};

const getActionReducer = (action) => ({
  [String(action.pending)]: (state) => ({
    ...state,
    status: 'pending',
    isLoaded: false,
  }),
  [String(action.fulfilled)]: (state, action) => ({
    ...state,
    status: 'fulfilled',
    isLoaded: true,
    bills: action.payload.bills,
  }),
  [String(action.rejected)]: (state) => ({
    ...state,
    status: 'rejected',
    isLoaded: false,
  }),
});

export default createReducer(initialState, {
  ...getActionReducer(getFromVault),
  ...getActionReducer(getFromInvite),
  ...getActionReducer(getFromMychart),
  ...getActionReducer(selectProvider),

  [String(updateBill.pending)]: (state) => ({
    ...state,
    status: 'pending',
    isLoaded: false,
  }),
  [String(updateBill.fulfilled)]: (state, action) => ({
    ...state,
    status: 'fulfilled',
    isLoaded: true,
    bills: map((bill) =>
      (bill.billId === action.payload.billId ? { ...bill, ...action.payload } : bill))(state.bills),
  }),
  [String(updateBill.rejected)]: (state) => ({
    ...state,
    status: 'rejected',
    isLoaded: false,
  }),

  [String(getBill.pending)]: (state) => ({
    ...state,
    status: 'pending',
    isLoaded: false,
  }),
  [String(getBill.fulfilled)]: (state, action) => ({
    ...state,
    status: 'fulfilled',
    isLoaded: true,
    bills: action.payload,
  }),
  [String(getBill.rejected)]: (state) => ({
    ...state,
    status: 'rejected',
    isLoaded: false,
  }),

  [String(createPartnerBill.pending)]: (state) => ({
    ...state,
  }),
  [String(createPartnerBill.fulfilled)]: (state, action) => ({
    ...state,
    bills: [action.payload],
  }),
  [String(createPartnerBill.rejected)]: (state) => ({
    ...state,
  }),

  [String(getBillFiles.pending)]: (state) => ({
    ...state,
  }),
  [String(getBillFiles.fulfilled)]: (state, action) => ({
    ...state,
    billsFiles: action.payload,
  }),
  [String(getBillFiles.rejected)]: (state) => ({
    ...state,
  }),

  [String(clearStore)]: () => ({
    ...initialState,
  }),
});
