import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { pathOr, isNil } from 'ramda';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import { Input } from 'components/Fields';
import Loading from 'components/Loading';
import Button from 'components/Button';

import { error as errorPage } from 'libs/views';

import generatePath from 'utils/generatePath';
import { verifyCodeForm } from 'utils/validationSchemas';
import { redirectToPartner } from 'utils/redirectTo';

import icon from 'assets/images/icn_code_sheet.svg';

const VerifyCode = ({
  funnelType, history, location, verificationCode, twoFactorToken, application,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const email = pathOr('', ['state', 'email'])(location);

  useEffect(() => {
    if (isNil(twoFactorToken)) history.push('/');
  }, []);

  const onFailure = () => history.push(generatePath(funnelType, errorPage));

  const onSuccess = () => redirectToPartner({
    history,
    funnelType,
    currentView: application.currentView,
  });

  const onSubmit = (data) => {
    setIsFetching(true);

    verificationCode({ ...data, twoFactorToken, applicationId: application.applicationId })
      .then(onSuccess)
      .catch(onFailure);
  };

  return (
    <Layout
      backLink="/"
    >
      <Card
        content={(
          <div className="funnel-card__wrapper d-flex flex-column financial-info">
            {isFetching && (
              <div className="connect-bank__loader text-center">
                <Loading />
              </div>
            )}
            <div className="text-center mb-6">
              <img src={icon} alt="" className="mb-5" />
              <h1 className="h4 font-weight-normal mb-5">
                Let’s make sure it’s really you
              </h1>
              <p className="mb-0">
                To keep your information safety we need to confirm your identity.
                Please enter the code we sent to
                {' '}
                <b>{email}</b>
              </p>
            </div>
            <div className="funnel-card__form w-100 px-4">
              <Formik
                initialValues={{ twoFactorCode: '' }}
                validationSchema={verifyCodeForm}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="text-center">
                    <div className="mb-7">
                      <Field
                        id="twoFactorCode"
                        type="text"
                        name="twoFactorCode"
                        label="Enter Code Here"
                        component={Input}
                      />
                    </div>
                    <Button
                      type="primary"
                      className="btn-block"
                      isSubmit
                      disabled={isSubmitting}
                    >
                      Send Code
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      />
    </Layout>
  );
};

export default VerifyCode;
