import React, { useState } from 'react';
import { propOr } from 'ramda';
import browserHistory from 'browserHistory';

import ErrorLayout from 'components/ErrorLayout';
import Icon from 'components/Icon';
import Link from 'components/Link';

const ErrorMessage = ({ history, location }) => {
  const { state } = browserHistory.location;
  const [errorState] = useState(location.state || state);
  const errorMessage = propOr('', 'errorMessage')(errorState);

  return (
    <ErrorLayout>
      <div className="mb-6">
        <Icon name="band-aids" className="error-page__icon mb-5 mm-md-6 text-pizazz" />
        <h1 className="h2 mb-5">Apologies</h1>
        <p className="text-center mb-0">
          {errorMessage}
        </p>
      </div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        className="btn btn-primary btn-block text-decoration-none px-10 py-3"
        onClick={() => history.replace('/')}
        eventName="go-back"
      >
        Go Back
      </Link>
    </ErrorLayout>
  );
};

export default ErrorMessage;
