import {
  split, compose, path, includes,
} from 'ramda';

const isDemo = () => {
  const splitDomain = compose(split('.'), path(['location', 'hostname']))(window);
  if (!Array.isArray(splitDomain)) return false;
  const demoDomains = ['demo', 'bridgemed_demo'];
  return splitDomain.some((domain) => includes(domain, demoDomains));
};

export default isDemo;
