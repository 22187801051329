import { connect } from 'react-redux';

import PaymentError from './PaymentError';

const mapStateToProps = ({
  offer,
}) => ({
  offer,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentError);
