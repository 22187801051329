import { connect } from 'react-redux';
import { filter, propEq } from 'ramda';

import { updateApplication } from 'actions/application';
import { getOfferPayment } from 'actions/paymentMade';
import { getBill } from 'actions/bill';

import Success from './Success';

const mapStateToProps = ({
  paymentMade,
  offer: { paymentsAmount },
  application: {
    applicationId,
    currentView,
    partnerId,
    status,
    offerId,
    providerId,
  },
  user,
  bill: { bills },
  patient: {
    patientId,
  },
  provider: { providerName },
  paymentMethod: { processor },
}) => {
  const pendingBills = filter(propEq('status', 'BILL_PENDING'))(bills);

  return {
    paymentMade,
    currentView,
    applicationId,
    patientId,
    paymentsAmount,
    user,
    partnerId,
    bills,
    pendingBills,
    applicationStatus: status,
    offerId,
    providerId,
    providerName,
    processor,
  };
};

const mapDispatchToProps = {
  updateApplication,
  getOfferPayment,
  getBill,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Success);
