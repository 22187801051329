import React from 'react';

import LogoImage from 'components/LogoImage';

const ProviderLogo = ({
  logoUrl = [],
  pageTitle,
}) => {
  if (logoUrl.length) {
    return (
      <div className="main-header__logos mb-1" data-testid="provider-logo">
        {logoUrl.map((item) => (
          <div key={item}><LogoImage logoUrl={item} /></div>
        ))}
      </div>
    );
  }

  return (
    <h1 className="h4 font-weight-normal text-dodger-blue mb-1" data-testid="provider-name">
      {pageTitle}
    </h1>
  );
};

export default ProviderLogo;
