import { connect } from 'react-redux';

import { updateApplicationPatient } from 'actions/application';
import {
  getOffer, generateOffers, getGeneratedOffers,
} from 'actions/offer';

import ManualAddress from './ManualAddress';

const mapStateToProps = ({
  bill: { bills },
  application: { offerId },
  user: { shouldHideIncome },
}) => ({
  bills,
  offerId,
  shouldHideIncome,
});

const mapDispatchToProps = {
  updateApplicationPatient,
  getOffer,
  generateOffers,
  getGeneratedOffers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManualAddress);
