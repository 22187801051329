import { connect } from 'react-redux';

import { getFromVault } from 'actions/user';
import { sendSlackMessage, updateLoginAttempts } from 'actions/application';

import BillInfoError from './BillInfoError';

const mapStateToProps = ({
  application: { failedLoginAttempts },
}) => ({
  failedLoginAttempts,
});

const mapDispatchToProps = {
  getFromVault,
  sendSlackMessage,
  updateLoginAttempts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BillInfoError);
