import axios from 'axios';
import axiosRetry from 'axios-retry';
import { equals, mergeAll } from 'ramda';
import LogRocket from 'logrocket';
import getSubdomain from 'utils/getSubdomain';
import {
  POST,
  PUT,
  GET,
  DELETE,
  GET_HTML,
  POST_MULTIPART,
} from './httpMethods';

const RETRIES = 2;

axiosRetry(axios, {
  retries: RETRIES,
  retryDelay: (retryCount) => retryCount * 1000,
});

const defaultHeaders = {};

const baseURL = equals(process.env.REACT_APP_ENV, 'development')
  ? process.env.REACT_APP_SERVER_URL
  : window.location.origin;

const subdomain = getSubdomain(window);

const request = (method, url, headers, axiosConfig = {}) => {
  const options = mergeAll([
    {
      method,
      url,
      baseURL,
      headers: {
        ...defaultHeaders,
        ...headers,
        'X-Provider-Name': subdomain,
        'X-LogRocket-URL': LogRocket.sessionURL || undefined,
      },
      responseType: 'json',
    },
    axiosConfig,
  ]);

  return axios(options);
};

export default {
  [POST]: (url, {
    headers = {}, data = {}, params = {}, transformResponse = [],
  }) => request(
    POST, url, headers, {
      data,
      params,
      transformResponse,
    },
  ),
  [POST_MULTIPART]: (url, {
    headers = {}, data = {}, params = {}, transformResponse = [],
  }) => request(
    POST,
    url,
    {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
    {
      data,
      params,
      transformResponse,
    },
  ),
  [PUT]: (url, {
    headers = {}, data = {}, params = {}, transformResponse = [],
  }) => request(
    PUT, url, headers, {
      data,
      params,
      transformResponse,
    },
  ),
  [GET]: (url, { headers = {}, params = {}, transformResponse = [] }) => request(
    GET, url, headers, {
      params,
      transformResponse,
    },
  ),
  [GET_HTML]: (url, { headers = {}, params = {}, transformResponse = [] }) => request(
    GET, url, headers, {
      params,
      transformResponse,
      responseType: 'text/html',
    },
  ),
  [DELETE]: (url, { headers = {} }) => request(DELETE, url, headers),
};
