import { connect } from 'react-redux';

import { updateApplication } from 'actions/application';
import {
  createOffer, updateOffer, getGeneratedOffers, generateOffers,
} from 'actions/offer';

import PaymentPlanOptions from './PaymentPlanOptions';

const mapStateToProps = ({
  application: {
    applicationId, currentView, offerId, status,
  },
  user,
  offer: { generatedOffers },
  bill: { bills, isLoaded },
  patient: { patientId },
  provider: { paymentMethodTypes },
}) => ({
  applicationId,
  currentView,
  patientId,
  user,
  generatedOffers,
  bills,
  applicationStatus: status,
  offerId,
  isBillsLoaded: isLoaded,
  paymentMethodTypes,
});

const mapDispatchToProps = {
  updateApplication,
  createOffer,
  updateOffer,
  getGeneratedOffers,
  generateOffers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentPlanOptions);
