import { connect } from 'react-redux';

import Card from './Card';

const mapStateToProps = ({
  application: { isEpicApp },
}) => ({ isEpicApp });

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Card);
