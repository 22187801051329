import React, { useEffect } from 'react';
import {
  prop, isNil, propEq,
} from 'ramda';

import Currency from 'components/Currency';

import {
  formatBillMetaTitle,
  formatBillMetaValue,
  getValidValue,
  formatDate,
  isAboveAllowedTotal,
  isBelowAllowedTotal,
} from 'utils/invoiceToPay';

import SubmitButtons from './SubmitButtons';
import SingleBillRow from './SingleBillRow';
import BalanceErrorPayInFull from './BalanceErrorPayInFull';
import BalanceError from './BalanceError';

const SingleBill = ({
  bill,
  updateBill,
  submitSuccess,
  hasPayInFullRedirect,
  payInFullUri,
  providerName,
}) => {
  const servicingDate = getValidValue(prop('servicingDate')(bill));
  const invoiceId = prop('invoiceId')(bill);
  const billMeta = prop('meta')(bill);
  const totalAmount = prop('totalAmount')(bill);
  const includedInOffer = propEq('includedInOffer', true);

  const isAboveBalance = isAboveAllowedTotal(totalAmount);
  const isBelowBalance = isBelowAllowedTotal(totalAmount);
  const isDisabled = !totalAmount || isAboveBalance || isBelowBalance;
  const hasBalanceError = isAboveBalance || isBelowBalance;

  useEffect(() => {
    const billId = prop('billId')(bill);
    if (!includedInOffer(bill)) updateBill({ billId, includedInOffer: true });
  }, [bill]);

  const BUTTON_TEXT = 'Continue';
  const isPayInFull = hasPayInFullRedirect && !!payInFullUri;

  return (
    <>
      <div className="payment-plan__box pt-4 pb-sm-5 pb-2 pb-sm-4 mb-6 px-5 px-sm-7 bg-zircon" data-testid="single-bill">
        <div className="text-center">
          <span className="h1 text-tuna mb-2" data-cy="onebill-balance" data-testid="onebill-balance">
            <Currency
              amount={totalAmount}
              className={hasBalanceError ? 'text-burnt-sienna' : ''}
            />
          </span>
          <br />
          <span className="text-tundora text-smaller">Patient Balance</span>
        </div>
        <hr className="payment-plan__box__hr" />
        <div>
          <SingleBillRow name="Service Date" value={formatDate(servicingDate) || 'Recurring'} data-cy="onebill-date" />
          <SingleBillRow name="Invoice number" value={invoiceId || ''} data-cy="onebill-invoicenumber" />
          {Object.keys(billMeta).map((item) => {
            const value = getValidValue(prop(item)(billMeta));
            if (isNil(value)) return null;
            return <SingleBillRow key={item} name={`${formatBillMetaTitle(item)}`} value={formatBillMetaValue(value)} data-cy={`onebill-${item}`} />;
          })}
          <SingleBillRow name="What you owe" value={<Currency amount={totalAmount} />} data-cy="onebill-billamount" />
        </div>
      </div>
      {isPayInFull
        ? <BalanceErrorPayInFull accumulator={totalAmount} />
        : <BalanceError accumulator={totalAmount} />}
      <SubmitButtons
        buttonText={BUTTON_TEXT}
        onSubmit={submitSuccess}
        isDisabled={isDisabled}
        isPayInFull={isPayInFull}
        payInFullUri={payInFullUri}
        totalAmount={totalAmount}
        providerName={providerName}
      />
    </>
  );
};

export default SingleBill;
