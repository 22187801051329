import React, { useEffect } from 'react';
import { path, includes } from 'ramda';

import { success } from 'libs/views';

import generatePath from 'utils/generatePath';
import getFunnelType from 'utils/getFunnelType';

const pendingPaymentStatus = ['WAITING_FOR_FIRST_PAYMENT', 'RETURNED'];

const withBankFunnel = (Component) => (props) => {
  const {
    offer,
    setPatientProp,
    location,
    history,
  } = props;

  const funnelType = getFunnelType(location);

  useEffect(() => {
    if (offer.isLoaded) {
      const receivableStatus = path(['receivable', 'status'])(offer);
      const patientId = path(['patientId'])(offer);

      if (includes(receivableStatus, pendingPaymentStatus)) {
        setPatientProp({ patientId });
      } else {
        history.push(generatePath(funnelType, success));
      }
    }
  }, [offer.isLoaded]);

  return <Component {...props} />;
};

export default withBankFunnel;
