import { connect } from 'react-redux';

import CardFooter from './CardFooter';

const mapStateToProps = ({
  provider: {
    providerName,
    billingPhoneNumber,
  },
}) => ({
  providerName,
  billingPhoneNumber,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CardFooter);
