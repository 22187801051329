import { connect } from 'react-redux';

import { sendSlackMessage } from 'actions/application';

import ErrorVault from './ErrorVault';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  sendSlackMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorVault);
