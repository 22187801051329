import { createAsyncAction, createAction } from 'redux-promise-middleware-actions';
import { prop, identity } from 'ramda';

import {
  reqCreatePatient,
  reqUpdatePatient,
  reqGetPatient,
  reqGetPartnerInfo,
  reqGetProviderName,
  reqCreateProvider,
  reqCreatePartnerBill,
  reqSendToCrm,
} from 'api/patient';

export const createPatient = createAsyncAction(
  'CREATE_PATIENT',
  (payload) => reqCreatePatient(payload).then(prop('data')),
);

export const updatePatient = createAsyncAction(
  'UPDATE_PATIENT',
  (payload) => reqUpdatePatient(payload).then(prop('data')),
);

export const getPatient = createAsyncAction(
  'GET_PATIENT',
  () => reqGetPatient().then(prop('data')),
);

export const getPartnerInfo = createAsyncAction(
  'GET_PARTNER_INFO',
  (payload) => reqGetPartnerInfo(payload).then(prop('data')),
);

export const getProviderName = createAsyncAction(
  'GET_PROVIDER_NAME',
  (payload) => reqGetProviderName(payload).then(prop('data')),
);

export const createProvider = createAsyncAction(
  'CREATE_PROVIDER',
  (payload) => reqCreateProvider(payload).then(prop('data')),
);

export const createPartnerBill = createAsyncAction(
  'CREATE_PARTNER_BILL',
  (payload) => reqCreatePartnerBill(payload).then(prop('data')),
);

export const sendToCrm = createAsyncAction(
  'SEND_TO_CRM',
  (payload) => reqSendToCrm(payload).then(prop('data')),
);

export const setPatientProp = createAction('SET_PATIENT_PROP', identity);
