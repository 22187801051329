import { connect } from 'react-redux';

import { getProviderName, createProvider, createPartnerBill } from 'actions/patient';
import { updateApplication } from 'actions/application';
import { getBill, getBillFiles, updateBill } from 'actions/bill';

import UploadBill from './UploadBill';

const mapStateToProps = ({
  bill: { isLoaded: getBillIsLoaded, bills, billsFiles },
  application,
}) => ({
  getBillIsLoaded, bills, application, billsFiles,
});

const mapDispatchToProps = {
  getProviderName,
  createProvider,
  createPartnerBill,
  updateApplication,
  getBill,
  getBillFiles,
  updateBill,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadBill);
