import { createAsyncAction } from 'redux-promise-middleware-actions';
import { prop } from 'ramda';

import {
  reqGetOfferPayment,
  reqMakePatientPayment,
  reqMakePatientPaymentPartner,
} from 'api/paymentMade';

export const getOfferPayment = createAsyncAction(
  'GET_OFFER_PAYMENT',
  (offerId) => reqGetOfferPayment(offerId).then(prop('data')),
);

export const makePatientPayment = createAsyncAction(
  'MAKE_PATIENT_PAYMENT',
  (payload) => reqMakePatientPayment(payload).then(prop('data')),
);

export const makePatientPaymentPartner = createAsyncAction(
  'MAKE_PATIENT_PAYMENT_PARTNER',
  (payload) => reqMakePatientPaymentPartner(payload).then(prop('data')),
);
