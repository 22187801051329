import { createReducer } from '@reduxjs/toolkit';

import {
  createPatientOffers,
  updateOffer,
  getOffer,
  getOfferByCode,
  generateOffers,
  createOffer,
  getGeneratedOffers,
} from 'actions/offer';
import { clearStore } from 'actions/application';

const initialState = {
  status: null,
  getOfferStatus: null,
  isLoaded: false,
  offerId: null,
  totalPayment: null,
  generatedOffers: [],
  paymentsAmount: [],
};

export default createReducer(initialState, {
  [String(createPatientOffers.pending)]: (state) => ({
    ...state,
    isLoaded: false,
    hasError: false,
  }),
  [String(createPatientOffers.fulfilled)]: (state, action) => ({
    ...state,
    isLoaded: true,
    hasError: false,
    ...action.payload,
  }),
  [String(createPatientOffers.rejected)]: (state) => ({
    ...state,
    isLoaded: false,
    hasError: true,
  }),

  [String(updateOffer.pending)]: (state) => ({
    ...state,
  }),
  [String(updateOffer.fulfilled)]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [String(updateOffer.rejected)]: (state) => ({
    ...state,
  }),

  [String(getOffer.pending)]: (state) => ({
    ...state,
    isLoaded: false,
    getOfferStatus: 'pending',
  }),
  [String(getOffer.fulfilled)]: (state, action) => ({
    ...state,
    isLoaded: true,
    getOfferStatus: 'fulfilled',
    ...action.payload,
  }),
  [String(getOffer.rejected)]: (state) => ({
    ...state,
    isLoaded: false,
    getOfferStatus: 'rejected',
  }),

  [String(clearStore)]: () => ({
    ...initialState,
  }),

  [String(getOfferByCode.pending)]: (state) => ({
    ...state,
    isLoaded: false,
  }),
  [String(getOfferByCode.fulfilled)]: (state, action) => ({
    ...state,
    ...action.payload.offer,
    isLoaded: true,
  }),
  [String(getOfferByCode.rejected)]: (state) => ({
    ...state,
    isLoaded: false,
  }),

  [String(generateOffers.pending)]: (state) => ({
    ...state,
  }),
  [String(generateOffers.fulfilled)]: (state, action) => ({
    ...state,
    generatedOffers: action.payload,
  }),
  [String(generateOffers.rejected)]: (state) => ({
    ...state,
  }),

  [String(createOffer.pending)]: (state) => ({
    ...state,
  }),
  [String(createOffer.fulfilled)]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [String(createOffer.rejected)]: (state) => ({
    ...state,
  }),

  [String(getGeneratedOffers.pending)]: (state) => ({
    ...state,
  }),
  [String(getGeneratedOffers.fulfilled)]: (state, action) => ({
    ...state,
    generatedOffers: action.payload,
  }),
  [String(getGeneratedOffers.rejected)]: (state) => ({
    ...state,
  }),
});
