import {
  format, differenceInDays, isBefore,
} from 'date-fns';

const isValidDOB = (dateOfBirth) => {
  const today = new Date();
  const diffDays = differenceInDays(today, new Date(dateOfBirth));
  const formattedDate = dateOfBirth && format(new Date(dateOfBirth), 'MM/dd/yyyy');
  const isBefore1901 = isBefore(new Date(dateOfBirth), new Date('01/01/1901'));

  if (formattedDate === '11/11/1111') return true;
  if (!isBefore1901 && diffDays > 0) return true;
  return false;
};

export default isValidDOB;
