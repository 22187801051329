import { connect } from 'react-redux';

import { getShortUrlTarget } from 'actions/urlShortener';

import UrlShortener from './UrlShortener';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  getShortUrlTarget,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UrlShortener);
