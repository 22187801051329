import { connect } from 'react-redux';

import { getDocument } from 'actions/documents';

import Document from './Document';

const mapStateToProps = ({
  documents: { documentsHTML },
}) => ({
  documentsHTML,
});

const mapDispatchToProps = { getDocument };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Document);
