import React from 'react';
import { prop, isNil } from 'ramda';

import Icon from 'components/Icon';
import Currency from 'components/Currency';
import Button from 'components/Button';

import { convertToDollars } from 'utils/currency';
import {
  formatBillMetaTitle, formatBillMetaValue, getValidValue, formatDate,
} from 'utils/invoiceToPay';

const InputCheckbox = ({
  index,
  bill,
  onChange,
  isDetailsOpen,
  setIsDetailsOpen,
}) => {
  const billMeta = prop('meta')(bill);
  const servicingDate = prop('servicingDate')(bill);
  const invoiceId = prop('invoiceId')(bill);
  const totalAmount = prop('totalAmount')(bill);

  const toggleIsDetailsOpen = () => setIsDetailsOpen(index, !isDetailsOpen);

  return (
    <div className="d-flex bg-zircon mb-2 pl-4 pr-1 py-3 rounded" data-cy="bill-item">
      <label htmlFor={bill.billId} className="invoice-checkbox mb-0">
        <input
          data-private
          className="invoice-checkbox__input sr-only"
          type="checkbox"
          id={bill.billId}
          name={bill.billId}
          onChange={onChange}
          checked={bill.includedInOffer}
          value={convertToDollars(totalAmount)}
          data-cy="bill-checkbox"
        />
        <div className="invoice-checkbox__content py-2">
          <div className="invoice-checkbox__fake-input d-flex justify-content-center align-items-center mr-4">
            <Icon name="checkmark" className="invoice-checkbox__fake-input__icon text-smaller text-white" />
          </div>
        </div>
      </label>
      <div className="w-100">
        <div className="d-flex justify-content-between align-items-center">
          <div data-private data-cy="bill-amount" data-testid="bill-amount" className="d-flex flex-column">
            <Currency
              amount={totalAmount}
              className="text-tuna text-larger font-weight-semibold"
            />
            <span className="text-storm-gray text-smaller" data-testid="bill-date">{formatDate(servicingDate) || 'Recurring'}</span>
          </div>
          <div className="ml-2">
            <Button
              type="button"
              onClick={toggleIsDetailsOpen}
              className="bg-transparent rounded-0 p-4 border-0 text-xsmall text-dodger-blue"
              data-cy="bills-showmore"
              data-testid={`bills-showmore-${index}`}
              eventName="toogle-bills-details"
            >
              <Icon
                name={isDetailsOpen ? 'caret-up-alt' : 'caret-down-alt'}
              />
            </Button>
          </div>
        </div>
        {isDetailsOpen && (
          <div className="text-tuna text-small" data-testid="bill-detail">
            <table className="table table-sm mb-0 mt-1 table-borderless table-fixed table-bill">
              <tbody>
                <tr>
                  <th>
                    Service Date:
                  </th>
                  <td data-cy="bills-date" data-testid="bills-date">
                    {formatDate(servicingDate) || 'Recurring'}
                  </td>
                </tr>
                {invoiceId && (
                  <tr>
                    <th>
                      Invoice #:
                    </th>
                    <td data-cy="bills-invoicenumber" data-testid="bills-invoicenumber">
                      {invoiceId || ''}
                    </td>
                  </tr>
                )}
                {Object.keys(billMeta).map((item) => {
                  const value = getValidValue(prop(item)(billMeta));
                  if (isNil(value)) return null;

                  return (
                    <tr key={item} className="text-capitalize">
                      <th>
                        {`${formatBillMetaTitle(item)}:`}
                      </th>
                      <td data-cy={`bills-${item}`} data-testid={`bills-${item}`}>
                        {formatBillMetaValue(value)}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <th>
                    What you owe:
                  </th>
                  <td data-cy="bills-billamount" data-testid="bills-billamount">
                    <Currency
                      amount={totalAmount}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default InputCheckbox;
