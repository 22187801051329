import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
import { equals, isEmpty } from 'ramda';

import ProgressBar from 'components/ProgressBar';

import getFunnelType from 'utils/getFunnelType';

import payzenLogo from 'assets/images/payzen-logo-colored.svg';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Link from 'components/Link';
import LayoutContext from 'contexts/LayoutContext';
import Banner from '../Banner';
import CardFooter from '../CardFooter';

const Card = ({
  content,
  progressBarValue,
  banner,
  className,
  location,
  showFooter,
  showBillingMessage,
  isEpicApp,
  isErrorPage,
}) => {
  const history = useHistory();
  const funnelType = getFunnelType(location);
  const isAppFunnel = equals(funnelType, 'app');
  const {
    backLink,
    hideBackLink,
    backCallback,
  } = useContext(LayoutContext);

  return (
    <>
      <div className={`card funnel-card flex-row ${isAppFunnel ? 'mb-7' : 'mb-3 mb-md-4'} ${className}`}>
        <div className="funnel-card__content flex-fill">
          <ProgressBar value={Number(progressBarValue)} />
          {banner && <Banner content={banner} isAppFunnel={isAppFunnel} />}
          {isEpicApp && (
            <header className="card-header text-center">
              {(!isEmpty(backLink) && !hideBackLink) && (
              <div className="card-header__back">
                <Link
                  data-testid="back-button"
                  to={backLink}
                  onClick={() => backCallback && backCallback()}
                  className="d-flex align-items-center text-decoration-none"
                  eventName="go-back"
                >
                  <Icon name="caret-left" className="text-dodger-blue text-larger" />
                </Link>
              </div>
              )}
              {(isErrorPage && !hideBackLink) && (
              <div className="card-header__back">
                <Button
                  type="link"
                  data-testid="back-button"
                  onClick={history.goBack}
                  eventName="go-back"
                >
                  <Icon name="caret-left" className="text-dodger-blue text-larger" />
                </Button>
              </div>
              )}
              <div className="mb-1 mb-md-2">
                <img src={payzenLogo} alt="" height={22} />
              </div>
            </header>
          )}
          {content}
        </div>
      </div>
      {showFooter && (
        <CardFooter
          isAppFunnel={isAppFunnel}
          showBillingMessage={showBillingMessage}
        />
      )}
    </>
  );
};

Card.propTypes = {
  content: PropTypes.node.isRequired,
  progressBarValue: PropTypes.number,
  banner: PropTypes.node,
  className: PropTypes.string,
  showFooter: PropTypes.bool,
  showBillingMessage: PropTypes.bool,
};

Card.defaultProps = {
  banner: null,
  className: '',
  progressBarValue: 0,
  showFooter: true,
  showBillingMessage: false,
};

export default withRouter(Card);
