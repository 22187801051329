import React, { useState } from 'react';
import ReactModal from 'react-modal';

import Icon from 'components/Icon';

const ReadMore = () => {
  const [showReadMore, setShowReadMore] = useState(false);
  const toggleReadMore = (e) => {
    e.stopPropagation();
    setShowReadMore(!showReadMore);
  };

  return (
    <>
      <p className="text-charade text-small mb-0">
        It’s the secure, easy way to pay.
        {' '}
        <button type="button" className="btn btn-link text-small align-baseline p-0 read-more__button" onClick={toggleReadMore}>Read More</button>
      </p>
      <ReactModal
        isOpen={showReadMore}
        className="main-modal main-modal--small"
        overlayClassName="main-modal__overlay"
        contentLabel="Read more Modal"
        ariaHideApp={false}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        role="dialog"
        closeTimeoutMS={300}
      >
        <div className="main-modal__content">
          <div className="read-more">
            <Icon name="equis" className="read-more__close text-xlarger text-sapphire clickable" onClick={toggleReadMore} />
            <h1 className="text-center text-base text-trout font-weight-bold mb-5">Why link your bank account?</h1>
            <div className="media text-small mb-4 pb-1">
              <Icon name="checkmark" className="text-cornflower-blue mr-5" />
              <div className="media-body text-trout">
                <h2 className="font-weight-bold text-small mb-0">Skip the hassle</h2>
                <p className="mb-0">Say goodbye to filling out checks, mailing them, and then wondering if they ever arrived.</p>
              </div>
            </div>
            <div className="media text-small mb-4 pb-1">
              <Icon name="checkmark" className="text-cornflower-blue mr-5" />
              <div className="media-body text-trout">
                <h2 className="font-weight-bold text-small mb-0">Save money</h2>
                <p className="mb-0">Avoid all interest and overdraft fees!</p>
              </div>
            </div>
            <div className="media text-small">
              <Icon name="checkmark" className="text-cornflower-blue mr-5" />
              <div className="media-body text-trout">
                <h2 className="font-weight-bold text-small mb-0">Protect yourself</h2>
                <p className="mb-0">Paper checks give everybody access to your bank account numbers and personal details. Avoid the risks with secure electronic payments.</p>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default ReadMore;
