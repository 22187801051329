import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  prop, path, last, __, compose, propOr, mapObjIndexed, omit, isEmpty, divide,
} from 'ramda';

import Layout from 'components/Funnel/Layout';
import Card from 'components/Funnel/Card';
import Button from 'components/Button';
import { Input } from 'components/Fields';
import Loading from 'components/Loading';

import generatePath from 'utils/generatePath';

import { error, personalInfo } from 'libs/views';
import { loggedIn, getProfile } from 'libs/authenticationService';
import { convertToCents } from 'utils/currency';

const generateFormData = (data) => {
  const { bills, ...rest } = data;

  const newData = {
    ...rest,
    totalAmount: convertToCents(rest.totalAmount),
    includedInOffer: true,
  };

  const form = new FormData();

  mapObjIndexed((value, key) => {
    form.set(key, value);
  }, newData);

  bills.forEach((file) => {
    form.append('bills', file);
  });

  return form;
};

const UploadBill = ({
  history,
  funnelType,
  createProvider,
  createPartnerBill,
  updateApplication,
  getBill,
  getBillIsLoaded,
  bills,
  application,
  getBillFiles,
  updateBill,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [applicationId, setApplicationId] = useState('');
  const [dirtyForm, setDirtyForm] = useState(false);

  useEffect(() => {
    const profile = loggedIn() && getProfile();
    setPatientId(path(['patient', 'patientId'])(profile));
    setApplicationId(prop('applicationId')(profile));
  }, []);

  useEffect(() => {
    const providerId = prop('providerId')(application);
    const patientId = prop('patientId')(application);

    if (providerId && patientId && !getBillIsLoaded) {
      getBill({ patientId, providerId });
    }
  }, []);

  useEffect(() => {
    const billId = compose(prop('billId'), last)(bills);

    if (billId) {
      getBillFiles(billId);
    }
  }, [bills]);

  const isSubmitButtonDisabled = ({ accountId, bills }) => !isEmpty(accountId) || !isEmpty(bills);

  const onFailure = () => history.push(generatePath(funnelType, error));

  const onSuccess = () => history.push(generatePath(funnelType, personalInfo));

  const createPartnerBillSuccess = (data) => () => {
    updateApplication({
      patientId,
      applicationId,
      ...data,
      meta: null,
    })
      .then(onSuccess)
      .catch(onFailure);
  };

  const createProviderSuccess = (data) => (response) => {
    const providerId = path(['value', 'providerId'])(response);
    const billId = compose(prop('billId'), last)(bills);
    const newData = { ...omit(['providerName'])(data), providerId };
    const formData = generateFormData(billId ? { ...newData, billId } : newData);
    const billPayload = { patientId, data: formData };

    return createPartnerBill(billPayload)
      .then(createPartnerBillSuccess({ providerId, medicalRecordNumber: data.accountId }))
      .then(() => {
        if (billId) updateBill({ billId, offerId: null, status: 'BILL_PENDING' });
      })
      .catch(onFailure);
  };

  const handleSubmit = (values) => {
    const providerName = compose(propOr('', 'providerName'), last)(bills);
    const totalAmount = compose(divide(__, 100), prop('totalAmount'), last)(bills);
    setIsFetching(true);

    if (!dirtyForm) return onSuccess();

    return createProvider({ providerName })
      .then(createProviderSuccess({ ...values, totalAmount }))
      .catch(onFailure);
  };

  const initialValues = {
    accountId: compose(propOr('', 'accountId'), last)(bills),
    bills: [],
  };

  const hackUseEffect = useEffect;

  return (
    <Layout
      backLink="/"
    >
      <Card
        className="mt-2"
        progressBarValue={0.7352941176}
        content={(
          <div className="funnel-card__wrapper">
            {isFetching && (
              <div className="connect-bank__loader text-center">
                <Loading />
              </div>
            )}
            <div className="mb-6">
              <h1 className="text-center h4 font-weight-normal mb-5 mb-2">
                Optional bill information
              </h1>
              <p className="text-center mb-0">If your bill is handy, please provide additional information, and upload an image of your bill.</p>
            </div>
            <div className="funnel-card__form">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ isSubmitting, values, dirty }) => {
                  hackUseEffect(() => {
                    setDirtyForm(dirty);
                  }, [dirty]);

                  const isDisabled = isSubmitButtonDisabled(values);

                  return (
                    <Form>
                      <Field
                        id="accountId"
                        type="text"
                        name="accountId"
                        label="Account ID (MRN)"
                        component={Input}
                        isOptional
                      />
                      <Button type="primary" className="btn-block mb-3" isSubmit disabled={!isDisabled || isSubmitting}>Confirm & Continue</Button>
                    </Form>
                  );
                }}
              </Formik>
              <Button type="outline-primary" className="btn-block" onClick={onSuccess}>Skip this page, if you don’t have your bill</Button>
            </div>
          </div>
        )}
      />
    </Layout>
  );
};

export default UploadBill;
