import React, { useEffect } from 'react';
import { path } from 'ramda';

import Header from 'components/Header';
import Loading from 'components/Loading';

const UrlShortener = ({
  getShortUrlTarget,
  history,
  match,
  location,
}) => {
  const onSuccess = (event) => {
    const url = path(['value', 'target'])(event);

    return window.location.replace(url);
  };

  const onFailure = () => history.push('/');

  const { linkId } = match.params;

  useEffect(() => {
    const search = path(['search'])(location);

    getShortUrlTarget({ linkId, search })
      .then(onSuccess)
      .catch(onFailure);
  }, []);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="d-flex align-items-center flex-fill">
        <div className="document__loader text-center">
          <Loading />
        </div>
      </div>
    </div>
  );
};

export default UrlShortener;
